<div class="col-md-10 offset-md-1">
    <h2>
        <span>Utilisateurs</span>
    </h2>

    <p-panel header="Filtres" [toggleable]="true" [style]="{'margin-bottom':'20px'}">
        <div class="row form-row">
            <span  class="col-md-4">Identifiant</span>
            <div class="col-md-5">
                <input type="search" class="form-control" [(ngModel)]="filterIdentifiant" (keyup)="filtrerIdentifiant()" (search)="loadAll()" placeholder="Filtrer...">
            </div>

            <span class="col-md-4">Profil</span>
            <div class="col-md-5">
                <div *ngFor="let data of lexprofilutilisateurs; let i=index">
                    <label style="width: 25%; float: left; "> <input type="checkbox" [value]="data.id" (change)="filtrerProfils($event)"/>
                    {{data.libelle}}
                    </label>
                </div>
            </div>

            <span class="col-md-4">Date de création</span>
            <div class="col-md-5">
                Du <p-calendar [(ngModel)]="filterMinDate" dateFormat="dd/mm/yy" [readonlyInput]="true" [showButtonBar]="true" [showIcon]="true" (ngModelChange)="filtrerDate()" ></p-calendar> 
                Au <p-calendar [(ngModel)]="filterMaxDate" dateFormat="dd/mm/yy" [readonlyInput]="true" [showButtonBar]="true" [showIcon]="true" (ngModelChange)="filtrerDate()"></p-calendar>
            </div>


            <span class="col-md-4">Zones</span>
            <div class="col-md-5">
                <p-multiSelect [options]="lexzones" optionLabel="libelle" defaultLabel="Filtrer..." [style]="{'width':'100%'}" (onChange)="filtrerZones($event.value)">
                </p-multiSelect>
            </div>

        </div>
      </p-panel>

    <dup-alert-error></dup-alert-error>
    <div>
        <button type="button" name="export" class="btn btn-primary btn-md float-right" (click)="export()" title="Exporter le tableau en CSV">
          Exporter
          <i *ngIf="isLoading" class="pi pi-spin pi-spinner" style="position: relative; top: 2px"></i>
        </button>
      </div>
    <div class="table-responsive" *ngIf="utilisateurs">
        <p-table class="table table-striped" [resetPageOnSort]="false" [sortOrder]="2" [value]="utilisateurs" [paginator]="true" [lazy]="true" [rows]="itemsPerPage" [totalRecords]="totalUtilisateurs" dataKey="id"  (onLazyLoad)="loadUtilisateursLazy($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th scope="col" [pSortableColumn]="'id'" style="width: 5em;"><span>ID</span><p-sortIcon [field]="'id'"></p-sortIcon></th>
                    <th scope="col" [pSortableColumn]="'email'"><span>Identifiant</span><p-sortIcon [field]="'email'"></p-sortIcon></th>
                    <th scope="col" ><span>Email contact</span></th>
                    <th scope="col" [pSortableColumn]="'dateCreation'"><span>Date Creation</span><p-sortIcon [field]="'dateCreation'"></p-sortIcon></th>
                    <th scope="col" [pSortableColumn]="'statutJuridiqueId'"><span>Statut Juridique</span><p-sortIcon [field]="'statutJuridiqueLibelle'"></p-sortIcon></th>
                    <th scope="col" [pSortableColumn]="'profilUtilisateurId'"><span>Profil Utilisateur</span><p-sortIcon [field]="'profilUtilisateurLibelle'"></p-sortIcon></th>
                    <th scope="col"><span>Zone</span></th>
                    <th scope="col"></th>
                </tr>
                
            </ng-template>
            <ng-template pTemplate="body" let-utilisateur>
                <tr>
                    <td>{{utilisateur.id}}</td>
                    <td>{{utilisateur.email}}</td>
                    <td>{{utilisateur.emailContact}}</td>
                    <td>{{utilisateur.dateCreation | date:'mediumDate'}}</td>
                    <td>
                        <div *ngIf="utilisateur.statutJuridiqueId">
                            {{utilisateur.statutJuridiqueLibelle}}
                        </div>
                    </td>
                    <td>
                        <div *ngIf="utilisateur.profilUtilisateurId">
                            {{utilisateur.profilUtilisateurLibelle}}
                        </div>
                    </td>
                    <td>
                        <span *ngFor="let zone of utilisateur.zones">
                            {{zone}}
                        </span>
                    </td>
                    <td class="text-right">
                        <div class="btn-group flex-btn-group-container"> 
                            <button type="submit"
                                    [routerLink]="['/', { outlets: { popup: 'utilisateur/'+ utilisateur.id} }]"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-primary btn-sm">
                                <span class="fa fa-pencil"></span>
                                <span class="d-none d-md-inline">Modifier</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
    