import { Component, OnInit, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { ActeurFormComponent } from '../acteurform/acteurform.component';
import { AdresseService } from 'src/app/entities/adresse/adresse.service';
import { ContactService } from 'src/app/entities/contact/contact.service';
import { ActeurService } from 'src/app/entities/acteur/acteur.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Ouvrage } from 'src/app/entities/ouvrage/ouvrage.model';
import { Acteur } from 'src/app/entities/acteur/acteur.model';
import { Adresse } from 'src/app/entities/adresse/adresse.model';
import { Contact } from 'src/app/entities/contact/contact.model';
import { OuvrageService } from 'src/app/entities/ouvrage/ouvrage.service';

@Component({
    selector: 'dup-foreur',
    templateUrl: './foreurform.component.html',
    styleUrls: [
      '../forms.css'
    ]
  })
  export class ForeurFormComponent implements OnInit, OnDestroy {
    

    ouvrage:Ouvrage;

    routeSub: any;

    isSaving:boolean;

    // les acteurs saisis
    @ViewChildren(ActeurFormComponent) acteurForms: QueryList<ActeurFormComponent>;

    constructor(
        private ouvrageService: OuvrageService,
        private adresseService: AdresseService,
        private contactService: ContactService,
        private acteurService: ActeurService,
        private route: ActivatedRoute,
        private router: Router,
        ) {
            this.isSaving = false;
            this.ouvrage = new Ouvrage();
            this.ouvrage.acteurForeur = new Acteur();
    }

    ngOnInit(): void {
        this.routeSub = this.route.params.subscribe((params) => {
            if (params['id']) {
                this.ouvrageService.find(params['id']).subscribe((res) => {
                    this.ouvrage = res;
                    this.ouvrage.acteurForeur = new Acteur();
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    validateForeur() {
        let baseActeur = false;
        let acteurForeur = this.ouvrage.acteurForeur;

        baseActeur = acteurForeur.statutJuridiqueId != null && acteurForeur.email != null;
        if (acteurForeur.statutJuridiqueId == 3800) {
          baseActeur = baseActeur && acteurForeur.siretEtab != null && acteurForeur.raisonSocialeEtab != null;
        }
        setTimeout(() => { 
          baseActeur = baseActeur && acteurForeur.adresse.pays != null;
          if (acteurForeur.adresse.pays == "France") {
            baseActeur = baseActeur && acteurForeur.adresse.communeInseeCom != null;
          } else {
            baseActeur = baseActeur && acteurForeur.adresse.lieuDit != null;
          }
        },0);
        return !baseActeur;
      }

      public save() {
        if(this.ouvrage.acteurForeur.id) {
          this.saveOuvrage(this.ouvrage.acteurForeur);
        } else {
          this.adresseService.create(this.ouvrage.acteurForeur.adresse).subscribe(
            (savedAdresse: Adresse) => {
              this.onSaveAdresseSuccess(this.ouvrage.acteurForeur, savedAdresse);
            }
            , (res: any) => this.onSaveError(res));
        }
      }
    
      private onSaveAdresseSuccess(acteur: Acteur, savedAdresse: Adresse) {
        // si on est ici on a deja verifie que le formulaire acteur existe
        if (savedAdresse) {
          acteur.adresse.id = savedAdresse.id;
          acteur.adresseId = savedAdresse.id;
          acteur.adresse.auteurCreation = savedAdresse.auteurCreation;
          acteur.adresse.auteurModification = savedAdresse.auteurModification;
        }
        if (acteur.contact?.nom) {
          this.contactService.create(acteur.contact).subscribe(
          (savedContact: Contact) => this.onSaveContactSuccess(acteur, savedContact)
          , (res: any) => this.onSaveError(res));
        } else {
          this.onSaveContactSuccess(acteur, null);
        }
      }
    
      private onSaveContactSuccess(acteur: Acteur, savedContact: Contact,) {
        // si on est ici on a deja verifie que le formulaire acteur existe
        if (savedContact) {
          acteur.contact.id = savedContact.id;
          acteur.contactId = savedContact.id;
          acteur.contact.auteurCreation = savedContact.auteurCreation;
          acteur.contact.auteurModification = savedContact.auteurModification;
        }
        this.acteurService.create(acteur).subscribe(
        (savedActeur: Acteur) => {
            this.saveOuvrage(savedActeur)
        }
        , (res: any) => this.onSaveError(res));
    }

    private saveOuvrage(savedActeur:Acteur) {
        this.ouvrage.acteurForeurId = savedActeur.id;
        this.ouvrage.acteurForeur.id = savedActeur.id;
        this.ouvrage.acteurForeur.auteurCreation = savedActeur.auteurCreation;
        this.ouvrage.acteurForeur.auteurModification = savedActeur.auteurModification;
       
        this.ouvrageService.update(this.ouvrage).subscribe(
        (updatedOuvrage: Ouvrage) => {
            this.gotoAccueil(null);
        }
        , (res: any) => this.onSaveError(res)
        );
    }

    private onSaveError(res?: any) {
        if (res) {
          console.log(res);
        }
        this.isSaving = false;
    }

    gotoAccueil(event) {
        this.router.navigate(['']);
      }
}