export class LexFonction {
    constructor(
        public id?: number,
        public code?: string,
        public mnemo?: string,
        public libelleSelection?: string,
        public libelleComplet?: string,
        public definition?: string,
        public selectionnable?: boolean,
        public valide?: boolean,
        public dateCreation?: any,
        public dateModification?: any,
        public parentId?: number,
        public typeRessourceId?: number,
        public typeRessourceLibelle?:string,
        public finaliteId?: number,
        public finaliteLibelle?:string,
    ) {
        this.selectionnable = false;
        this.valide = false;
    }
}
