<div *ngIf="isAuthenticated">
    <router-outlet name="navbar"></router-outlet>
</div>
<div>
    <div [class]="isAuthenticated?'card jh-card-logged':'card jh-card'">
        <router-outlet></router-outlet>
        <router-outlet name="popup"></router-outlet>
    </div>
</div>
<div *ngIf="isAuthenticated">
  <dup-footer></dup-footer>
</div>
