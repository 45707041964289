import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/login/login.service';

@Component({
    selector: 'dup-error',
    templateUrl: './error.component.html',
    styleUrls: [
      'error.css'
  ]
})
export class ErrorComponent implements OnInit{

    constructor(private router: Router, private loginService: LoginService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loginService.logout();
    }, 5000);
  }

  accueil() {
    this.loginService.logout();
  }
}
