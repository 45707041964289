import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeclarationService } from 'src/app/entities/declaration/declaration.service';

@Component({
    selector: 'dup-delete-popup',
    templateUrl: './delete-popup.component.html',
    styleUrls: ['./popup.css']
  })
  export class DeletePopupComponent implements OnInit {

    // declaration en cours
    @Input() declarationId: number;

    constructor(
      private ngbModal: NgbActiveModal,
      private declarationService: DeclarationService
    ) { }

    ngOnInit() {

    }

    validate() {
      this.declarationService.deletePj(this.declarationId).subscribe((res) =>
        this.declarationService.deleteOuvrages(this.declarationId).subscribe((res) =>
          this.declarationService.deleteReglementations(this.declarationId).subscribe(res =>
            this.declarationService.delete(this.declarationId).subscribe(res => {
              this.ngbModal.close();
            }))));
    }

    cancel() {
      this.ngbModal.dismiss();
    }

  }
