export class Commune {
    constructor(
        public gid?: Number,
        public id?: string,
        public nomCom?: string,
        public inseeCom?: string,
        public inseeDep?: string,
        public inseeReg?: string,
        public version?: string,
        public codeEpci?: string,
        public codePostal?:string,
        public email?: string,
        public geom?:any
    ) {}
}