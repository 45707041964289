import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

import { saveAs } from "file-saver";
import * as _ from 'lodash';

import { Declaration } from './declaration.model';
import { Acteur } from '../acteur/acteur.model';
import { Ouvrage } from '../ouvrage/ouvrage.model';
import { DeclarationService } from './declaration.service';
import { Adresse } from '../adresse/adresse.model';
import { AdresseService } from '../adresse/adresse.service';
import { Contact } from '../contact/contact.model';
import { ContactService } from '../contact/contact.service'
import { ActeurService } from '../acteur/acteur.service';
import { DeclarationReglementationService } from '../declaration-reglementation/declaration-reglementation.service';
import { AbandonPopupService } from 'src/app/shared/popup/abandon-popup.service';
import { Utilisateur } from '../utilisateur/utilisateur.model';
import { OuvrageService } from '../ouvrage/ouvrage.service';
import { UtilisateurService } from '../utilisateur/utilisateur.service';
import { Principal } from 'src/app/shared/auth/principal.service';
import { LexFonction } from '../lex-fonction/lex-fonction.model';
import { LexReglementation } from '../lex-reglementation/lex-reglementation.model';
import { LexSystemeReferenceSpatial } from '../lex-systeme-reference-spatial/lex-systeme-reference-spatial.model';
import { LexSystemeReferenceSpatialService } from '../lex-systeme-reference-spatial/lex-systeme-reference-spatial.service';
import { LexFonctionService } from '../lex-fonction/lex-fonction.service';
import { AbandonPopupComponent } from 'src/app/shared/popup/abandon-popup.component';
import { PieceJointePopupComponent } from 'src/app/shared/popup/piecejointe-popup.component';
import { PieceJointePopupService } from 'src/app/shared/popup/piecejointe-popup.service';
import { PieceJointeService } from '../piece-jointe/piece-jointe.service';

@Component({
    selector: 'dup-declaration-detail',
    templateUrl: './declaration.component.html',
    styleUrls: [
      'declaration.css'
    ],
    providers: []
  })
export class DeclarationComponent implements OnInit{

    declaration: Declaration;

    declarant:Utilisateur;
    acteurMoa:Acteur;
    fonction:LexFonction;

    ouvrages: Ouvrage[];

    zones:string[];
    administrations:string[];
    lexReglementations:LexReglementation[];
    lexsrs: LexSystemeReferenceSpatial[];

    isLoading: Boolean = false;
    isInstructor: Boolean = false;
    isEnCours:Boolean = false;

    routeSub: any;

    constructor(
        private principal: Principal,
        private declarationService: DeclarationService,
        private ouvrageService: OuvrageService,
        private adresseService: AdresseService,
        private contactService: ContactService,
        private acteurService: ActeurService,
        private utilisateurService: UtilisateurService,
        private declarationReglementationService: DeclarationReglementationService,
        private lexSystemeReferenceSpatialService:LexSystemeReferenceSpatialService,
        private lexFonctionService:LexFonctionService,
        private abandonPopupService:AbandonPopupService,
        private pieceJointePopupService:PieceJointePopupService,
        private pieceJointeService:PieceJointeService,
        private route:ActivatedRoute,
        private router:Router
    ) {
        this.declaration = new Declaration();
        this.declaration.acteurMoa = new Acteur();
        this.declarant = new Utilisateur();
        this.ouvrages = [];
        this.administrations = [];
        this.fonction = new LexFonction();
    }

    ngOnInit() {
        this.lexSystemeReferenceSpatialService.query()
            .subscribe((res) => { this.lexsrs = res; }, (res) => {});
        this.principal.isInstructor().then((res) => {
            this.isInstructor = res;
        });
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['id'] ) {
                this.declarationService.find(params['id']).subscribe((res)=> {
                    this.declaration = res;
                    this.isEnCours = this.declaration.dateSoumission == null;
                    this.declaration.acteurMoa = new Acteur();
                    this.utilisateurService.findByMail(this.declaration.auteurCreation).subscribe((res:Utilisateur) => {
                        this.declarant = res;
                        this.adresseDeclarant();
                        this.contactDeclarant();
                    });
                    if(this.declaration.acteurMoaId) {
                        this.acteurService.find(this.declaration.acteurMoaId).subscribe((res)=> {
                            this.declaration.acteurMoa = res;
                        if(res.contactId)
                            this.contactService.find(res.contactId).subscribe((res) => this.declaration.acteurMoa.contact = res);
                        if(res.adresseId)
                            this.adresseService.find(res.adresseId).subscribe((res) => this.declaration.acteurMoa.adresse = res);
                        });
                    }
                    if(this.declaration.fonctionId) {
                        this.lexFonctionService.find(this.declaration.fonctionId).subscribe((res) => this.fonction = res);
                    }
                    this.declarationReglementationService.findByDeclaration(this.declaration.id).subscribe((res:LexReglementation[])=>
                    {
                        this.lexReglementations = res;
                    });
                    this.declarationService.findAdministrations(this.declaration.id).subscribe(res => this.administrations = res);
                });
                this.ouvrageService.findByDeclaration(params['id']).subscribe((res)=> {
                    let ouvrages:Ouvrage[] = res;
                    ouvrages.forEach(ouvrage => {
                        if(ouvrage.acteurMoeId) {
                        this.acteurService.find(ouvrage.acteurMoeId).subscribe((res)=> {
                            ouvrage.acteurMoe = res;
                        if(res.contactId)
                            this.contactService.find(res.contactId).subscribe((res) => ouvrage.acteurMoe.contact = res);
                        if(res.adresseId)
                            this.adresseService.find(res.adresseId).subscribe((res) => ouvrage.acteurMoe.adresse = res);
                        });
                        }
                        if(ouvrage.acteurProprioId) {
                        this.acteurService.find(ouvrage.acteurProprioId).subscribe((res)=> {
                            ouvrage.acteurProprio = res;
                        if(res.contactId)
                            this.contactService.find(res.contactId).subscribe((res) => ouvrage.acteurProprio.contact = res);
                        if(res.adresseId)
                            this.adresseService.find(res.adresseId).subscribe((res) => ouvrage.acteurProprio.adresse = res);
                        });
                        }
                        if(ouvrage.acteurForeurId) {
                        this.acteurService.find(ouvrage.acteurForeurId).subscribe((res)=> {
                            ouvrage.acteurForeur = res;
                        if(res.contactId)
                            this.contactService.find(res.contactId).subscribe((res) => ouvrage.acteurForeur.contact = res);
                        if(res.adresseId)
                            this.adresseService.find(res.adresseId).subscribe((res) => ouvrage.acteurForeur.adresse = res);
                        });
                        }
                        if(ouvrage.adresseId)
                            this.adresseService.find(ouvrage.adresseId).subscribe((res) => ouvrage.adresse = res);
                    });
                    this.ouvrages = ouvrages;
                    //this.finalSearch();
                });
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }

    private adresseDeclarant() {
        if(this.declarant.adresseId) this.adresseService.find(this.declarant.adresseId).subscribe((subRes: Adresse) => {
              this.declarant.adresse = subRes;
            }, (subRes) => {});
    }

    private contactDeclarant() {
        if(this.declarant.contactId) this.contactService.find(this.declarant.contactId).subscribe((subRes: Contact) => {
              this.declarant.contact = subRes;
            }, (subRes) => {});
    }

    private finalSearch() {

            /*this.isLoading = true;
            this.ouvrageService.findZonesByDeclaration(this.declaration.id).subscribe((res:string[]) => {
                this.zones = res;
                this.isLoading = false;
        });*/
    }

    isMinier() {
        let mine = _.find(this.lexReglementations, function(r) {return r.code =='1';});
        return mine != null;
    }

    isLoiEau() {
        let eau = _.find(this.lexReglementations, function(r) {return r.code =='2';});
        return eau != null;
    }

    isLoiIcpe() {
        let icpe = _.find(this.lexReglementations, function(r) {return r.code =='3' || r.code == '4';});
        return icpe != null;
    }

    isForageDomestique() {
        let fd = _.find(this.lexReglementations, function(r) {return r.code =='5';});
        return fd != null;
    }

    retour(event) {
        this.router.navigate(['']);
    }

    telecharger(event) {
        this.declarationService.download(this.declaration.id).subscribe(res => {
            var newBlob = new Blob([res], { type: "application/pdf" });
            saveAs(newBlob, "dcl_duplos_min_" + this.declaration.id + ".pdf");
            this.declarationService.deleteFile(this.declaration.id).subscribe(res => {});
        });
    }

    cerfa(event) {
        this.isLoading = true;
        this.declarationService.cerfa(this.declaration.id).subscribe(res => {
            var newBlob = new Blob([res], { type: "application/pdf" });
            saveAs(newBlob, "cerfa_" + this.declaration.id + ".pdf");
            this.isLoading = false;
            this.declarationService.deleteCerfa(this.declaration.id).subscribe(res => {});
        });
    }

    supprimer(event) {
        let modalRef:NgbModalRef = this.abandonPopupService.abandonModalRef(AbandonPopupComponent as Component, this.declaration.id);
        modalRef.result.then((result) => {
            this.router.navigate(['']);
        }, (reason) => {});
    }

    getUnite(srsId) {
        let srs = _.find(this.lexsrs, ['id', srsId]);
        return srs?srs.uniteXYLibelle:'';
    }

    openPopup(id, nom) {
        let modalRef: NgbModalRef = this.pieceJointePopupService.pieceJointeModalRef(PieceJointePopupComponent as Component, id, nom, this.declaration.id, false);
        modalRef.result.then((result) => {
        }, (reason) => { });
    }

    downloadRapport() {
        this.pieceJointeService.downloadRapport(this.declaration.id).subscribe(res => {
            var newBlob = new Blob([res], { type: "application/pdf" });
            saveAs(newBlob, "rapport_"+this.declaration.id + ".pdf");
        });
    }
}
