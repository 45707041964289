<div class="col-md-10 offset-md-1" style="text-align: center;">
    <div class="logo form-group">
      <i class="logo-img"></i>
      <h2>DUPLOS</h2>
      <h3>Déclaration Unifiée Pour Les Ouvrages Souterrains</h3>
    </div>
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <p style="text-align: left;">Erreur : vous n'êtes pas identifié dans DUPLOS. Merci de vérifier vos informations et réessayez.</p>
            <p><button type="button" class="btn btn-primary btn-md col-md-5" (click)="accueil()">Retour à l'accueil</button></p>
        </div>
    </div>
</div>
