import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DeclarationService } from 'src/app/entities/declaration/declaration.service';

@Component({
    selector: 'dup-delete-popup',
    templateUrl: './abandon-popup.component.html',
    styleUrls: ['./popup.css']
  })
  export class AbandonPopupComponent implements OnInit {
  
    // declaration en cours
    @Input() declarationId: number;
    @Input() multiple:boolean;

    isSaving: Boolean = false;
    interval;
    value = 0;
  
    constructor(
      private ngbModal: NgbActiveModal,
      private declarationService: DeclarationService
    ) { }
  
    ngOnInit() {
  
    }
  
    validate() {
      this.isSaving = true;
      this.interval = setInterval(() => {
        if (this.value < 100) this.value += 1;
        else {
          clearInterval(this.interval);
        }
      },this.multiple?500:250)
        this.declarationService.cancel(this.declarationId).subscribe((res) => {
        this.isSaving = false;
        this.ngbModal.close();
      }, err => {
        this.isSaving = false;
        this.ngbModal.dismiss("Error");
      });
    }
  
    cancel() {
      this.ngbModal.dismiss();
    }
  
  }