import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { DeclarationService } from './declaration.service';
import { declarationRoute } from './declaration.route';
import { DeclarationComponent } from './declaration.component';
import { DuplosSharedModule } from 'src/app/shared/shared.module';

const ENTITY_STATES = [
    ...declarationRoute,
];

@NgModule({
    imports: [
        DuplosSharedModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        DeclarationComponent
    ],
    providers: [
        DeclarationService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosDeclarationModule {}
