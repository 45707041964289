<div  id="declaration">
    <input type="button" name="back" class="btn btn-secondary btn-md" value="Retour" (click)="retour($event)" />
    <div class="float-right">
        <input type="button" name="previous" *ngIf="!isEnCours" class="btn btn-primary btn-md" value="Télécharger le récepissé" (click)="telecharger($event)"/>
        <!--<input type="button" name="previous" *ngIf="!isEnCours && isForageDomestique()" class="btn btn-primary btn-md" value="Télécharger le CERFA" (click)="cerfa($event)" [disabled]="isLoading"/>-->
        <!--<input type="button" name="delete" *ngIf="!isInstructor && declaration.statutDeclarationId == 1850" class="btn btn-danger btn-md" value="Abandonner" (click)="supprimer($event)" />-->
    </div>
    <div class="view-declaration-box">
        <h4 class="fs-title">Références de la déclaration : </h4>
        <div class="row form-row">
            <label class="col-md-6 form-control-label">Numéro de la déclaration : {{declaration.id}}</label>
            <label class="col-md-6 form-control-label">Statut de la déclaration : {{declaration.statutDeclarationId?declaration.statutDeclarationLibelle:''}}</label>

        </div>
        <div class="row form-row">
            <label class="col-md-6 form-control-label">Type : {{declaration.typeDeclarationId?declaration.typeDeclarationLibelle:''}}</label>
            <label class="col-md-6 form-control-label">Date de transmission : {{declaration.dateSoumission? (declaration.dateSoumission | date:'dd/MM/yyyy'):''}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-6 form-control-label">Nom du projet : {{declaration.nomProjet}}</label>
            <label class="col-md-6 form-control-label">Date de finalisation : {{declaration.dateFinalisation? (declaration.dateFinalisation | date:'dd/MM/yyyy'):''}}</label>
            <label *ngIf="declaration.statutDeclarationId==1900" class="col-md-2 form-control-label">Rapport de fin de travaux :</label>
            <button *ngIf="declaration.statutDeclarationId==1900" type="button" (click)="downloadRapport()" class="btn btn-primary btn-sm" title="Télécharger le rapport">
                <span class="fas fa-paperclip"></span>
            </button>
        </div>
    </div>
    <div class="view-declaration-box">
        <h4 class="fs-title">Caractéristiques de la déclaration : </h4>
        <div class="row form-row">
            <label class="col-md-6 form-control-label" *ngIf="declaration.dateDebutTravaux && declaration.dateFinTravaux">Période envisagée des travaux : Du {{declaration.dateDebutTravaux ? (declaration.dateDebutTravaux | date:'dd/MM/yyyy'):''}} au {{declaration.dateFinTravaux ? (declaration.dateFinTravaux | date:'dd/MM/yyyy') : ''}}</label>
            <label class="col-md-6 form-control-label">Fonction : {{declaration.fonctionId?fonction.libelleComplet:''}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-6 form-control-label" *ngIf="declaration.relationId">Relation entre les ouvrages : {{declaration.relationLibelle}}</label>
            <label class="col-md-6 form-control-label">Substance : {{declaration.fonctionId?fonction.typeRessourceLibelle:''}}</label>
        </div>

        <div class="row form-row">
            <!--<label class="col-md-6 form-control-label">Concerne une ICPE : {{declaration.typeIcpeId? declaration.typeIcpeLibelle:'Non'}}</label>-->
            <label class="col-md-6 form-control-label" *ngIf="declaration.usageId">Usage : {{declaration.usageId?declaration.usageLibelle:''}}</label>
            <label class="col-md-6 form-control-label" *ngIf="declaration.prelevement">Volume : {{declaration.prelevement | number:'0.5-5':'fr'}}</label>
        </div>
    </div>
    <div class="view-declaration-box">
        <h4 class="fs-title">Acteurs : </h4>
        <div class="row form-row">
            <label class="col-md-6 form-control-label">Déclarant : {{declarant && declarant.statutJuridiqueLibelle == "Professionnel" ?declarant.raisonSocialeEtab:'' }} 
                                                                    {{declarant && declarant.contact && declarant.statutJuridiqueLibelle == "Particulier"?declarant.contact.prenom:''}}
                                                                    {{declarant && declarant.contact && declarant.statutJuridiqueLibelle == "Particulier"?declarant.contact.nom:''}}</label>
            <label class="col-md-6 form-control-label">Maitre d'ouvrage : {{declaration.acteurMoaId?(declaration.acteurMoa.raisonSocialeEtab?declaration.acteurMoa.raisonSocialeEtab:(declaration.acteurMoa.contact?declaration.acteurMoa.contact.prenom + ' ' + declaration.acteurMoa.contact.nom:'')):'-'}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-6 form-control-label">Adresse : {{declarant && declarant.adresse && declarant.adresse.bp?'BP '+declarant.adresse.bp+', ':''}}{{declarant && declarant.adresse?declarant.adresse.nomVoie:'-'}}, {{declarant && declarant.adresse && declarant.adresse.codePostal?declarant.adresse.codePostal:''}} {{declarant && declarant.adresse?(declarant.adresse.communeInseeCom?declarant.adresse.communeNomCom:declarant.adresse.lieuDit):''}}{{declarant && declarant.adresse && declarant.adresse.cedex?'CEDEX '+declarant.adresse.cedex:''}}, {{declarant && declarant.adresse?declarant.adresse.pays:''}}</label>
            <label class="col-md-6 form-control-label">SIRET : {{declaration.acteurMoaId?declaration.acteurMoa.siretEtab:'-'}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-6 form-control-label">Téléphone : {{declarant && declarant.contact && declarant.contact.tel2?declarant.contact.tel2:'-'}} (mobile) / {{declarant && declarant.contact && declarant.contact.tel1?declarant.contact.tel1:'-'}} (fixe)</label>
            <label class="col-md-6 form-control-label">Adresse : {{declaration.acteurMoaId && declaration.acteurMoa.adresse?declaration.acteurMoa.adresse.nomVoie:'-'}}, {{declaration.acteurMoa && declaration.acteurMoa.adresse && declaration.acteurMoa.adresse.codePostal?declaration.acteurMoa.adresse.codePostal:''}} {{declaration.acteurMoa && declaration.acteurMoa.adresse && declaration.acteurMoa.adresse.communeInseeCom?declaration.acteurMoa.adresse.communeNomCom:declaration.acteurMoa.adresse.lieuDit}}, {{declaration.acteurMoa && declaration.acteurMoa.adresse?declaration.acteurMoa.adresse.pays:'-'}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-6 form-control-label">Courriel : {{declarant?declarant.email:''}}</label>
            <label class="col-md-6 form-control-label">Contact : {{declaration.acteurMoaId && declaration.acteurMoa.contact?declaration.acteurMoa.contact.prenom:''}} {{declaration.acteurMoa && declaration.acteurMoa.contact?declaration.acteurMoa.contact.nom:''}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-6 form-control-label"></label>
            <label class="col-md-6 form-control-label">Téléphone : {{declaration.acteurMoaId && declaration.acteurMoa.contact?declaration.acteurMoa.contact.tel2:'-'}} (mobile) / {{declaration.acteurMoa && declaration.acteurMoa.contact?declaration.acteurMoa.contact.tel1:'-'}} (fixe)</label>
        </div>
        <div class="row form-row">
            <label class="col-md-6 form-control-label"></label>
            <label class="col-md-6 form-control-label">Courriel : {{declaration.acteurMoaId?declaration.acteurMoa.email:'-'}}</label>
        </div>
    </div>
    <div class="view-declaration-box">
        <h4 class="fs-title">Ouvrages : </h4>
        <div *ngFor="let ouvrage of ouvrages" class="row form-row">
            <label class="col-md-12 fs-title">{{ouvrage.nomUsuel}}</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Code BSS :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.indiceBss}}</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Nature :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.natureOuvrageLibelle}}</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Verticalité :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.directionLibelle}} sur {{ouvrage.longeur | number}} m</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Adresse :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.adresse?ouvrage.adresse.nomVoie:'-'}}, {{ouvrage.adresse?(ouvrage.adresse.communeInseeCom?ouvrage.adresse.communeNomCom:ouvrage.adresse.lieuDit):"-"}}, {{ouvrage.adresse?ouvrage.adresse.pays:'-'}}</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Références cadastrales :</label>
            <label class="col-md-8 form-control-label">Section {{ouvrage.numSection}}, Parcelle {{ouvrage.numParcelle}}</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Coordonnées :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.x | number:'0.5-5':'fr'}} {{ouvrage.srsId? getUnite(ouvrage.srsId):''}}, {{ouvrage.y | number:'0.5-5':'fr'}} {{ouvrage.srsId? getUnite(ouvrage.srsId):''}} ({{ouvrage.srsLibelle}}), {{ouvrage.modeXYLibelle}}</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Altitude :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.zcalc}} m, {{ouvrage.modeZCalcLibelle}}</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Propriétaire :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurProprio?(ouvrage.acteurProprio.raisonSocialeEtab?ouvrage.acteurProprio.raisonSocialeEtab:(ouvrage.acteurProprio.contact?ouvrage.acteurProprio.contact.prenom + ' ' + ouvrage.acteurProprio.contact.nom:'')):'-'}}</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Adresse :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurProprio && ouvrage.acteurProprio.adresse && ouvrage.acteurProprio.adresse.bp?'BP '+ouvrage.acteurProprio.adresse.bp+', ':''}}{{ouvrage.acteurProprio && ouvrage.acteurProprio.adresse?ouvrage.acteurProprio.adresse.nomVoie:'-'}}, {{ouvrage.acteurProprio && ouvrage.acteurProprio.adresse && ouvrage.acteurProprio.adresse.codePostal?ouvrage.acteurProprio.adresse.codePostal:''}} {{ouvrage.acteurProprio && ouvrage.acteurProprio.adresse?(ouvrage.acteurProprio.adresse.communeInseeCom?ouvrage.acteurProprio.adresse.communeNomCom:ouvrage.acteurProprio.adresse.lieuDit):''}}{{ouvrage.acteurProprio && ouvrage.acteurProprio.adresse && ouvrage.acteurProprio.adresse.cedex?'CEDEX '+ouvrage.acteurProprio.adresse.cedex:''}}, {{ouvrage.acteurProprio && ouvrage.acteurProprio.adresse?ouvrage.acteurProprio.adresse.pays:''}}</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Téléphone :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurProprio && ouvrage.acteurProprio.contact?ouvrage.acteurProprio.contact.tel2:'-'}} (mobile) / {{ouvrage.acteurProprio && ouvrage.acteurProprio.contact?ouvrage.acteurProprio.contact.tel1:'-'}} (fixe)</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Courriel :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurProprio?ouvrage.acteurProprio.email:'-'}}</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Maitre d'oeuvre :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurMoe?(ouvrage.acteurMoe.raisonSocialeEtab?ouvrage.acteurMoe.raisonSocialeEtab:(ouvrage.acteurMoe.contact?ouvrage.acteurMoe.contact.prenom + ' ' + ouvrage.acteurMoe.contact.nom:'')):'-'}}</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Adresse :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurMoe && ouvrage.acteurMoe.adresse && ouvrage.acteurMoe.adresse.bp?'BP '+ouvrage.acteurMoe.adresse.bp+', ':''}}{{ouvrage.acteurMoe && ouvrage.acteurMoe.adresse?ouvrage.acteurMoe.adresse.nomVoie:'-'}}, {{ouvrage.acteurMoe && ouvrage.acteurMoe.adresse && ouvrage.acteurMoe.adresse.codePostal?ouvrage.acteurMoe.adresse.codePostal:''}} {{ouvrage.acteurMoe && ouvrage.acteurMoe.adresse?(ouvrage.acteurMoe.adresse.communeInseeCom?ouvrage.acteurMoe.adresse.communeNomCom:ouvrage.acteurMoe.adresse.lieuDit):''}}{{ouvrage.acteurMoe && ouvrage.acteurMoe.adresse && ouvrage.acteurMoe.adresse.cedex?'CEDEX '+ouvrage.acteurMoe.adresse.cedex:''}}, {{ouvrage.acteurMoe && ouvrage.acteurMoe.adresse?ouvrage.acteurMoe.adresse.pays:''}}</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Téléphone :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurMoe && ouvrage.acteurMoe.contact?ouvrage.acteurMoe.contact.tel2:'-'}} (mobile) / {{ouvrage.acteurMoe && ouvrage.acteurMoe.contact?ouvrage.acteurMoe.contact.tel1:'-'}} (fixe)</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Courriel :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurMoe?ouvrage.acteurMoe.email:'-'}}</label>
            <span class="col-md-1"></span>
            <label class="col-md-3 form-control-label">Entreprise de forage :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurForeur?(ouvrage.acteurForeur.raisonSocialeEtab?ouvrage.acteurForeur.raisonSocialeEtab:(ouvrage.acteurForeur.contact?ouvrage.acteurForeur.contact.prenom + ' ' + ouvrage.acteurForeur.contact.nom:'')):'-'}}</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Adresse :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurForeur && ouvrage.acteurForeur.adresse && ouvrage.acteurForeur.adresse.bp?'BP '+ouvrage.acteurForeur.adresse.bp+', ':''}}{{ouvrage.acteurForeur && ouvrage.acteurForeur.adresse?ouvrage.acteurForeur.adresse.nomVoie:'-'}}, {{ouvrage.acteurForeur && ouvrage.acteurForeur.adresse && ouvrage.acteurForeur.adresse.codePostal?ouvrage.acteurForeur.adresse.codePostal:''}} {{ouvrage.acteurForeur && ouvrage.acteurForeur.adresse?(ouvrage.acteurForeur.adresse.communeInseeCom?ouvrage.acteurForeur.adresse.communeNomCom:ouvrage.acteurForeur.adresse.lieuDit):''}}{{ouvrage.acteurForeur && ouvrage.acteurForeur.adresse && ouvrage.acteurForeur.adresse.cedex?'CEDEX '+ouvrage.acteurForeur.adresse.cedex:''}}, {{ouvrage.acteurForeur && ouvrage.acteurForeur.adresse?ouvrage.acteurForeur.adresse.pays:''}}</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Téléphone :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurForeur && ouvrage.acteurForeur.contact?ouvrage.acteurForeur.contact.tel2:'-'}} (mobile) / {{ouvrage.acteurForeur && ouvrage.acteurForeur.contact?ouvrage.acteurForeur.contact.tel1:'-'}} (fixe)</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Courriel :</label>
            <label class="col-md-8 form-control-label">{{ouvrage.acteurForeur?ouvrage.acteurForeur.email:'-'}}</label>
            <span class="col-md-2"></span>
            <label class="col-md-2 form-control-label">Pièces jointes :</label>
            <button type="button" (click)="openPopup(ouvrage.id, ouvrage.nomUsuel)" class="btn btn-primary btn-sm" title="Ajouter une pièce jointe">
                <span class="fas fa-paperclip"></span>
            </button>
        </div>
    </div>
    <!--<div class="alert alert-warning">
        <h4 class="fs-title">Informations géographiques (source INPN) : </h4>
        <div class="saving" [hidden]="!isLoading">
            <i class="pi pi-spin pi-spinner" style="position: relative; top: 2px"></i>
            Chargement en cours...
        </div>
        <div class="form-group" [hidden]="isLoading">
            <label class="form-control-label">Pour information, un ou plusieurs ouvrages de votre déclaration sont dans les zones géographiques suivantes :</label>
            <div class="form-control-label" *ngFor="let zone of zones">&bull;&nbsp;{{zone}}</div>
        </div>
    </div>-->
    <div class="view-declaration-box" *ngIf="administrations">
        <h4 class="fs-title">Informations règlementaires : </h4>
        <div class="form-group">
            <!--<label class="form-control-label" *ngIf="isMinier()">Cette déclaration sera enregistrée au titre de l'article L.411-1 (anciennement 131) du Code Minier et transmise à la DREAL. Un code BSS, identifiant unique, va être attribué à chaque ouvrage.</label>
            <label class="form-control-label" *ngIf="isLoiEau()|| isLoiIcpe()">Les données saisies vont être transmises au service instructeur. Cela ne dispense en aucun cas de déposer un dossier à ce service au titre de la loi sur l’eau, en rappelant le n°BSS qui va a été attribué à chaque ouvrage.</label>
            <label class="form-control-label" *ngIf="isForageDomestique()">Une fois soumise, cette déclaration conduira à l’édition d’un formulaire CERFA pré-rempli, ce document sera à imprimer, à signer après vérification des informations, et à transmettre à la mairie de votre commune, au titre de l'article L.2224-9 du Code général des collectivités territoriales. Cette déclaration sera à compléter au plus tard un mois après l'achèvement des travaux (Article R.2224-22-1).
                Pour un usage unifamiliale, une analyse de l'eau de type P1, à l’exception du chlore, définie dans l’arrêté du 11 janvier 2007 (relatif au programme de prélèvements et d’analyses du contrôle sanitaire pour les eaux fournies par un réseau de distribution) doit être réalisée et jointe à la déclaration ; pour les ouvrages à réaliser l’analyse est transmise après travaux ; pour les autres cas, une autorisation préfectorale doit être demandée au titre de l’article L.1321-7 du code de la santé publique.
                Conformément au décret n°91-1147 du 14 octobre 1991, toute personne désirant réaliser un ouvrage souterrain doit se renseigner au préalable auprès de sa mairie sur l’existence et les zones d’implantation éventuelles d’ouvrages souterrains, aériens ou subaquatiques impactés par son projet et envoyer une demande de renseignements (DR) à chacun des exploitations de ces ouvrages afin que les travaux envisagés puissent être exécutés en toute sécurité.
                </label>
            <label class="form-control-label">Avertissement : vous allez transmettre les informations de cette télé-déclaration au(x) service(s) instructeur(s), et vous vous engagez à réaliser l’ouvrage déclaré.</label>-->
            <label>Cette déclaration vous engage à poursuivre la procédure.

                Elle ne vaut pas autorisation de réaliser l'ouvrage au titre des autres réglementations qui s'appliquent à votre projet.

                Compte tenu des éléments déclarés vous devez vous rapprocher de <span *ngFor="let administration of administrations">la {{administration}}, </span>qui a été informée de l'attribution de ce code BSS.
            </label>
        </div>
    </div>
</div>
