export class LexSystemeReferenceSpatial {
    constructor(
        public id?: number,
        public code?: string,
        public epsg?: number,
        public libelle?: string,
        public systemeCoordonnees?: string,
        public typeSystemeReference?: string,
        public xLib?: string,
        public xMin?: number,
        public xMax?: number,
        public yLib?: string,
        public yMin?: number,
        public yMax?: number,
        public description?: string,
        public ordre?: number,
        public valide?: boolean,
        public dateCreation?: any,
        public dateModification?: any,
        public mnemo?: string,
        public uniteXYId?: number,
    ) {
        this.valide = false;
    }
}
