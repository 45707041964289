import { Component, Input } from '@angular/core';

import { Contact } from '../../entities/contact/contact.model';


@Component({
  selector: 'dup-contact-form',
  templateUrl: './contactform.component.html',
  styles: []
})
export class ContactFormComponent {
  
  // contact en cours
  @Input()
  contact: Contact;

  constructor(
  ) {
    this.contact = new Contact();
  }

}
