import { Component, OnInit, OnDestroy, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';

import * as _ from 'lodash';

import { LexFonctionService } from 'src/app/entities/lex-fonction/lex-fonction.service';
import { LexSubstanceService } from 'src/app/entities/lex-substance/lex-substance.service';
import { LexUsageService } from 'src/app/entities/lex-usage/lex-usage.service';
import { LexRegimeIcpeService } from 'src/app/entities/lex-regime-icpe/lex-regime-icpe.service';
import { LexRelationService } from 'src/app/entities/lex-relation/lex-relation.service';
import { LexTypeIcpeService } from 'src/app/entities/lex-type-icpe/lex-type-icpe.service';
import { LexSystemeReferenceSpatialService } from 'src/app/entities/lex-systeme-reference-spatial/lex-systeme-reference-spatial.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { DuplosEventManager } from 'src/app/shared/event/event.service';
import { Declaration } from 'src/app/entities/declaration/declaration.model';
import { LexFonction } from 'src/app/entities/lex-fonction/lex-fonction.model';
import { LexSubstance } from 'src/app/entities/lex-substance/lex-substance.model';
import { LexUsage } from 'src/app/entities/lex-usage/lex-usage.model';
import { LexStatutDeclaration } from 'src/app/entities/lex-statut-declaration/lex-statut-declaration.model';
import { LexRegimeIcpe } from 'src/app/entities/lex-regime-icpe/lex-regime-icpe.model';
import { LexRelation } from 'src/app/entities/lex-relation/lex-relation.model';
import { LexTypeDeclaration } from 'src/app/entities/lex-type-declaration/lex-type-declaration.model';
import { LexTypeIcpe } from 'src/app/entities/lex-type-icpe/lex-type-icpe.model';
import { LexTypeSaisieCoor } from 'src/app/entities/lex-type-saisie-coor/lex-type-saisie-coor.model';
import { LexSystemeReferenceSpatial } from 'src/app/entities/lex-systeme-reference-spatial/lex-systeme-reference-spatial.model';
import { LexOperation } from 'src/app/entities/lex-operation/lex-operation.model';
import { FieldOption } from '../hierarchical-select/data';
import { CheckboxModule } from 'primeng/checkbox';

import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dup-declaration',
    templateUrl: './declarationform.component.html',
    styleUrls: [
      '../forms.css'
    ],
    encapsulation: ViewEncapsulation.None
  })
  export class DeclarationFormComponent implements OnInit, OnDestroy  {


  /*public fr:any = {
    firstDayOfWeek: 1,
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di", "Lu","Ma","Me","Je","Ve","Sa"],
    monthNames: [ "Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre" ],
    monthNamesShort: [ "Jan", "Fév", "Mar", "Avr", "Mai", "Juin","Juil", "Aoû", "Sep", "Oct", "Nov", "Déc" ],
    today: 'Aujourd\'hui',
    clear: 'vider',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sem'
  };*/

    // declaration en cours
  @Input() declaration: Declaration;
  @Input() typeDeclaration: LexTypeDeclaration;
  @Output() declarationChange = new EventEmitter<Declaration>();
  //@Output() domestique: EventEmitter<Boolean> = new EventEmitter();
  @Output() eau: EventEmitter<boolean> = new EventEmitter();
  @Output() changeOuvrages: EventEmitter<boolean> = new EventEmitter();

  // les differents lexiques
  lexfonctions: LexFonction[];
  lexsubstances: LexSubstance[];
  lexusages: LexUsage[];
  lexstatutdeclarations: LexStatutDeclaration[];
  lexregimeicpes: LexRegimeIcpe[];
  lexrelations: LexRelation[] = [];
  lextypeicpes: LexTypeIcpe[];
  lextypesaisiecoor: LexTypeSaisieCoor[];
  lexsrs: LexSystemeReferenceSpatial[];
  lexoperations: LexOperation[];

  // liste hierarchique des lexiques concernes
  fonctionsOptions: FieldOption[];
  substancesOptions: FieldOption[];
  usagesOptions: FieldOption[];

  selectedFonction: FieldOption;
  selectedSubstance: FieldOption;
  selectedFonctionFinal: FieldOption;
  selectedSubstanceFinal: FieldOption;
  selectedUsage: FieldOption[];

  // concerne une ICPE ?
  isICPE: boolean = false;
  // concerne de l'eau
  isEau: boolean = false;
  // exploitation/eau
  isExploit: boolean = false;

  showPrelevementWarning : boolean = false;

  // concerne des substances
  isSubstance: boolean = false;
  // concerne les forages domestiques
  isDomestique: boolean = false;

  isDebutOK:boolean = true;
  isFinOK:boolean = true;
  isDebutTooSoon:boolean = false;
  isFinKO:boolean = false;

  minDate:Date;
  maxDate:Date;

  maxSelectableDate:Date = null;
  minSelectableDate:Date = null;  

  typeReglementation = "MIN";

  arrosage:boolean = false;
  abreuvage:boolean = false;
  sanitaire:boolean = false;
  lavageNettoyage:boolean = false;
  piscineBassin:boolean = false;
  consommationHumaine:boolean = false;
  unifamilial: boolean = false;
  autreCasUsage: boolean = false;

  // Langue PrimePNG
  subscription: Subscription;

  constructor(
    private lexFonctionService: LexFonctionService,
    private lexSubstanceService: LexSubstanceService,
    private lexUsageService: LexUsageService,
    private lexRegimeIcpeService: LexRegimeIcpeService,
    private lexRelationService: LexRelationService,
    private lexTypeIcpeService: LexTypeIcpeService,
    private lexSystemeReferenceSpatialService: LexSystemeReferenceSpatialService,
    private alertService: AlertService,
    private eventManager: DuplosEventManager,
    private primeNGConfig: PrimeNGConfig,
    private translateService: TranslateService
  ) 
  {
    this.fonctionsOptions = [];
    this.substancesOptions = [];
    this.usagesOptions = [];
    this.selectedFonction = null;
    this.selectedUsage = [];
    this.minDate = null;
    this.maxDate = null;

    this.translateService.addLangs(['fr']);
    this.translateService.setDefaultLang('fr');

    this.subscription = this.translateService.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });

  }

  ngOnInit(): void {

    if (sessionStorage.getItem("typeReglementation") !== null) {
      this.typeReglementation = sessionStorage.getItem("typeReglementation");
    }

    if (sessionStorage.getItem('typeReglementation') !== null && sessionStorage.getItem('typeReglementation') == 'FORDOM'){
      this.declaration.plusieursOuvrages = false;
    }
    
    this.lexRegimeIcpeService.query()
    .subscribe((res) => { this.lexregimeicpes = res; }, (res) => this.onError(res));
    this.lexRelationService.query()
      .subscribe((res) => {
        res.forEach(rel => {
          if(rel.valide) this.lexrelations.push(rel);
        });
      }, (res) => this.onError(res));
    this.lexTypeIcpeService.query()
      .subscribe((res) => { this.lextypeicpes = res; }, (res) => this.onError(res));
    this.lexSystemeReferenceSpatialService.query()
      .subscribe((res) => { this.lexsrs = res; }, (res) => this.onError(res));
    this.lexFonctionService.query()
      .subscribe((res) => {
        this.lexfonctions = res;
        this.fonctionsOptions = this.makeFonctionTree();
        if(this.declaration.fonctionId == null){
          setTimeout( () => { this.loadFonction() }, 1500 );
        }else{
          this.loadFonction()
        }
      }, (res) => this.onError(res));
    this.lexSubstanceService.query()
      .subscribe((res) => {
        this.lexsubstances = res;
        this.substancesOptions = this.makeSubstanceTree();
        if(this.declaration.fonctionId){
          setTimeout( () => { this.loadSubstance() }, 1500 );
        }else{
          this.loadSubstance()
        }
      }, (res) => this.onError(res));
    this.lexUsageService.query()
      .subscribe((res) => {
        this.lexusages = res;
        this.usagesOptions = this.makeUsageTree();
        if(this.declaration.fonctionId){
          setTimeout( () => { this.loadUsage() }, 1500 );
        }else{          
          // Si on est dans le cas d'une déclaration Code Minier alors, pas d'usage "Usage domestique"
          if (sessionStorage.getItem('typeReglementation') !== null && sessionStorage.getItem('typeReglementation') == 'MIN'){
            this.usagesOptions = this.usagesOptions.filter(function (item,idx){ if ( item.text != "Usage domestique") return item;});
          } else if (sessionStorage.getItem('typeReglementation') !== null && sessionStorage.getItem('typeReglementation') == 'FORDOM'){
            this.declaration.usageId = Number(this.usagesOptions.filter(function (item,idx){ if ( item.text == "Usage domestique") return item;})[0].value);
            
          }
          this.loadUsage();
          
        }
      }, (res) => this.onError(res));
  }
  
  ngOnDestroy(): void {
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
  }

  translate(lang: string){
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
  }

  loadFonction() {
    if (this.typeReglementation == "FORDOM"){
      this.selectedFonction = this.fonctionsOptions.find(fopt => fopt.text == "EXPLOITATION");
      this.selectedFonctionFinal = this.selectedFonction.children.find(fopt => fopt.text == "EAU");
      this.isExploit = true;
      this.declaration.fonctionId = Number(this.selectedFonctionFinal.value);
      this.declaration.fonctionLibelleComplet = this.selectedFonctionFinal.text;
    }

    if (this.declaration.fonctionId && this.selectedFonction == undefined && this.lexfonctions) {
      let leaf = _.find(this.lexfonctions, ['id', this.declaration.fonctionId]);
      this.isEau = _.includes(leaf.libelleComplet, 'EXPLOITATION/EAU') || _.includes(leaf.libelleComplet, 'STOCKAGE/EAU/INJECTION') || _.includes(leaf.libelleComplet, 'STOCKAGE/EAU/RECHARGE');
      this.isExploit = leaf.code == '300';
      this.eau.emit(this.isEau);
      this.isSubstance = _.includes(leaf.libelleComplet, "ROCHES-SUBSTANCES");
      if (leaf.parentId) {
        let parent = this.findFonctionParent(leaf);
        this.selectedFonction = _.find(this.fonctionsOptions, ['value', parent.id.toString()]);
        this.selectedFonctionFinal = _.find(this.selectedFonction.children, ['value', leaf.id.toString()]);
      } else {
        this.selectedFonction = _.find(this.fonctionsOptions, ['value', leaf.id.toString()]);
      }
      /*if (this.declaration.prelevement) {
        this.prelevementChange(this.declaration.prelevement);
      }*/
    }

    if(this.typeDeclaration.code == '1') {
      this.minDate = new Date();
    } else if(this.typeDeclaration.code == '6') {
      this.maxDate = new Date();
      this.maxSelectableDate = new Date(this.maxDate.getTime() - 86400000);
    }
  }

  loadSubstance() {
    if (this.declaration.substanceId && this.selectedSubstance == undefined && this.lexsubstances) {
      let leaf = _.find(this.lexsubstances, ['id', this.declaration.substanceId]);
      if (leaf.parentId) {
        let parent = this.findSubstanceParent(leaf);
        this.selectedSubstance = _.find(this.substancesOptions, ['value', parent.id.toString()]);
        this.selectedSubstanceFinal = _.find(this.selectedSubstance.children, ['value', leaf.id.toString()]);
      } else {
        this.selectedSubstance = _.find(this.substancesOptions, ['value', leaf.id.toString()]);
      }
    }
  }

  loadUsage() {
      /*if (this.typeReglementation == "FORDOM"){
        console.log(this.fonctionsOptions);
        console.log(this.lexusages);
        //this.selectedUsage = this.lexusages.find((value: LexUsage, index: number, obj: LexUsage[]) => value.libelle == "Usage domestique");
        this.selectedUsage = this.usagesOptions.filter(function (item,idx){ if ( item.text != "Usage domestique") return item;});
      }*/

    if (this.declaration.usageId && this.selectedUsage.length == 0 && this.lexusages) {
      let foArray: FieldOption[] = [];
      let leaf = _.find(this.lexusages, ['id', this.declaration.usageId]);
      let leafFo = new FieldOption(leaf.libelle, leaf.id.toString(), leaf.selectionnable, new Array<FieldOption>());
      if (leaf.parentId) {
        foArray.push(this.initSelectable(leafFo, this.lexusages, leaf.parentId));
      } else {
        foArray.push(leafFo);
      }
      this.selectedUsage = foArray;
    }
    this.splitDeclarationUsage();
  }

    // gestion des erreurs de communication
  private onError(error: any) {
    this.alertService.error(error.message, null, null);
  }

  // gestion de l'event change sur concerne une ICPE
  changeICPE(event) {
    this.isICPE = this.declaration.typeIcpeId != undefined && this.declaration.typeIcpeId != null;

  }

  changeNbOuvrages(event) {
    if (!this.declaration.plusieursOuvrages) {
      this.declaration.relationId = null;
      this.declaration.relationLibelle = null;
    }
    this.changeOuvrages.emit(this.declaration.plusieursOuvrages);
  }

  // creation de l'arbre des fonctions
  makeUsageTree(): FieldOption[] {
    const optionsArray = new Array<FieldOption>();
    let rootUsage = _.filter(this.lexusages, ["parentId", null]);
    rootUsage.forEach(usage => {
      this.createUsageOption(usage, optionsArray);
    });
    return optionsArray;
  }

  createUsageOption(usage: LexUsage, optionsArray: Array<FieldOption>) {
    if(usage.valide) {
      const option = new FieldOption(usage.libelle, usage.id.toString(), usage.selectionnable, new Array<FieldOption>());
      optionsArray.push(option);
      let usageChildren = _.filter(this.lexusages, ["parentId", usage.id]);
      if (usageChildren.length > 0) {
        usageChildren.forEach(child => this.createUsageOption(child, option.children));
      }
    }
  }

  // creation de l'arbre des fonctions
  makeFonctionTree(): FieldOption[] {
    const optionsArray = new Array<FieldOption>();
    this.lexfonctions.forEach(fonction => {
      if (!fonction.parentId && fonction.valide) {
        const option = new FieldOption(fonction.libelleComplet, fonction.id.toString(), fonction.selectionnable, new Array<FieldOption>());
        optionsArray.push(option);
      }
    });
    this.lexfonctions.forEach(fonction => {
      //let isParent = _.find(this.lexfonctions, ["parentId", fonction.id]);
      if (fonction.parentId && fonction.valide && fonction.selectionnable) {
        let parent = this.findFonctionParent(fonction);
        const option = _.find(optionsArray, ['value', parent.id.toString()]);
        const optionChild = new FieldOption(fonction.libelleComplet.substring(parent.libelleComplet.length + 1), fonction.id.toString(), fonction.selectionnable, new Array<FieldOption>());
        option.children.push(optionChild);
      }
    });

    return optionsArray;
  }

  // creation de l'arbre des substances
  makeSubstanceTree(): FieldOption[] {
    const optionsArray = new Array<FieldOption>();
    this.lexsubstances.forEach(substance => {
      if (!substance.parentId) {
        const option = new FieldOption(substance.libelle, substance.id.toString(), false, new Array<FieldOption>());
        optionsArray.push(option);
      }
    });
    this.lexsubstances.forEach(substance => {
      if (substance.parentId) {
        let parent = this.findSubstanceParent(substance);
        const option = _.find(optionsArray, ['value', parent.id.toString()]);
        const optionChild = new FieldOption(substance.libelle, substance.id.toString(), true, new Array<FieldOption>());
        option.children.push(optionChild);
      }
    });

    return optionsArray;
  }

  findFonctionParent(fonction: LexFonction) {
    let parent = _.find(this.lexfonctions, ["id", fonction.parentId]);
    if (parent.parentId) {
      return this.findFonctionParent(parent);
    } else {
      return parent;
    }
  }

  findSubstanceParent(fonction: LexSubstance) {
    let parent = _.find(this.lexsubstances, ["id", fonction.parentId]);
    if (parent.parentId) {
      return this.findSubstanceParent(parent);
    } else {
      return parent;
    }
  }

  // retourne l'option parent
  findParentOption(optionList: FieldOption[], parentId: String): FieldOption {
    let result: FieldOption;
    for (let i = 0, len = optionList.length; i < len; i++) {
      const option = optionList[i];
      if (option.value === parentId) {
        result = option;
        break;
      }
      if (option.children.length > 0) {
        result = this.findParentOption(option.children, parentId);
      }
    }
    return result;
  }

  /*parentDomestique() {
    this.domestique.emit(this.isDomestique);
  }*/

  onDateDebutSelect(event) {
    if(this.typeDeclaration.code == '1' && this.declaration.dateDebutTravaux) {
      let dateNow = new Date();
      dateNow.setMonth(dateNow.getMonth() + 1);
      if (this.declaration.dateDebutTravaux < dateNow) {
        this.isDebutTooSoon = true;
      } else this.isDebutTooSoon = false;
    }
    this.onDateFinSelect(event);
  }

  onDateFinSelect(event) {
    if (this.declaration.dateFinTravaux && this.declaration.dateDebutTravaux) {
      if (this.declaration.dateFinTravaux <= this.declaration.dateDebutTravaux) {
        this.isFinKO = true;
      } else this.isFinKO = false;
    }
    this.computeSelectableDates();
  }
  computeSelectableDates(){

    if(this.declaration.dateFinTravaux){
      this.maxSelectableDate = new Date(this.declaration.dateFinTravaux?.getTime() - 86400000);
    }
    if(this.declaration.dateDebutTravaux){
      this.minSelectableDate = new Date(this.declaration.dateDebutTravaux?.getTime() + 86400000);
    }    
  }

  fonctionChange(event, isLeaf: boolean) {
    this.showPrelevementWarning = false;
    if (event) {
      const fonction = _.find(this.lexfonctions, ['id', Number(event.value)]);
      if (fonction?.selectionnable) {
        this.declaration.fonctionId = fonction.id;
        this.declaration.fonctionLibelleComplet = fonction.libelleComplet;
        // regarde si doit afficher usage et prelevement
        this.isEau = _.includes(fonction.libelleComplet, 'EXPLOITATION/EAU') || _.includes(fonction.libelleComplet, 'STOCKAGE/EAU/INJECTION') || _.includes(fonction.libelleComplet, 'STOCKAGE/EAU/RECHARGE');
        this.isExploit = (fonction.code == '300' || fonction.code == '30001' || fonction.code == '30002');
        this.isSubstance = _.includes(fonction.libelleComplet, "ROCHES-SUBSTANCES");
        if(fonction.libelleComplet == 'EXPLOITATION/EAU') {
          this.showPrelevementWarning = true;
        }
      }
    } else {
      this.declaration.fonctionId = null;
      this.declaration.fonctionLibelleComplet = null;
      this.isEau = false;
      this.isSubstance = false;
    }
    this.eau.emit(this.isEau);
    if (!this.isEau) {
      this.declaration.usageId = null;
      this.declaration.usageLibelle = null;
    }
    if (!this.isSubstance) {
      this.declaration.substanceId = null;
      this.declaration.substanceLibelle = null;
    }
    if (!isLeaf) {
      this.selectedFonctionFinal = null;
    }
  }

  parentSubstanceChange(event) {
    this.declaration.substanceId = null;
    this.declaration.substanceLibelle = null;
  }

  substanceChange(event) {
    const substance = _.find(this.lexsubstances, ['id', Number(event.value)]);
    if (substance) {
      this.declaration.substanceId = substance.id;
      this.declaration.substanceLibelle = substance.libelle;
    }
  }

  /*prelevementChange(event) {
    const fonction = _.find(this.lexfonctions, ["id", this.declaration.fonctionId]);
    //regarde si doit ajouter un aquifère
    this.isDomestique = fonction.code == "300" && event < 1000;
    this.parentDomestique();
  }*/

  usageChange(event) {
    const usage = this.getItemFromHierarchy(Number(event), this.lexusages);
    if (usage) {
      this.declaration.usageId = usage.id;
      this.declaration.usageLibelle = usage.libelleSelection;
    }
  }

  initSelectable(fo: FieldOption, array: Array<any>, parentId: number): FieldOption {
    let parent = _.find(array, ['id', parentId]);
    const libelle = (parent.libelleSelection) ? parent.libelleSelection : parent.libelle;
    let parentFo = new FieldOption(libelle, parent.id.toString(), parent.selectionnable, new Array<FieldOption>());
    parentFo.children.push(fo);
    if (parent.parentId)
      return this.initSelectable(parentFo, array, parent.parentId);
    return parentFo;
  }

  // retourne le bon item a partir d'un id
  // si l'item n'est pas selectionnable retourne le premier fils
  getItemFromHierarchy(idToFind: number, hierarchy: Array<any>) {
    let selectedItem = hierarchy.find(function (element) {
      return element.id === idToFind;
    });
    if (selectedItem) {
      if (!selectedItem.selectionnable) {
        selectedItem = null;
      }
    }
    return selectedItem;
  }

  tooltip() {
    let tooltip="Au sens de l’article R. 1321-1 du code de la santé publique";
   return tooltip;
  }

  updateUsagesFordom(event){
    //console.log(this.declaration.autreUsagePrecision);
    this.declaration.autreUsagePrecision = "";
    if (this.arrosage) { this.declaration.autreUsagePrecision = this.declaration.autreUsagePrecision + ",ARROSAGE";}
    if (this.abreuvage) { this.declaration.autreUsagePrecision = this.declaration.autreUsagePrecision + ",ABREUVAGE";}
    if (this.sanitaire) { this.declaration.autreUsagePrecision = this.declaration.autreUsagePrecision + ",SANITAIRE";}
    if (this.lavageNettoyage) { this.declaration.autreUsagePrecision = this.declaration.autreUsagePrecision + ",LAVAGE-NETTOYAGE";}
    if (this.piscineBassin) { this.declaration.autreUsagePrecision = this.declaration.autreUsagePrecision + ",PISCINE-BASSIN";}
    if (this.consommationHumaine) { 
      this.declaration.autreUsagePrecision = this.declaration.autreUsagePrecision + ", CONSOMMATION HUMAINE";
    } else {
      this.unifamilial = false;
      this.autreCasUsage = false;
    }
    if (this.unifamilial) { this.declaration.autreUsagePrecision = this.declaration.autreUsagePrecision + ",USAGE UNIFAMILIAL";}
    if (this.autreCasUsage) { this.declaration.autreUsagePrecision = this.declaration.autreUsagePrecision + ",AUTRE CAS D'USAGE";}
    if (this.declaration.autreUsagePrecision.length > 1){
      this.declaration.autreUsagePrecision = this.declaration.autreUsagePrecision.substring(1,this.declaration.autreUsagePrecision.length);
    }
  }

  splitDeclarationUsage(){
    if(!this.declaration || !this.declaration.autreUsagePrecision){
      return;
    }
    let splitted = this.declaration.autreUsagePrecision.split(", "); 
    splitted.forEach((usage) => this.checkAutreUsage(usage));
  }
  
  checkAutreUsage(usage){
    if (usage == "ARROSAGE") { this.arrosage = true;}
    if (usage == "ABREUVAGE") { this.abreuvage = true;}
    if (usage == "SANITAIRE") { this.sanitaire = true;}
    if (usage == "LAVAGE-NETTOYAGE") { this.lavageNettoyage = true;}
    if (usage == "PISCINE-BASSIN") { this.piscineBassin = true;}
    if (usage == "CONSOMMATION HUMAINE") { this.consommationHumaine = true;}
    if (usage == "USAGE UNIFAMILIAL") { this.unifamilial = true;}
    if (usage == "AUTRE CAS D'USAGE") { this.autreCasUsage = true;}
  }

}
