import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'src/app/app.constants';
import { Departement } from './departement.model';
import { DateUtils } from 'src/app/shared/model/date-utils';

@Injectable()
export class DepartementService {

    private resourceUrl =  SERVER_API_URL + 'api/departements';
    private resourceRegUrl =  SERVER_API_URL + 'api/regions';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    find(id: string): Observable<Departement> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findByRegion(id: string): Observable<Departement[]> {
        return this.http.get(`${this.resourceRegUrl}/${id}/departements`).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    query(req?: any): Observable<Departement[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    private convertResponse(res): Departement[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to Region.
     */
    private convertItemFromServer(json: any): Departement {
        const entity: Departement = Object.assign(new Departement(), json);
        return entity;
    }
}