import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/alert/alert.service';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {

    constructor(private alertService: AlertService) {
    }

    intercept(request: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(map((response: HttpEvent<any>) => {
            const headers = [];
            if(response instanceof HttpResponse) {
                response.headers.keys().forEach( name => {
                    if (name.toLowerCase().endsWith('app-alert') || name.toLowerCase().endsWith('app-params')) {
                        headers.push(name);
                    }        
                });
                if (headers.length > 1) {
                    headers.sort();
                    const alertKey = response.headers.get(headers[ 0 ]);
                    if (typeof alertKey === 'string') {
                        if (this.alertService) {
                            this.alertService.success(alertKey, null, null);
                        }
                    }
                }
            }
            return response;
        }),catchError((error:HttpErrorResponse) => {
            return throwError(error); // here, response is an error
        }));
    }
}
