import { Injectable, Component } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class AbandonPopupService {
    private ngbModalRef: NgbModalRef;
    constructor(private modalService: NgbModal) {
        this.ngbModalRef = null;
    }

    abandonModalRef(component: Component, declarationId: number): NgbModalRef {
        const modalRef = this.modalService.open(component, { size: 'lg', backdrop: 'static', centered: true });
        modalRef.componentInstance.declarationId = declarationId;
        return modalRef;
    }
    
    close() {
        this.ngbModalRef.close();
    }
}