import { Routes, Route } from '@angular/router';

import { CaracOuvragePopupComponent } from './caracOuvrageForm/caracouvrageform.component';
import { AquiferePopupComponent } from './aquifereform/aquifereform.component';
import { ForeurFormComponent } from './foreurform/foreurform.component';
import { DeclarantComponent } from './declarantForm/declarant.component';
import { CreationDeclarationFormComponent } from './creationDeclarationForm/creationdeclarationform.component';
import { SettingsComponent } from './accountForm/settings.component';
import { UserRouteAccessService } from '../shared/auth/user-route-access-service';
import { ActeurPopupComponent } from './acteurform/acteur-popup.component';

export const declarationFormRoute: Route = {
    path: 'declarationForm',
    component: CreationDeclarationFormComponent,
    data: {
        pageTitle: 'Nouvelle Declaration'
    },
    runGuardsAndResolvers: 'always',
};

export const declarationUpdateFormRoute: Route = {
    path: 'declarationForm/:id',
    component: CreationDeclarationFormComponent,
    data: {
        pageTitle: 'Modifier Declaration'
    },
    runGuardsAndResolvers: 'always',
};

export const settingsRoute: Route = {
    path: 'settings',
    component: SettingsComponent,
    data: {
        authorities: ['ROLE_USER'],
        pageTitle: 'Mon compte'
    },
    canActivate: [UserRouteAccessService]
};

const FORMS_ROUTE = [
    declarationFormRoute,
    declarationUpdateFormRoute,
    settingsRoute
];

export const formsState: Routes = [{
    path: '',
    data: {
        authorities: ['ROLE_USER']
    },
    canActivate: [UserRouteAccessService],
    children: FORMS_ROUTE
}
];

export const declarantIdentificationRoute: Routes = [
    {
        path: 'declarant',
        component: DeclarantComponent,
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'Declarant'
        }
    }
]

export const ouvragePopupRoute: Routes = [
    {
        path: 'ouvrages/:id',
        component: CaracOuvragePopupComponent,
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'Ouvrage'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];

export const aquiferePopupRoute: Routes = [
    {
        path: 'aquiferes/:id',
        component: AquiferePopupComponent,
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'Aquifere'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];

export const acteurPopupRoute: Routes = [
    {
        path: 'acteurs/:id',
        component: ActeurPopupComponent,
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'Acteur'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];

export const ouvrageUpdateFormRoute: Routes = [
    {
        path: 'foreurForm/:id',
        component: ForeurFormComponent,
        data: {
            pageTitle: 'Ajouter Foreur'
        }
    }
];


