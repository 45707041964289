import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { Acteur } from './acteur.model';
import { DateUtils } from 'src/app/shared/model/date-utils';

@Injectable()
export class ActeurService {

    private resourceUrl =  SERVER_API_URL + 'api/acteurs';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    create(acteur: Acteur): Observable<Acteur> {
        const copy = this.convert(acteur);
        return this.http.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    update(acteur: Acteur): Observable<Acteur> {
        const copy = this.convert(acteur);
        return this.http.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    find(id: number): Observable<Acteur> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findByMail(mail: string): Observable<Acteur> {
        return this.http.get(`${this.resourceUrl}/mail/${mail}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findBySiret(siret: string): Observable<Acteur[]> {
        return this.http.get(`${this.resourceUrl}/siret/${siret}`).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    query(req?: any): Observable<Acteur[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    private convertResponse(res): Acteur[] {
        const result = [];
        if(res) {
          for (let i = 0; i < res.length; i++) {
              result.push(this.convertItemFromServer(res[i]));
          }
        }
        return result;
    }

    /**
     * Convert a returned JSON object to Acteur.
     */
    private convertItemFromServer(json: any): Acteur {
        const entity: Acteur = Object.assign(new Acteur(), json);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateModification = this.dateUtils
            .convertLocalDateFromServer(json.dateModification);
        return entity;
    }

    /**
     * Convert a Acteur to a JSON which can be sent to the server.
     */
    private convert(acteur: Acteur): Acteur {
        const copy: Acteur = Object.assign({}, acteur);
        if(acteur.dateCreation)
            copy.dateCreation = this.dateUtils
                .convertLocalDateToServer(acteur.dateCreation);
        if(acteur.dateModification)
            copy.dateModification = this.dateUtils
                .convertLocalDateToServer(acteur.dateModification);
        return copy;
    }

    getActeurBySiret(siret:string) {
        return this.http.get(`${this.resourceUrl}/searchBySiret/${siret}`).pipe(map((res) => {
            return res;
        }));
    
    }
}
