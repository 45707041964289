import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SERVER_API_URL } from '../../app.constants';
import { Aquifere } from './aquifere.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AquifereService {

    private resourceUrl =  SERVER_API_URL + 'api/aquiferes';

    constructor(private http: HttpClient) {
    }

    findAquifere(x: number, y:number, srs:string): Observable<Aquifere[]> {
        return this.http.get(`${this.resourceUrl}/${x}/${y}/${srs}`).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    private convertResponse(res): Aquifere[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(res[i]);
        }
        return result;
    }
}