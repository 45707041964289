import { Commune } from '../commune/commune.model';

export class Adresse {
    constructor(
        public id?: number,
        public numeroVoie?: string,
        public typeVoie?: string,
        public nomVoie?: string,
        public complement?: string,
        public lieuDit?: string,
        public codePostal?: string,
        public bp?: string,
        public cedex?: string,
        public pays?: string,
        public auteurCreation?:string,
        public auteurModification?:string,
        public dateCreation?: any,
        public dateModification?: any,
        public communeInseeCom?: string,
        public communeNomCom?:string,
        public commune?:Commune
    ) {
    }
}
