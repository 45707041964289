import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { Route } from '@angular/router';
import { HomeComponent } from './login/home.component';

export const HOME_ROUTE: Route = {
    path: '',
    component: HomeComponent,
    data: {
        authorities: [],
        pageTitle: 'DUPLOS'
    }
};

export const DATA: Route = {
  path: 'donnéespersonnelles',
  component: PersonalDataComponent,
  data: {
    authorities: [],
    pageTitle: 'Données personnelles'
  }
}

export const MENTIONS: Route = {
  path: 'mentions-legales',
  component: MentionsLegalesComponent,
  data: {
    authorities: [],
    pageTitle: 'Mentions légales'
  }
}
