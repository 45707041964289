<div  class="col-md-10 offset-md-1">
  <div class="logo form-group">
    <i class="logo-img"></i>
    <h2>Données personnelles</h2>
    <h3>Politique de protection des données personnelles des tiers</h3>
  </div>
  <div class="row">
    <p>Le BRGM attache une grande importance au respect des droits et libertés des personnes et s’engage à assurer la protection et la sécurité des données à caractère personnel des utilisateurs de ses services qu’il est amené à traiter pour les besoins de son activité.</p>
    <p>La notion de données à caractère personnel vise toute information se rapportant à une personne physique identifiée ou pouvant être identifiée, directement ou indirectement, notamment par référence à un identifiant (par exemple, un nom ou un numéro d'identification) ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.</p>
    <p>A ce titre, le BRGM s’engage à respecter le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE, ci-après désigné par « RGPD », et la loi n° 78-17 du 6 janvier 1978 modifiée relative à l'informatique, aux fichiers et aux libertés, ci-après désignés par « la Réglementation ».</p>
    <p>La présente politique décrit comment le BRGM collecte, utilise et gère des données à caractère personnel, ainsi que les droits des utilisateurs concernés.</p>
    <p>Pour toute information sur la protection des données personnelles, vous pouvez également consulter <a href="https://www.cnil.fr/">le site web de la Commission Nationale de l’Informatique et des Libertés.</a></p>
  </div>
  <div class="row">
    <h3>1- Qui contacter en cas de questions sur vos données à caractère personnel ?</h3>
    <p>Le BRGM a désigné un délégué à la protection des données en charge de l’ensemble des problématiques relatives à la protection des données à caractère personnel. Vous pouvez le contacter aux coordonnées suivantes : <a href="mailto:dpo@brgm.fr">dpo@brgm.fr</a></p>
    <h3>2- Quels types de données à caractère personnel vous concernant le BRGM collecte-t-il ?</h3>
    <p>Selon les circonstances, le BRGM est amené à collecter certaines données à caractère personnel listées ci-dessous :</p>
    <ul>
      <li>Informations d'identité : nom, prénom, âge, photographie, sexe, date et lieu de naissance, nationalité, références de titres d'identité.</li>
      <li>Informations de contact : coordonnées professionnelles et personnelles.</li>
      <li>Informations concernant votre parcours professionnel.</li>
      <li>Adresses IP et données de connexion lorsque vous naviguez sur les sites internet du BRGM ou que vous accédez aux systèmes d'information du BRGM.</li>
      <li>Données nécessaires à l'exécution des contrats.</li>
      <li>Données nécessaires à la gestion des relations évènementielles, opérations de communication, formation, sollicitation, promotion, investissement et partenariat.</li>
    </ul>
    <h3>3- Pour quelles raisons et dans quel but le BRGM collecte-t-il vos données à caractère personnel?</h3>
    <p>Le BRGM peut être amené à collecter des données à caractère personnel vous concernant, en qualité de responsable de traitement mais aussi en tant que sous-traitant, pour répondre à vos demandes, pour le respect de ses obligations légales et conventionnelles, l'exécution d'un contrat, pour des motifs d'intérêts publics ou pour la réalisation d'un intérêt légitime.</p>
    <p>Le BRGM collecte des données à caractère personnel vous concernant, le cas échéant par le biais de formulaires et pages de contact, notamment aux fins de traitement d'une inscription en ligne, d'un abonnement à des newsletters ou à des publications, de recueillir les commentaires et répondre aux questions concernant les activités du BRGM et l'information scientifique et technique qu'il produit ou diffuse, d'études statistiques, enquêtes et sondages, à l'occasion de votre consultation de sites internet, de donner accès à des données ou des logiciels en téléchargement ou à des fins d'actions de communication interne et externe telles que la gestion d'événements de communication et l'organisation de sessions de formation, conférences, etc.</p>
    <h3>4- Avec qui le BRGM partage-t-il vos données à caractère personnel ?</h3>
    <p>Le BRGM est susceptible de transférer vos données à caractère personnel à des prestataires et/ou des sous-traitants notamment pour assurer le suivi du traitement de demandes, faire fonctionner, améliorer ou pour maintenir ses activités et ses services et pour administrer ses sites.</p>
    <h3>5- Où sont stockées et traitées vos données à caractère personnel ?</h3>
    <p>Vos données à caractère personnel sont traitées autant que possible au BRGM, sur le territoire national ou de l’Union européenne. Pour en savoir plus, vous pouvez contacter le délégué à la protection des données aux coordonnées suivantes : <a href="mailto:dpo@brgm.fr">dpo@brgm.fr</a>.</p>
    <h3>6- Combien de temps le BRGM conserve-t-il vos données à caractère personnel ?</h3>
    <p>Le BRGM ne conserve vos données à caractère personnel que pendant la durée strictement nécessaire afin de répondre aux finalités décrites précédemment.</p>
    <p>Au terme de ce délai ou suite à une sollicitation de votre part, vos données à caractère personnel sont effacées des bases actives et uniquement conservées sous forme d'archives aux fins d'établissement de la preuve d'un droit, de l'exécution d'une obligation légale ou d'un contrat.</p>
    <p>Conformément à la politique d'archivage, à l'issue de la durée de conservation applicable, il est procédé :</p>
    <ul>
      <li>soit à la suppression ou la destruction sécurisée de vos données à caractère personnel ;</li>
      <li>soit à la stricte anonymisation de vos données à caractère personnel ;</li>
      <li>soit à l'archivage de vos données à caractère personnel à des fins archivistiques dans l'intérêt public, à des fins historiques ou à des fins statistiques.</li>
    </ul>
    <p>Pour en savoir plus, vous pouvez contacter le délégué à la protection des données aux coordonnées suivantes : <a href="mailto:dpo@brgm.fr">dpo@brgm.fr</a>.</p>
    <h3>7- Quels sont vos droits sur vos données à caractère personnel ?</h3>
    <p>Vous disposez de droits sur vos données à caractère personnel. Conformément à la Réglementation, notamment les articles 15 à 22 du RGPD, et après avoir justifié de votre identité, vous avez le droit d’en solliciter l'accès, la rectification ou l'effacement. En outre, dans les limites posées par la loi, vous disposez également du droit de vous opposer au traitement, d'en demander la limitation, de décider du sort post-mortem de vos données à caractère personnel, et d'exercer le droit à la portabilité des données à caractère personnel fournies. Attention : ces droits ne sont pas absolus et restent limités. Toute demande infondée ou abusive au regard notamment des impératifs de sécurité, des lois et règlements pourra être rejetée.</p>
    <p>Afin d'exercer ces droits, vous pouvez contacter le délégué à la protection des données à l'adresse suivante : <a href="mailto:dpo@brgm.fr">dpo@brgm.fr</a>.</p>
    <p>Par ailleurs, le cas échéant, vous pouvez retirer votre consentement à tout moment. Le retrait du consentement ne compromet pas le caractère licite du traitement fondé sur le consentement effectué avant ce retrait.</p>
    <h3>8- Dans quelle mesure le BRGM sécurise vos données à caractère personnel ?</h3>
    <p>Le BRGM s’assure que vos données sont traitées en toute sécurité et confidentialité, y compris lorsque certaines opérations sont réalisées par des sous-traitants. A cet effet, la politique de sécurité des systèmes d’information du BRGM définit les mesures techniques et organisationnelles appropriées pour éviter la perte, la mauvaise utilisation, l’altération et la suppression des données à caractère personnel vous concernant sont mises en place. Ces mesures sont adaptées selon le niveau de sensibilité des données à caractère personnel traitées et selon le niveau de risque que présente le traitement ou sa mise en œuvre.</p>
    <h3>9- Le BRGM peut-il faire évoluer ce document d'information ?</h3>
    <p>Le BRGM pourra faire évoluer ce document d'information à tout moment, en fonction de l'évolution des techniques et outils de traitement notamment. Il est donc recommandé de le consulter régulièrement.</p>
    <h3>10- Commission nationale de l'informatique et des libertés ("CNIL")</h3>
    <p>Nous vous rappelons que vous pouvez introduire une réclamation auprès du délégué à la protection des données (<a href="mailto:dpo@brgm.fr">dpo@brgm.fr</a>) et, en cas de litige non résolu, auprès de la CNIL, qui est l'autorité administrative indépendante chargée de veiller au respect de la Réglementation en France. Vous pouvez la saisir directement sur le site internet <a href="https://www.cnil.fr/fr/agir">https://www.cnil.fr/fr/agir</a> ou par courrier à l'adresse suivante : Commission nationale de l'informatique et des libertés, 3 Place de Fontenoy - TSA 80715, 75334 Paris Cedex 07.</p>
    <h3>11- Information spécifique aux cookies</h3>
    <p>Lors de la consultation de sites web du BRGM, des témoins de connexion, dits "cookies", sont déposés sur votre ordinateur, votre mobile ou votre tablette. Ils ont comme finalité d’établir des mesures d'audience. Les données collectées (zone géographique, pages visitées, temps passé, etc.) sont enregistrées et conservées sur les serveurs du BRGM, pour une durée maximum de treize (13) mois. Ces informations sont strictement anonymes, ne permettent pas d'identifier les utilisateurs ni de suivre leur navigation sur d'autres sites. Les informations recueillies ne seront utilisées que pour les seuls besoins de l'analyse de la fréquentation des sites du BRGM.</p>
    <p>Vous pouvez néanmoins refuser le dépôt de cookies en cliquant ci-dessous :</p>
    <iframe title="Refuser les cookies" frameborder="no" height="200px" width="650px" src="https://wwwstats.brgm.fr/index.php?module=CoreAdminHome&amp;action=optOut&amp;language=fr"></iframe>
    <p>Pour obtenir plus d'informations sur les cookies, vous pouvez consulter le site internet de la CNIL : <a href="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs">https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs</a>.</p>
  </div>
</div>
