import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { FieldOption } from './data';

@Component({
  selector: 'dup-hierarchical-select',
  templateUrl: './hierarchical-select.component.html',
  styles: []
})
export class HierarchicalSelectComponent implements OnInit, OnChanges {

// TODO: gestion de l'édition et de la valeur initiale

  @Input() options: FieldOption[];
  @Input() selectedOption:FieldOption[];
  @Input() requiredUsage:Boolean;
  @Output() onHierarchicalSelectChange = new EventEmitter<any>();

  currentOption: FieldOption;
  selected:FieldOption;

  //typeReglementation = "MIN";
  isFORDOM = false;

  constructor() { 
    this.selected = new FieldOption(null, null, false, null);
    this.currentOption = null;
  }

  ngOnInit() {
    if (sessionStorage.getItem("typeReglementation") !== null &&  sessionStorage.getItem("typeReglementation") == "FORDOM"){
      //this.typeReglementation = sessionStorage.getItem("typeReglementation");
      this.isFORDOM = true;
      //console.log(this.options);
    }
  }

  ngOnChanges() {
    if (this.isFORDOM) {
      this.currentOption = _.find(this.options, ['text', "Usage domestique"]);
    } else {
      if(this.selectedOption && this.selectedOption.length == 1) {
        this.selected = this.selectedOption[0];
        this.currentOption = _.find(this.options, ['value', this.selected.value]);
      }
    }
  }

  onChange(event) {
    console.log(this.options);
    if(this.currentOption) this.onHierarchicalSelectChange.emit(this.currentOption.value);
  }

  onSonSelectChange(event) {
    //console.log(this.options);
    this.onHierarchicalSelectChange.emit(event);
  }

}
