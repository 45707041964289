import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { DateUtils } from 'src/app/shared/model/date-utils';

import { Perimetre } from './perimetre.model';

@Injectable()
export class PerimetreService {

    private resourceUrl =  SERVER_API_URL + 'api/perimetre-regions';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    query(req?: any): Observable<Perimetre[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    private convertResponse(res): Perimetre[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to Perimetre.
     */
    private convertItemFromServer(json: any): Perimetre {
        const entity: Perimetre = Object.assign(new Perimetre(), json);
        return entity;
    }

    /**
     * Convert a Perimetre to a JSON which can be sent to the server.
     */
    private convert(perimetre: Perimetre): Perimetre {
        const copy: Perimetre = Object.assign({}, perimetre);
        return copy;
    }
}
