<h2 class="fs-title">Localisation d'ouvrage(s)</h2>
<div class="row mes-declarations-container">
    <span *ngIf="!validatePerimetre()" style="color: red; font-weight: bold; width: 100%">Positionnement impossible : la localisation souhaitée est en dehors des régions couvertes par DUPLOS.</span>
    <span *ngIf="!prelevementIsOK" style="color: red; font-weight: bold; width: 100%">Prélèvement cumulé des ouvrages supérieur à 1000m3</span>
    <!--<span *ngIf="isEau && isDomestique && !isSumOK" style="color: red;">Votre déclaration concerne un ouvrage domestique, la somme des prélèvements de tous vos ouvrages doit être inférieure à 1000 m<sup>3</sup>/an</span>
    <span *ngIf="isEau && !isDomestique && !isSumOK" style="color: red;">Votre déclaration ne concerne pas un ouvrage domestique, la somme des prélèvements de tous vos ouvrages doit être supérieure ou égale à 1000 m<sup>3</sup>/an</span>-->
    <div *ngIf="declaration.plusieursOuvrages" class="col-md-12">
        <span>Copie automatique des informations du premier ouvrage </span>
        <input *ngIf="declaration.plusieursOuvrages" type="checkbox" class="col-md-1" name="same" id="same" [(ngModel)]="isSame" (change)="same($event)" />
    </div>
    <div class="col-md-5 table-responsive">
        <span>Ajoutez un ouvrage en cliquant sur la carte ou manuellement </span>
        <button [disabled]="(!declaration.plusieursOuvrages && declarationOuvrages.ouvrages.length==1) || declarationOuvrages.ouvrages.length>= 20" type="button" title="Ajouter un ouvrage" (click)="addOuvrage($event)" class="btn btn-primary btn-sm">
            <span class="fas fa-plus"></span>
        </button>
        <span class="row" *ngIf="duplicateNomUsuel.size > 0" style="color:red;font-weight:bold; margin-left:0px">Renommer les noms identiques</span>
        <p-scrollPanel [style]="{height: '550px'}">
            <p-panel *ngFor="let ouvrage of declarationOuvrages.ouvrages">
                <p-header>
                    <button type="button" title="Modifier le nom" class="btn btn-secondary btn-sm" style="font-size: 8px;" (click)="changeName(ouvrage)">
                        <span class="fas fa-pen"></span>
                    </button>
                    <span (click)="changeName(ouvrage)" #ouvWarn style="display:inline-block;">
                        <span *ngIf="ouvrage.nomUsuel == ''" style="color:red;"> Nom (obligatoire)</span>
                        <span *ngIf="ouvrage.nomUsuel != ''" class="ui-panel-title" [ngStyle]="duplicateNomUsuel.has(ouvrage.nomUsuel) && {'color': 'red', 'font-weight': 'bold'}" style="font-size:16px;display:inline-block;margin-top:2px;font-weight:300;"> {{ouvrage.nomUsuel}}</span>
                    </span>
                    <input maxlength="25" #ouvName [(ngModel)]="ouvrage.nomUsuel" (change)="computeDuplicate()" (keyup.enter)="closeInput(ouvrage)" (blur)="closeInput(ouvrage)" style="display:none;" />
                    <button type="button" (click)="cancelOuvrage(ouvrage)" class="btn btn-danger btn-sm" style="float: right;margin-left: 2px;">
                        <span class="fas fa-times"></span>
                    </button>
                </p-header>
                <div style="font-size: 14px;cursor:pointer;">
                    <div (click)="openLocalisationPopup(ouvrage)">
                        <button type="button" title="Modifier la localisation" class="btn btn-secondary btn-sm" style="font-size: 8px;">
                            <span class="fas fa-pen"></span>
                        </button> <span *ngIf="!validateLocalisation(ouvrage)" style="color:red"> Localisation (obligatoire)</span>
                        <span *ngIf="validateLocalisation(ouvrage)"> X: {{ouvrage.x}}, Y: {{ouvrage.y}}</span>
                    </div>
                    <div (click)="openOuvragePopup(ouvrage)">
                        <button type="button" title="Modifier les caractéristiques" class="btn btn-secondary btn-sm" style="font-size: 8px;">
                            <span class="fas fa-pen"></span>
                        </button> <span *ngIf="!validateCarac(ouvrage)" style="color:red"> Caractéristiques(obligatoire)</span>
                        <span *ngIf="validateCarac(ouvrage)"> Type: {{ouvrage.natureOuvrageId?ouvrage.natureOuvrageLibelle+' ':''}}{{ouvrage.directionId?ouvrage.directionLibelle +' ':''}}{{ouvrage.longeur?'sur '+ouvrage.longeur+' m':''}}</span>
                    </div>
                    <div *ngIf="!isDeclarantMOE" (click)="openActeurPopup(ouvrage, 'acteurMoe', 'Maitre d\'oeuvre')">
                            <button type="button" title="Modifier le maître d'oeuvre" class="btn btn-secondary btn-sm" style="font-size: 8px;">
                                <span class="fas fa-pen"></span>
                            </button> 
                            <span *ngIf="!validateActeur(ouvrage.acteurMoe) && !acteursFacultatifs" style="color:red"> Maitre d'oeuvre (obligatoire)</span>
                            <span *ngIf="!validateActeur(ouvrage.acteurMoe) && acteursFacultatifs" > Maitre d'oeuvre (facultatif)</span>
                            <span *ngIf="validateActeur(ouvrage.acteurMoe)"> Maître d'oeuvre : {{ouvrage.acteurMoe?(ouvrage.acteurMoe.raisonSocialeEtab?ouvrage.acteurMoe.raisonSocialeEtab:(ouvrage.acteurMoe.contact?ouvrage.acteurMoe.contact.prenom:'')):''}} {{ouvrage.acteurMoe?(ouvrage.acteurMoe.raisonSocialeEtab?'':ouvrage.acteurMoe.contact?ouvrage.acteurMoe.contact.nom:''):''}}</span>
                    </div>
                    <div *ngIf="!isDeclarantProprietaire"  (click)="openActeurPopup(ouvrage, 'acteurProprio', 'Propriétaire')">
                        <button type="button" title="Modifier le propriétaire" class="btn btn-secondary btn-sm" style="font-size: 8px;">
                            <span class="fas fa-pen"></span>
                        </button> 
                        <span *ngIf="!validateActeur(ouvrage.acteurProprio) && !acteursFacultatifs" style="color:red"> Propriétaire (obligatoire)</span>
                        <span *ngIf="!validateActeur(ouvrage.acteurProprio) && acteursFacultatifs"> Propriétaire (facultatif)</span>
                        <span *ngIf="validateActeur(ouvrage.acteurProprio)"> Propriétaire : {{ouvrage.acteurProprio?(ouvrage.acteurProprio.raisonSocialeEtab?ouvrage.acteurProprio.raisonSocialeEtab:(ouvrage.acteurProprio.contact?ouvrage.acteurProprio.contact.prenom:'')):''}} {{ouvrage.acteurProprio?(ouvrage.acteurProprio.raisonSocialeEtab?'':ouvrage.acteurProprio.contact?ouvrage.acteurProprio.contact.nom:''):''}}</span>
                    </div>
                    <div *ngIf="!isDeclarantExploitant" (click)="openActeurPopup(ouvrage, 'acteurForeur', 'Foreur')">
                        <button type="button" title="Modifier le foreur" class="btn btn-secondary btn-sm" style="font-size: 8px;">
                            <span class="fas fa-pen"></span>
                        </button> Foreur (facultatif): {{ouvrage.acteurForeur?(ouvrage.acteurForeur.raisonSocialeEtab?ouvrage.acteurForeur.raisonSocialeEtab:(ouvrage.acteurForeur.contact?ouvrage.acteurForeur.contact.prenom:'')):''}} {{ouvrage.acteurForeur?(ouvrage.acteurForeur.raisonSocialeEtab?'':ouvrage.acteurForeur.contact?ouvrage.acteurForeur.contact.nom:''):''}}
                    </div>
                    <div *ngIf="isEau" (click)="openAquiferePopup(ouvrage)">
                        <button type="button" title="Modifier la nappe aquifère" class="btn btn-secondary btn-sm" style="font-size: 8px;">
                            <span class="fas fa-pen"></span>
                        </button> Aquifère, eau superficielle ou source (facultatif): {{ouvrage.codeNappe?ouvrage.nomNappe+' ':''}}{{ouvrage.codeNappe?'('+ouvrage.codeNappe+')':''}}
                    </div>
                </div>
            </p-panel>
        </p-scrollPanel>
    </div>
    <p-card class="col-md-7">
        <div id="leftPanel" class="map_container" [hidden]="displayCaracOuvrage || displayActeurOuvrage || displayAquifereOuvrage || displayLocalisationOuvrage">
            <ol-map [(declaration)]="declaration" [(declarationOuvrages)]="declarationOuvrages" [tick]="tick" (tock)="checkPrelevement()" [isSame]="isSame" (coord_valide_child)= "coord_valide($event)"></ol-map>
        </div>
        <div *ngIf="displayLocalisationOuvrage">
            <p-scrollPanel [style]="{height: '500px'}">
                <dup-localisation [ouvrage]="ouvrageForm" [nomOuvrage]="ouvrageForm.nomUsuel" [isCarte]="isCarte()" [perimetre]="perimetre" (coord_valide_child)= "coord_valide($event)"></dup-localisation>
            </p-scrollPanel>
            <div class="modal-footer">
                <div class="col-md-6">
                    <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="displayLocalisationOuvrage = false"/>
                    <input type="button" name="next" class="btn btn-success btn-md float-right" value="Valider" (click)="saveLocalisation()"  [disabled]="!validateLocalisation(ouvrageForm)" />
                </div>
            </div>
        </div>
        <div *ngIf="displayCaracOuvrage">
            <p-scrollPanel [style]="{height: '500px'}">
                <dup-carac-ouvrage [ouvrage]="ouvrageForm" [nomOuvrage]="ouvrageForm.nomUsuel" [isEau]="isEau"></dup-carac-ouvrage>
            </p-scrollPanel>
            <div class="modal-footer">
                <div class="col-md-6">
                    <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="displayCaracOuvrage = false"/>
                    <input  type="button" name="next" class="btn btn-success btn-md float-right" value="Valider" (click)="saveOuvrage()" [disabled]="!validateCarac(ouvrageForm)" />
                </div>
            </div>
        </div>
        <div *ngIf="displayActeurOuvrage">
            <p-scrollPanel [style]="{height: '500px'}">
                <h2 style="padding: 1rem"> {{ouvrageForm.nomUsuel ? ouvrageForm.nomUsuel : 'Nom Ouvrage'}} </h2>
                <h2 class="modal-title modal-header" style="padding-left: 1rem; padding-bottom: 1rem;">{{acteurName}}</h2>
                
                <div class="modal-body col-md-12" id="dup-acteur-popup">
                    <label class="col-md-5 form-control-label" for="ident" *ngIf="declaration.acteurMoa && declaration.acteurMoa.email">Identique au maitre d'ouvrage</label>
                    <input type="checkbox" class="col-md-1" name="ident" id="ident" *ngIf="declaration.acteurMoa && declaration.acteurMoa.email" [(ngModel)]="isIdent" (change)="ident()" />
                    <dup-acteur-form [acteur]="acteur"></dup-acteur-form>
                </div>
            </p-scrollPanel>
            <div class="modal-footer">
                <div class="col-md-6">
                    <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="displayActeurOuvrage = false"/>
                    <input  type="button" name="next" class="btn btn-success btn-md float-right" value="Valider" (click)="saveActeur()" [disabled]="!validateActeur(acteur)" />
                </div>
            </div>
        </div>
        <div *ngIf="displayAquifereOuvrage">
            <p-scrollPanel [style]="{height: '500px'}">
                <dup-aquifere [ouvrage]="ouvrageForm" [nomOuvrage]="ouvrageForm.nomUsuel" [srs]="getSrs()"></dup-aquifere>
            </p-scrollPanel>
            <div class="modal-footer">
                <div class="col-md-6">
                <input type="button" name="previous" class="previous action-button-previous" value="Annuler" (click)="displayAquifereOuvrage = false"/>
                <input type="button" name="next" class="next action-button" value="Valider" (click)="saveAquifere()" />
                </div>
            </div>
        </div>
    </p-card>
</div>
