import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import * as $ from 'jquery';
import * as _ from 'lodash';

import { Ouvrage } from 'src/app/entities/ouvrage/ouvrage.model';
import { ActivatedRoute } from '@angular/router';
import { LocalisationPopupService } from '../../shared/popup/localisation-popup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdresseFormComponent } from '../adresseform/adresseform.component';
import { ApiService } from 'src/app/shared/service/api.service';
import { CommuneService } from 'src/app/entities/commune/commune.service';
import { Adresse } from 'src/app/entities/adresse/adresse.model';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { LexTypeLocalisation } from 'src/app/entities/lex-type-localisation/lex-type-localisation.model';
import { LexSystemeReferenceSpatial } from 'src/app/entities/lex-systeme-reference-spatial/lex-systeme-reference-spatial.model';
import { LexUnite } from 'src/app/entities/lex-unite/lex-unite.model';
import { LexModeObtentionXY } from 'src/app/entities/lex-mode-obtention-xy/lex-mode-obtention-xy.model';
import { LexModeObtentionZ } from 'src/app/entities/lex-mode-obtention-z/lex-mode-obtention-z.model';
import { LexSystemeReferenceSpatialService } from 'src/app/entities/lex-systeme-reference-spatial/lex-systeme-reference-spatial.service';
import { LexUniteService } from 'src/app/entities/lex-unite/lex-unite.service';
import { LexModeObtentionXYService } from 'src/app/entities/lex-mode-obtention-xy/lex-mode-obtention-xy.service';
import { LexModeObtentionZService } from 'src/app/entities/lex-mode-obtention-z/lex-mode-obtention-z.service';
import { LexTypeLocalisationService } from 'src/app/entities/lex-type-localisation/lex-type-localisation.service';

import { Point } from 'ol/geom';
import GeoJSON from 'ol/format/GeoJSON';
import { Perimetre } from 'src/app/entities/perimetre/perimetre.model';

@Component({
  selector: 'dup-localisation',
  templateUrl: './localisationform.component.html',
  styleUrls: [
    '../forms.css'
  ]
})
export class LocalisationFormComponent implements OnInit {
  @Input() ouvrage: Ouvrage;
  @Input() isCarte:Boolean;
  @Input() perimetre: Perimetre[];

  lextypelocalisation: LexTypeLocalisation[];
  lexsrs: LexSystemeReferenceSpatial[];
  lexunites: LexUnite[];
  lexmethodesxy: LexModeObtentionXY[];
  lexmethodesz: LexModeObtentionZ[];

  modifyz: Boolean = false;
  commentaire: string;
  boutonAltitude: Boolean = false;

  // est-ce que la sauvegarde est en cours
  isSaving: Boolean = false;
  coord_ok: boolean = true;
  @Output() coord_valide_child: EventEmitter<boolean> = new EventEmitter();
  
  // acces aux adresses saisis
  @ViewChildren(AdresseFormComponent) adresseForms: QueryList<AdresseFormComponent>;

  constructor(
      private alertService: AlertService,
      //private ngbModal: NgbActiveModal,
      private lexSystemeReferenceSpatialService:LexSystemeReferenceSpatialService,
      private lexUniteService: LexUniteService,
      private lexModeObtentionXYService: LexModeObtentionXYService,
      private lexModeObtentionZService: LexModeObtentionZService,
      private lexTypeLocalisationService: LexTypeLocalisationService,
      private communeService: CommuneService,
      private ouvrageDeclarationService: ApiService
  ) {
  }

  ngOnInit(): void {
    this.lexTypeLocalisationService.query()
      .subscribe((res) => { this.lextypelocalisation = res; this.ouvrage.typeLocalisationId = _.find(this.lextypelocalisation, ["code", 0]).id;}, (res) => this.onError(res));
    this.lexSystemeReferenceSpatialService.query()
      .subscribe((res) => {
        this.lexsrs = res;
        this.lexsrs.forEach(lexsrsOption => {
          switch (lexsrsOption.libelle) {
            case "RRAF 1991 / UTM zone 20N":
              lexsrsOption.libelle = lexsrsOption.libelle + " (Guadeloupe-Martinique)";
              break;

            case "RGFG95 / UTM zone 22N":
              lexsrsOption.libelle = lexsrsOption.libelle + " (Guyane)";
              break;

            case "RGM04 / UTM zone 38S":
              lexsrsOption.libelle = lexsrsOption.libelle + " (Mayotte)";
              break;

            case "RGR92 / UTM zone 40S":
              lexsrsOption.libelle = lexsrsOption.libelle + " (Réunion)";
              break;
          
            default:
              break;
          }
        });

      }, (res) => this.onError(res));
    this.lexUniteService.query()
      .subscribe((res) => { this.lexunites = res; }, (res) => this.onError(res));
    this.lexModeObtentionXYService.query()
      .subscribe((res) => { this.lexmethodesxy = res; }, (res) => this.onError(res));
    this.lexModeObtentionZService.query()
      .subscribe((res) => { this.lexmethodesz = res; }, (res) => this.onError(res));

    if (this.ouvrage.zsaisie){
      this.boutonAltitude = true;
    }

  }

    // gestion des erreurs de communication
  private onError(error: any) {
    this.alertService.error(error.message, null, null);
  }

  // page suivante
  next(event) {
    this.changeFieldSet($(event.target).parent(), $(event.target).parent().next());
  }

  // page precedente
  previous(event) {
    this.changeFieldSet($(event.target).parent(), $(event.target).parent().prev());
  }

  /**
   * Change the fieldset to display
   * @param current_fs current to hide
   * @param next_fs next to show
   */
  changeFieldSet(current_fs, next_fs) {
    // activate next step on progressbar using the index of next_fs
    $('#progressbarouvrage li').removeClass('active');
    $('#progressbarouvrage li').eq($('fieldset.step').index(next_fs)).addClass('active');

    // show the next fieldset
    next_fs.show();

    // hide the current fieldset
    current_fs.hide();
  }

  validateOuvrage() {
    let baseOuvrage = this.ouvrage.modeXYId != null && this.ouvrage.srsId != null && this.ouvrage.x != null
     && this.ouvrage.y != null && this.ouvrage.typeLocalisationId != null && this.ouvrage.adresse != null
     && this.ouvrage.adresse.communeInseeCom != null;
    return !baseOuvrage;
  }

  /*validate() {
    this.ouvrage.adresse = this.adresseForms.first.adresse;
    this.ngbModal.close(this.ouvrage);
  }

  cancel() {
    this.ngbModal.dismiss();
  }*/

  changeSrs(event) {
    let srs = _.find(this.lexsrs, ['id', this.ouvrage.srsId]);
    this.ouvrage.srsLibelle = srs.libelle;

    // Contrôle des décimales
    this.updateGeom();
  }

  getUnite() {
    let srs = _.find(this.lexsrs, ['id', this.ouvrage.srsId]);
    return srs?srs.uniteXYLibelle:'';
  }

  getPrecisionXY(id) {
    let modeXY = _.find(this.lexmethodesxy, ['id', id]);
    return modeXY?modeXY.precisionXYLibelle:'';
  }

  getPrecisionZ(id) {
    let modeZ = _.find(this.lexmethodesz, ['id', id]);
    return modeZ?modeZ.precisionZLibelle:'';
  }

  switchZSaisie() {
    this.modifyz = !this.modifyz;
  }

  localiser() {
    if(this.ouvrage.x && this.ouvrage.y && this.ouvrage.srsId) {
      this.transformToGeom(this.ouvrage,_.find(this.lexsrs, ["id", this.ouvrage.srsId]).epsg);
    }
    this.ouvrageDeclarationService.getAdresseByCoordinate(this.ouvrage.geom.coordinates[0], this.ouvrage.geom.coordinates[1]).subscribe(
      (data: any) => {
          let adresse:Adresse = new Adresse();
          if (data && data.features && data.features[0] && data.features[0].properties) {
            adresse.codePostal = data.features[0].properties.postcode;
            adresse.communeInseeCom = data.features[0].properties.citycode;
            adresse.nomVoie = data.features[0].properties.name;
            adresse.numeroVoie = data.features[0].properties.housenumber;
            if(adresse.communeInseeCom) {
              this.communeService.find(this.ouvrage.adresse.communeInseeCom).subscribe((commune) =>
              {
                adresse.commune = commune;
                this.ouvrage.adresse = adresse;
                //console.log("adresse : " + this.ouvrage.adresse);
              });
            }
          } else {
            this.communeService.findByCoordinates(this.ouvrage.geom.coordinates[0], this.ouvrage.geom.coordinates[1]).subscribe((commune) => {
              adresse.commune = commune;
              adresse.communeInseeCom = commune.inseeCom;
              adresse.codePostal = commune.codePostal;
              this.ouvrage.adresse = adresse;
              //console.log("adresse findbycoord : " + this.ouvrage.adresse);
            });
          }
      });
    this.ouvrageDeclarationService.getCadastreByCoordinates(this.ouvrage.geom.coordinates[0], this.ouvrage.geom.coordinates[1]).subscribe((data:any) => {
      this.ouvrage.numSection = data.features[0].properties.section;
      this.ouvrage.numFeuille = data.features[0].properties.feuille;
      this.ouvrage.numParcelle = data.features[0].properties.numero;
    });
    this.ouvrageDeclarationService.getZByCoordinates(this.ouvrage.geom.coordinates[0], this.ouvrage.geom.coordinates[1]).subscribe((elevations:any) => {
      if(elevations.elevations[0]!=null) {
        this.ouvrage.zcalc = elevations.elevations[0];
        let methodeZ:LexModeObtentionZ = _.find(this.lexmethodesz, ['code', '1']);
        this.ouvrage.modeZCalcId = methodeZ.id;
        this.ouvrage.modeZCalcLibelle = methodeZ.libelle;
      }
    });

    /*if(this.ouvrage.adresse && this.ouvrage.adresse.commune) {
      let perimetre = _.find(this.perimetre, ['regionInseeReg', this.ouvrage.adresse.commune.inseeReg]);
      console.log(perimetre);
      //isValid = isValid && perimetre != null;
    }*/

  }

  updateGeom() {
    if(this.ouvrage.x && this.ouvrage.y && this.ouvrage.srsId) {
      if (this.ouvrage.srsId != 10900){
        this.ouvrage.x = Number.parseInt(this.ouvrage.x.toFixed(0));
        this.ouvrage.y = Number.parseInt(this.ouvrage.y.toFixed(0));
      }
      //console.log(this.ouvrage.srsId + " / " + this.ouvrage.x + " / " + this.ouvrage.y);
      this.transformToGeom(this.ouvrage,_.find(this.lexsrs, ["id", this.ouvrage.srsId]).epsg);

      if(this.ouvrage.x && this.ouvrage.y && this.ouvrage.srsId) {
        this.transformToGeom(this.ouvrage,_.find(this.lexsrs, ["id", this.ouvrage.srsId]).epsg);

        this.ouvrageDeclarationService.getAdresseByCoordinate(this.ouvrage.geom.coordinates[0], this.ouvrage.geom.coordinates[1]).subscribe(
          (data: any) => {
              let adresse:Adresse = new Adresse();
              if (data && data.features && data.features[0] && data.features[0].properties) {
                adresse.codePostal = data.features[0].properties.postcode;
                adresse.communeInseeCom = data.features[0].properties.citycode;
                adresse.nomVoie = data.features[0].properties.name;
                adresse.numeroVoie = data.features[0].properties.housenumber;
                if(adresse.communeInseeCom) {
                  this.communeService.find(this.ouvrage.adresse.communeInseeCom).subscribe((commune) =>
                  {
                    adresse.commune = commune;
                    this.ouvrage.adresse = adresse;
                    if (this.ouvrage.adresse.commune.codePostal != null) {
                      let perimetre = _.find(this.perimetre, ['regionInseeReg', this.ouvrage.adresse.commune.inseeReg]);
                      if (perimetre != null){
                        this.coord_ok = true;
                      }
                    } else {
                      this.coord_ok = false;
                    }
                    this.coord_valide_child.emit(this.coord_ok);
                  });
                }
                //this.getMairieMail(this.ouvrage);

              } else {
                this.communeService.findByCoordinates(this.ouvrage.geom.coordinates[0], this.ouvrage.geom.coordinates[1]).subscribe((commune) => {
                  adresse.commune = commune;
                  adresse.communeInseeCom = commune.inseeCom;
                  adresse.codePostal = commune.codePostal;
                  this.ouvrage.adresse = adresse;
                  if (this.ouvrage.adresse.commune.codePostal != null) {
                    this.coord_ok = true;
                  } else {
                    this.coord_ok = false;
                  }
                  this.coord_valide_child.emit(this.coord_ok);
                });
              }
              
          });
      }
      
    }
  }


  transformToGeom(ouvrage: Ouvrage, epsg: string) {
    var geom = new Point([ouvrage.x, ouvrage.y]);
    geom.transform('EPSG:'+epsg, 'EPSG:4326');
    ouvrage.geom = (new GeoJSON()).writeGeometryObject(geom.clone());
  }
}

@Component({
  selector: 'dup-localisation-popup',
  template: ''
})
export class LocalisationPopupComponent implements OnInit, OnDestroy {

  routeSub: any;

  constructor(
    private route: ActivatedRoute,
    private localisationPopupService: LocalisationPopupService
  ) {}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
        if ( params['id'] ) {
            this.localisationPopupService
                .open(LocalisationFormComponent as Component, params['id']);
        } else {
            this.localisationPopupService
                .open(LocalisationFormComponent as Component);
        }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}
