import { Routes } from '@angular/router';


import { UtilisateurComponent } from './utilisateur.component';
import { UtilisateurPopupComponent } from './utilisateur-dialog.component';
import { UserRouteAccessService } from 'src/app/shared/auth/user-route-access-service';

export const utilisateurRoute: Routes = [
    {
        path: 'utilisateur',
        component: UtilisateurComponent,
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'Utilisateurs'
        },
        canActivate: [UserRouteAccessService]
    }
];

export const utilisateurPopupRoute: Routes = [
    {
        path: 'utilisateur/:id',
        component: UtilisateurPopupComponent,
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'Utilisateurs'
        },
        canActivate: [UserRouteAccessService],
        outlet: 'popup'
    }
];
