<div class="col-md-10 offset-md-1" id="dup-identification" style="text-align: center;">
    <h2 class="fs-title">Identité du déclarant</h2>
    <div class="form-group">
        <label class="form-control-label" for="roleDeclarant">Veuillez sélectionner votre rôle dans cette déclaration </label>
        <div class="row">
            <label class="col-md-6 offset-md-3" style="text-align: left;">Je suis un acteur du projet (plusieurs choix possibles) :</label>
            <div class="col-md-9 offset-md-3">
                <div class="form-row">
                    <input type="checkbox" class="col-md-2" name="roleDeclarant" id="roleDeclarantProprietaire" value="{{UTILISATEUR_ROLE_PROPRIETAIRE}}" [(ngModel)]="isDeclarantProprietaire" (change)="uncheckTiers()" />
                    <label class="col-md-10" style="text-align: left;" for="roleDeclarantProprietaire">Propriétaire foncier <span class="descriptionLabel">(Propriétaire du terrain où sera réalisé l'ouvrage)</span></label>
                    
                </div>
                <div class="form-row">
                    <input type="checkbox" class="col-md-2" name="roleDeclarant" id="roleDeclarantMOA" value="{{UTILISATEUR_ROLE_MOA}}" [(ngModel)]="isDeclarantMOA" (change)="uncheckTiers()" />
                    <label class="col-md-10" style="text-align: left;" for="roleDeclarantMOA">Maitre d'ouvrage <span class="descriptionLabel">(Commanditaire du forage, propriétaire de l'ouvrage)</span></label>
                    
                </div>
                <div class="form-row">
                    <input type="checkbox" class="col-md-2" name="roleDeclarant" id="roleDeclarantMOE" value="{{UTILISATEUR_ROLE_MOE}}" [(ngModel)]="isDeclarantMOE" (change)="uncheckTiers()" />
                    <label class="col-md-10" style="text-align: left;" for="roleDeclarantMOE">Maitre d'oeuvre <span class="descriptionLabel">(Entreprise en charge de la réalisation ou supervision des travaux)</span></label>
                </div>
                <div class="form-row">
                    <input type="checkbox" class="col-md-2" name="roleDeclarant" id="roleDeclarantExploitant" value="{{UTILISATEUR_ROLE_EXPLOITANT}}" [(ngModel)]="isDeclarantExploitant" (change)="uncheckTiers()" />
                    <label class="col-md-10" style="text-align: left;" for="roleDeclarantExploitant">Entreprise de forage <span class="descriptionLabel">(Entreprise réalisant les travaux)</span></label>
                </div>
                <label class="form-row col-md-12" style="text-align: left;">Je suis un tiers déclarant :</label>
                <div class="form-row">
                    <input type="checkbox" class="col-md-2" name="roleDeclarant" id="roleDeclarantTiers" value="{{UTILISATEUR_ROLE_TIERS}}" [(ngModel)]="isDeclarantTiers" (change)="uncheckAllActors()" />
                    <label class="col-md-10" style="text-align: left;" for="roleDeclarantTiers">Autre Personne physique ou morale <span class="descriptionLabel">(déclarant pour le compte d'autrui)</span></label>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 offset-md-2">
        <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="cancel()"/>
        <input type="button" name="next" class="btn btn-success btn-md float-right" value="Suivant" (click)="validate()" [disabled]="declarantValid()" />
    </div>
</div>