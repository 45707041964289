import { OnInit, OnDestroy, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UTILISATEUR_ROLE_PROPRIETAIRE, UTILISATEUR_ROLE_EXPLOITANT, UTILISATEUR_ROLE_MOA, UTILISATEUR_ROLE_MOE, UTILISATEUR_ROLE_TIERS } from 'src/app/shared/constants/utilisateur.constants';

@Component({
    selector: 'dup-identification',
    templateUrl: './declarant.component.html',
    styleUrls: [
        '../forms.css'
    ]
})
export class DeclarantComponent implements OnInit, OnDestroy {
        // exposition des constantes pour le template
    readonly UTILISATEUR_ROLE_PROPRIETAIRE = UTILISATEUR_ROLE_PROPRIETAIRE;
    readonly UTILISATEUR_ROLE_EXPLOITANT = UTILISATEUR_ROLE_EXPLOITANT;
    readonly UTILISATEUR_ROLE_MOA = UTILISATEUR_ROLE_MOA;
    readonly UTILISATEUR_ROLE_MOE = UTILISATEUR_ROLE_MOE;
    readonly UTILISATEUR_ROLE_TIERS = UTILISATEUR_ROLE_TIERS;

    // roles du declarant
    isDeclarantProprietaire: Boolean = false;
    isDeclarantExploitant: Boolean = false;
    isDeclarantMOE: Boolean = false;
    isDeclarantMOA: Boolean = false;
    isDeclarantTiers: Boolean = false;

    routeSub: any;

    typeId:String;

    constructor(private router:Router,
        private route: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if (params['type']) {
                this.typeId = params['type'];
            }
        });
    }

    uncheckAllActors(){
        this.isDeclarantProprietaire = false;
        this.isDeclarantExploitant = false;
        this.isDeclarantMOE = false;
        this.isDeclarantMOA = false;
    }

    uncheckTiers(){
       this.isDeclarantTiers = false; 
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }

    declarantValid() {
        return !(this.isDeclarantExploitant || this.isDeclarantMOA || this.isDeclarantMOE || this.isDeclarantProprietaire || this.isDeclarantTiers);
    }

    validate() {
        this.router.navigate(['declarationForm',{type: this.typeId, moa: this.isDeclarantMOA, moe: this.isDeclarantMOE, proprio: this.isDeclarantProprietaire, foreur: this.isDeclarantExploitant, tiers: this.isDeclarantTiers}]);
        //this.router.navigate({ moa: this.isDeclarantMOA, moe: this.isDeclarantMOE, proprio: this.isDeclarantProprietaire, foreur: this.isDeclarantExploitant });
    }

    cancel() {
        this.router.navigate(['']);
    }
}