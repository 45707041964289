import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from "file-saver";
import { LexTypeDoc } from 'src/app/entities/lex-type-doc/lex-type-doc.model';
import { LexTypeDocService } from 'src/app/entities/lex-type-doc/lex-type-doc.service';
import { PieceJointeService } from 'src/app/entities/piece-jointe/piece-jointe.service';
import { PieceJointe } from 'src/app/entities/piece-jointe/piece-jointe.model';

@Component({
    selector: 'dup-pj-popup',
    templateUrl: './piecejointe-popup.component.html',
    styleUrls: ['./popup.css']
  })
  export class PieceJointePopupComponent implements OnInit {
  
    // declaration en cours
    @Input() ouvrageId: number;

    @Input() ouvrageNom: string;

    @Input() declarationId: number;

    @Input() editionMode:boolean = false;

    lexTypeDocs:LexTypeDoc[] = [];

    typeDocId:number;

    piecesJointes:PieceJointe[] = [];
  
    constructor(
      private ngbModal: NgbActiveModal,
      private lexTypeDocService:LexTypeDocService,
      private pieceJointeService:PieceJointeService
    ) { }
  
    ngOnInit() {
        this.lexTypeDocService.query().subscribe(res => {
          res.forEach(type => {
            if(type.valide) {
              this.lexTypeDocs.push(type);
            }
          });
        });
        this.pieceJointeService.findByOuvrage(this.ouvrageId).subscribe(res => this.piecesJointes = res);
    }

    myUploader(event, fileUpload) {
      if(this.typeDocId != null) {
        let data:FormData = new FormData();
        data.append("fichier",event.files[0] as File, (event.files[0] as File).name);
        data.append("ouvrageId",this.ouvrageId.toString());
        data.append("declarationId",this.declarationId.toString());
        data.append("typeDocId",this.typeDocId.toString());
        data.append("nomFichier", (event.files[0] as File).name);
        this.pieceJointeService.create(data).subscribe(res => {
          this.piecesJointes = [...this.piecesJointes, res];
        });
        fileUpload.clear()
      }
    }

    uploaded(event) {
        this.ngbModal.dismiss();
    }

    downloadFile(pj:PieceJointe) {
      this.pieceJointeService.downloadFile(pj.id).subscribe(res => {
        var newBlob = new Blob([res], { type: "application/pdf" });
        saveAs(newBlob, pj.nomFichier);
      });
    }

    deleteFile(pj:PieceJointe) {
      this.pieceJointeService.delete(pj.id).subscribe(res => {
        let index = this.piecesJointes.indexOf(pj);
        this.piecesJointes.splice(index,1);
      });
    }
  
    cancel() {
      this.ngbModal.dismiss();
    }
  
  }