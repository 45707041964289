<div class="row form-row" *ngIf="!isOuvrage">
    <label class="col-md-5 form-control-label" >Pays <span class="asterisk-red">*</span></label>
</div><div class="row form-group form-row">
    <span class="col-md-3">
        <input type="radio" class="col-md-2" name="pays" id="paysFrance" [(ngModel)]="isFrance"
            [value]="true" (click)="changeToFrance()" style="margin-top: 6.4px;" [disabled]="isOuvrage"/>
        <label for="paysFrance" class="col-md-10">France</label>
    </span>
    <span class="col-md-3">
        <input type="radio" class="col-md-2" name="pays" id="paysAutre" [(ngModel)]="isFrance"
            [value]="false" style="margin-top: 6.4px;" [disabled]="isOuvrage"/>
        <label for="paysAutre" class="col-md-10">Autre (préciser)</label>
    </span>
</div>
<div class="row form-group" *ngIf="!isFrance">
    <input type="text" class="col-md-8 form-control" name="paysText" id="field_pays"
        [(ngModel)]="adresse.pays" maxlength="50"/>
</div>
<div class="row form-row">
    <label class="col-md-5 form-control-label" for="field_nomVoie">Adresse</label>
</div><div class="row form-group form-row">
    <input type="text" class="col-md-8 form-control" name="nomVoie" id="field_nomVoie"
        [(ngModel)]="adresse.nomVoie" maxlength="100" />
</div>

<div class="row form-row">
    <label *ngIf="isFrance" class="col-md-5 form-control-label" for="field_codePostal">Code postal</label>
    <label *ngIf="!isFrance" class="col-md-5 form-control-label" for="field_codePostal">Zip code</label>
</div><div class="row form-group form-row">
    <input type="text" class="col-md-6 form-control" name="codePostal" id="field_codePostal"
        [(ngModel)]="adresse.codePostal" maxlength="5" />
    <input type="button" *ngIf="isFrance" name="previous" class="col-md-2 btn btn-success btn-md" value="Rechercher" (click)="findCity($event)" [disabled]="!adresse.codePostal || adresse.codePostal.length!=5"/>
</div>

<div class="row form-row" *ngIf="isFrance">
    <label class="col-md-5 form-control-label" for="field_region">Région <span class="asterisk-red">*</span></label>
</div><div class="row form-group form-row" *ngIf="isFrance">
    <div class="col-md-8 dropdown-no-padding">
        <ng-select id="field_region" name="region" [(ngModel)]="region" [items]="regions" bindLabel="nomReg" (change)="onSelectRegion($event)"></ng-select>
    </div>
</div>

<div class="row form-row" *ngIf="isFrance">
    <label class="col-md-5 form-control-label" for="field_departement" [hidden]="!region">Département <span class="asterisk-red">*</span></label>
</div><div class="row form-group form-row" *ngIf="isFrance">
    <div class="col-md-8 dropdown-no-padding">
        <ng-select  id="field_departement" name="departement" [(ngModel)]="departement" [items]="departements" bindLabel="nomDep" (change)="onSelectDepartement($event)" [hidden]="!region"></ng-select>
    </div>
</div>

<div class="row form-row" *ngIf="isFrance">
    <label class="col-md-5 form-control-label" for="field_commune" [hidden]="!departement">Commune <span class="asterisk-red">*</span></label>
</div><div class="row form-group form-row" *ngIf="isFrance">
    <div class="col-md-8 dropdown-no-padding">
        <ng-select id="field_commune" name="commune" [(ngModel)]="commune" [items]="communes" bindLabel="nomCom" [loading]="communeLoading" (change)="onSelectCommune($event)" [hidden]="!departement"></ng-select>
    </div>
</div>

<div class=" row form-row" *ngIf="!isFrance">
    <label class="col-md-5 form-control-label" for="field_lieuDit">Ville <span class="asterisk-red">*</span></label>
</div><div class="row form-group form-row" *ngIf="!isFrance">
    <input type="text" class="col-md-8 form-control" name="ville" id="field_ville"
        [(ngModel)]="adresse.lieuDit" maxlength="50"/>
</div>

<div class="row form-row" *ngIf="isFrance">
    <label class="col-md-5 form-control-label" for="field_complement">Complément</label>
</div><div class="row form-group form-row" *ngIf="isFrance">
    <input type="text" class="col-md-8 form-control" name="complement" id="field_complement"
        [(ngModel)]="adresse.complement" maxlength="50"/>
</div>

<div class="row form-row" *ngIf="isFrance">
    <label class="col-md-5 form-control-label" for="field_lieuDit">Lieu-dit</label>
</div><div class="row form-group form-row" *ngIf="isFrance">
    <input type="text" class="col-md-8 form-control" name="lieuDit" id="field_lieuDit"
        [(ngModel)]="adresse.lieuDit" maxlength="50"/>
</div>

<div class="row form-row" *ngIf="displayBPCedex()">
    <label class="col-md-5 form-control-label" for="field_bp">Boite postale</label>
</div><div class="row form-group form-row" *ngIf="displayBPCedex()">
    <input type="text" class="col-md-8 form-control" name="bp" id="field_bp"
        [(ngModel)]="adresse.bp" maxlength="50"/>
</div>

<div class="row form-row" *ngIf="displayBPCedex()">
    <label class="col-md-5 form-control-label" for="field_cedex">Cedex</label>
</div><div class="row form-group form-row" *ngIf="displayBPCedex()">
    <input type="text" class="col-md-8 form-control" name="cedex" id="field_cedex"
        [(ngModel)]="adresse.cedex" maxlength="50"/>
</div>


