import * as _ from 'lodash';
import * as $ from 'jquery';

import { Component, OnInit, OnDestroy, AfterViewChecked, Input } from "@angular/core";
import { Map, View, Feature, Overlay } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { intersects } from 'ol/extent';
import { OSM, Vector as VectorSource, TileWMS } from 'ol/source';
import { Point, Polygon } from 'ol/geom';
import { defaults as defaultControls } from 'ol/control';
import { Circle as CircleStyle, Fill, Stroke, Style, RegularShape } from 'ol/style';
import { Snap, Select } from 'ol/interaction';
import { click } from 'ol/events/condition';
import GeoJSON from 'ol/format/GeoJSON';

import { Ouvrage } from '../../entities/ouvrage/ouvrage.model';
import { ApiService } from 'src/app/shared/service/api.service';
import { Commune } from 'src/app/entities/commune/commune.model';
import { CommuneService } from 'src/app/entities/commune/commune.service';
import { Router } from '@angular/router';
import { Declaration } from 'src/app/entities/declaration/declaration.model';

/**
 * Composant d'affichage de la carte OpenLayers
 */
@Component({
  selector: 'ol-map-accueil',
  styleUrls: ['./ol-map.component.scss'],
  templateUrl: './ol-map.component.html'
})

export class OlMapComponent implements OnInit, AfterViewChecked, OnDestroy {

  private _ouvrages: Ouvrage[];
  //@Input() declaration: Declaration;
  @Input()
  set ouvrages(ouvrages: Ouvrage[]) {
    this._ouvrages = ouvrages;
    this.updateOuvragesLayers();
  }

  @Input() declarations:Declaration[];

  @Input() userEmail: string;

  /**
   * Objet Ol Map
   */
  private _map: Map = null;
  /**
   * Overlay pour les popups
   */
  private popupOverlay:Overlay = null;
 
  /** 
   * Source OL contenant 
  */
  pointsSource: VectorSource; 
  parcellesSource: VectorSource; 
  source : VectorSource;
  pointsLayer: VectorLayer;
  parcellesLayer: VectorLayer;
  legendInfoterre:boolean = false;
  legendDuplos:boolean = true;

  layerSwitcherLayers:any[] = [];

  constructor(private ouvrageDeclaration:ApiService, private communeService:CommuneService,
    private router: Router) {}

  ngOnInit() {
    this.source = new VectorSource({wrapX: false});
    this._createMap();
  }


  ngAfterViewChecked (){
    this._map.updateSize();
  }

  ngOnDestroy() {
    this._map = null;
  }

  /**
   * Re-génère la liste des features des ouvrages.
   */
  private updateOuvragesLayers(): void {
    const featureList = new Array<Feature>();
    if (this.pointsSource){
      this.pointsSource.clear(true);
      if (this._ouvrages){ 
        this._ouvrages.forEach(ouvrage => {          
          if (ouvrage.geom){
            let infobulle = "Déclaration : "+ ouvrage.declarationId +"<br/>Ouvrage : "+ouvrage.nomUsuel+"<br/>Statut : "+ouvrage.statutLibelle;
            let feature = new Feature({
              'geometry': new Point([ouvrage.geom.coordinates[0], ouvrage.geom.coordinates[1]]),
              dataProjection: 'EPSG: 4326',
              featureProjection: 'EPSG: 4326',
              name: infobulle,
              class: ouvrage.statutId,
              declaration: ouvrage.declarationId,
              perimetre : ouvrage.auteurCreation != this.userEmail
            });
            featureList.push(feature);
          }
        });
        if (featureList.length > 0){
          this.pointsSource.addFeatures(featureList); 
          const extent = this.pointsLayer.getSource().getExtent();
          this._map.getView().fit(extent, this._map.getSize());
          if(featureList.length == 1)
          {
            this._map.getView().setZoom(17);
          }
        }
      }
    }
  }


   /**
   * Crée la carte OpenLayers
   */
  private _createMap(): void {

    this.pointsSource = new VectorSource();
    this.pointsLayer = new VectorLayer({
        duplos: {
          name: 'OuvrageLayer'
        },
        title: 'Ouvrages',
        source: this.pointsSource,
        style: function(feature) {

          let func = function(color:string, isFromPerimetre: boolean){
            let imageStyle = isFromPerimetre ? 
            new RegularShape({ points: 5, radius: 7, radius2: 3, fill: new Fill({ color: color}) })
            :
            new CircleStyle({ radius: 7, fill: new Fill({ color: color}) });
        
            return new Style({ image: imageStyle });
          }

          let style;
          let statut = feature.get('class');
          let isFromPerimetre = feature.get('perimetre');
          switch(statut) {
            case 1950: {
              style = func("black", isFromPerimetre);
              break;
            }
            case 1900: {
              style = func("green", isFromPerimetre);
              break;
            }
            case 1850: {
              style = func("blue", isFromPerimetre);
              break;
            }
            case 1800: {
              style = func("yellow", isFromPerimetre);
              break;
            }
          }
          return style;
        }
    });
    this.parcellesSource = new VectorSource();
    this.parcellesLayer = new VectorLayer({
        parcelles: {
          name: 'ParcelleLayer'
        },
        title: 'Cadastre',
        visible: false,
        source: this.parcellesSource,
        style: new Style({
          stroke: new Stroke({
            color: '#000',
            width: 1
          })
        }),
      });

      let osmLyr = new TileLayer({
      title: 'Open Street Map',
      source: new OSM(),
      type: 'base'
    });

    let bssLayer = new TileLayer({
      title: 'Ouvrages BSS',
      source: new TileWMS({
        url: 'https://mapsref.brgm.fr/wxs/infoterre/catalogue',
        params: {'LAYERS': ['BSS_TOTAL', 'BSS_SEMIS_2', 'BSS_SEMIS_1', 'BSS_SEMIS_0']}
      }),
      visible: false,
      type: 'base'
    });

    let orthoLayer = new TileLayer({
      title: 'OrthoPhoto',
      source: new TileWMS({
        url: 'https://mapsref.brgm.fr/wxs/refcom-brgm/refign',
        params: {
          'LAYERS': 'ORTHO',
          'TRANSPARENT': 'TRUE',
          'SERVICE': 'WMS',
          'VERSION': '1.1.1',
          'REQUEST': 'GetMap',
          'SRS': 'EPSG:4326',
          'FORMAT': 'image/png'}
      }),
      type: 'base'
    });

    this.layerSwitcherLayers.push({olLayer: this.pointsLayer, title: this.pointsLayer.get('title'), visible: this.pointsLayer.getVisible(), id: 'points'});
    this.layerSwitcherLayers.push({olLayer: this.parcellesLayer, title: this.parcellesLayer.get('title'), visible: this.parcellesLayer.getVisible(), id: 'cadastre'});
    //this.layerSwitcherLayers.push({olLayer: osmLyr, title: osmLyr.get('title'), visible: osmLyr.getVisible(), id: 'osm'});
    this.layerSwitcherLayers.push({olLayer: bssLayer, title: bssLayer.get('title'), visible: bssLayer.getVisible(), id: 'infoterre'});
    this.layerSwitcherLayers.push({olLayer: orthoLayer, title: orthoLayer.get('title'), visible: orthoLayer.getVisible(), id: 'orthophoto'});

    this._map = new Map({
      controls: defaultControls(),
      view: new View({
        projection: 'EPSG:4326',
        center: [2.4609, 46.6061],
        zoom: 7
      }),
      layers: [              
        osmLyr,
        orthoLayer,
        bssLayer,
        this.pointsLayer, 
        this.parcellesLayer
      ],
      target: 'map'
    });

    let select = new Select({
      condition: click
    });
    
    select.on('select', event => this.onSelectPoint(event));
    this._map.addInteraction(select);

    /*var snap = new Snap({source: this.pointsSource});
    this._map.addInteraction(snap);*/

    this.popupOverlay = new Overlay({
      element: $('#popup')[0],
      offset: [9, 9]
    });
    this._map.addOverlay(this.popupOverlay);
    
    this._map.on('pointermove', (event) => {
        let features:Feature[] = [];
        features = this._map.getFeaturesAtPixel(event.pixel,
            { layerFilter: function(layerCandidate){
              return (layerCandidate.values_.duplos) ;
            }
          });
        if (features && features.length > 0) {
            $('#popup')[0].innerHTML = features[0].values_.name;
            $('#popup')[0].hidden = false;
            this.popupOverlay.setPosition(event.coordinate);
        }
        if (!features || features.length === 0) {
          $('#popup')[0].innerHTML = '';
          $('#popup')[0].hidden = true;
        }
    });

    this._map.on('moveend', (event) => this.onZoom(event));

    if(this._ouvrages) {
      this.updateOuvragesLayers();
    }
  }

  private onZoom(e): void {
    let zoom = this._map.getView().getZoom();
    this.parcellesSource.clear(true);
    if(zoom>16) {
      let extentMap = this._map.getView().calculateExtent(this._map.getSize());
      this.communeService.findByBBox(extentMap).subscribe((res)=> {
        let communes:Commune[] = res;
        if(communes !=null) {
          communes.forEach((commune:Commune) => {
            if(commune.inseeCom=="75056" || commune.inseeCom=="13055" || commune.inseeCom=="69123")
            {
              this.ouvrageDeclaration.getArrondissements(commune.inseeDep).subscribe((arrondissements:any) => {
                let arr:string[] = [];
                arrondissements.records.forEach((arrondissement) => {
                  let shape = (new GeoJSON()).readGeometry(arrondissement.fields.geo_shape);
                  if(intersects(extentMap,shape.getExtent())) {
                    arr.push(arrondissement.fields.code_insee);
                  }
                });
                arr.forEach((a) => this.createFeaturesParcelle(a, extentMap));
              });
            } else {
              this.createFeaturesParcelle(commune.inseeCom, extentMap);
            }
          });
        }
      })
    }
  }

  toggleLayer(layer:any) {
    layer.olLayer.setVisible(layer.visible);
    if(layer.id == "infoterre") 
    {
      this.legendInfoterre = !this.legendInfoterre;
    }
    if(layer.id == "points"){
      this.legendDuplos = !this.legendDuplos;
    }
  }

  private createFeaturesParcelle(code_insee, extent) {
    this.ouvrageDeclaration.getCadastreLayer(code_insee).subscribe((featureCollection:any) => {
      const featureList = new Array<Feature>();
      featureCollection.features.forEach((feat) => {
        let feature = new Feature({
          'geometry': new Polygon(feat.geometry.coordinates),
          dataProjection: 'EPSG: 4326',
          featureProjection: 'EPSG: 4326'
        });
        if(intersects(extent,feature.getGeometry().getExtent()))
        {
          featureList.push(feature);
        }
      });
      this.parcellesSource.addFeatures(featureList);
    });
  }

  /**
   * Event lorsqu'un point est selectionné
   */
  private onSelectPoint(e): void {
    let features:Feature[] = [];
    features = e.selected;
    if (features && features.length > 0) {
      let statut = features[0].get('class');
      let declaration =  _.find(this.declarations, ['id',features[0].get('declaration')]);
      let url;
      if(statut == 1800) {
        url = this.router.createUrlTree(['declarationForm/'+declaration.id,{type: declaration.typeDeclarationId}]);
      } else {
        url = this.router.createUrlTree(['declaration/'+declaration.id,{}]);
      }
      window.open('#'+url.toString(), '_blank');
    }
  }

}