import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from '../../app.constants';

@Injectable()
export class AccountService  {
    constructor(private http: HttpClient) { }

    get(): Observable<any> {
        return this.http.get(SERVER_API_URL + 'api/account');
    }

    save(account: any): Observable<any> {
        return this.http.post(SERVER_API_URL + 'api/account', account);
    }

    isAdmin(): Observable<any> {
        return this.http.get(SERVER_API_URL + 'api/account/admin');
    } 

    isBSS(): Observable<any> {
        return this.http.get(SERVER_API_URL + 'api/account/bss');
    }

    isState(): Observable<any> {
        return this.http.get(SERVER_API_URL + 'api/account/state');
    } 

    isInstructor(): Observable<any> {
        return this.http.get(SERVER_API_URL + 'api/account/instructor');
    } 

    isPro(): Observable<any> {
        return this.http.get(SERVER_API_URL + 'api/account/pro');
    } 

    getDialog(): Observable<any> {
        return this.http.get(SERVER_API_URL + 'api/dialog',{responseType: 'text'});
    }

    getPasseport(): Observable<any> {
        return this.http.get(SERVER_API_URL + 'api/passeport',{responseType: 'text'});
    }
}
