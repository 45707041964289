import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LexRegimeIcpeService } from './lex-regime-icpe.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';


@NgModule({
    imports: [
        DuplosSharedModule
    ],
    declarations: [],
    providers: [
        LexRegimeIcpeService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosLexRegimeIcpeModule {}
