<div id="map" class="map">
    <div id="layerswitcher" class="switcher">
        <div *ngFor="let layer of layerSwitcherLayers">
            <input type="checkbox" (change)="toggleLayer(layer)" [(ngModel)]="layer.visible" [id]="layer.id">{{layer.title}}
        </div>
    </div>
</div>
<div id="legends">
    <div id="legendDuplos" [hidden]="!legendDuplos" style="display: inline-block;">
        <ul class="legend">
            <li><span class="inprogress"></span> Ouvrages en cours</li>
            <li><span class="submitted"></span> Ouvrages soumis en BSS</li>
            <li><span class="completed"></span> Ouvrages finalisés</li>
            <li><span class="canceled"></span> Ouvrages abandonnés</li>
        </ul>
    </div>
    <div id="legendBSS" [hidden]="!legendInfoterre" style="display: inline-block;">
        <img src="http://mapsref.brgm.fr/wxs/infoterre/catalogue?=&request=GetLegendGraphic&SERVICE=WMS&VERSION=1.1.1&FORMAT=image/png&LAYER=BSS_TOTAL" alt="Carte infoterre">
    </div>
</div>
<div id="popup" class="popup" [hidden]="true"></div>