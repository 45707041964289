<div class="modal-header">
    <h2 class="modal-title">Confirmation d'abandon</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-delete-popup">
    <div class="saving" [hidden]="!isSaving">
        <p-progressBar [value]="value"></p-progressBar>
        Abandon du projet dans la Banque du Sous-Sol (BSS) en cours...
    </div>
    <div [hidden]="isSaving">
        <p>Êtes vous sûr de vouloir abandonner cette déclaration (abandon du projet, ouvrages non réalisés,...) ? Cette opération est irréversible.</p>
    </div>
</div>
<div class="modal-footer">
    <div class="col-md-6">
        <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="cancel()" [disabled]="isSaving"/>
        <input type="button" name="validate" class="btn btn-danger btn-md float-right" value="Valider" (click)="validate()" />
    </div>
</div>