import { Acteur } from '../acteur/acteur.model';
import { Adresse } from '../adresse/adresse.model';
import { Contact } from '../contact/contact.model';

export class Utilisateur {
    constructor(
        public id?: number,
        public email?: string,
        public siretEtablissement?: string,
        public raisonSocialeEtab?: string,
        public siretApprouve?: boolean,
        public referentEtablissement?: boolean,
        public auteurCreation?:string,
        public auteurModification?:string,
        public dateCreation?: any,
        public dateModification?: any,
        public contactId?: number,
        public adresseId?: number,
        public statutJuridiqueId?: number,
        public statutJuridiqueLibelle?: string,
        public profilUtilisateurId?: number,
        public profilUtilisateurLibelle?: string,
        public adresse?:Adresse,
        public contact?:Contact,
        public zones?:string[],
        public firstLogin?: boolean,
        public emailContact?: string
    ) {
        this.siretApprouve = false;
        this.referentEtablissement = false;
        this.zones = [];
    }

    toActeur(): Acteur {
        const result = new Acteur();
        result.email = this.email;
        result.siretEtab = this.siretEtablissement;
        result.contactId = this.contactId;
        result.adresseId = this.adresseId;
        result.statutJuridiqueId = this.statutJuridiqueId;
        result.auteurCreation = this.auteurCreation;
        return result;
    }
}
