import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { DuplosActeurModule } from './acteur/acteur.module';
import { DuplosAdresseModule } from './adresse/adresse.module';
import { DuplosContactModule } from './contact/contact.module';
import { DuplosDeclarationModule } from './declaration/declaration.module';
import { DuplosDeclarationReglementationModule } from './declaration-reglementation/declaration-reglementation.module';
import { DuplosLexDirectionOuvrageModule } from './lex-direction-ouvrage/lex-direction-ouvrage.module';
import { DuplosLexFonctionModule } from './lex-fonction/lex-fonction.module';
import { DuplosLexModeObtentionXYModule } from './lex-mode-obtention-xy/lex-mode-obtention-xy.module';
import { DuplosLexModeObtentionZModule } from './lex-mode-obtention-z/lex-mode-obtention-z.module';
import { DuplosLexNatureOuvrageModule } from './lex-nature-ouvrage/lex-nature-ouvrage.module';
import { DuplosLexOperationModule } from './lex-operation/lex-operation.module';
import { DuplosLexPrecisionXYModule } from './lex-precision-xy/lex-precision-xy.module';
import { DuplosLexPrecisionZModule } from './lex-precision-z/lex-precision-z.module';
import { DuplosLexProfilReglementationModule } from './lex-profil-reglementation/lex-profil-reglementation.module';
import { DuplosLexProfilUtilisateurModule } from './lex-profil-utilisateur/lex-profil-utilisateur.module';
import { DuplosLexRegimeIcpeModule } from './lex-regime-icpe/lex-regime-icpe.module';
import { DuplosLexReglementationModule } from './lex-reglementation/lex-reglementation.module';
import { DuplosLexRelationModule } from './lex-relation/lex-relation.module';
import { DuplosLexStatutDeclarationModule } from './lex-statut-declaration/lex-statut-declaration.module';
import { DuplosLexStatutJuridiqueModule } from './lex-statut-juridique/lex-statut-juridique.module';
import { DuplosLexSystemeReferenceSpatialModule } from './lex-systeme-reference-spatial/lex-systeme-reference-spatial.module';
import { DuplosLexTypeDeclarationModule } from './lex-type-declaration/lex-type-declaration.module';
import { DuplosLexTypeIcpeModule } from './lex-type-icpe/lex-type-icpe.module';
import { DuplosLexTypeLocalisationModule } from './lex-type-localisation/lex-type-localisation.module';
import { DuplosLexTypeSaisieCoorModule } from './lex-type-saisie-coor/lex-type-saisie-coor.module';
import { DuplosLexUniteModule } from './lex-unite/lex-unite.module';
import { DuplosLexUsageModule } from './lex-usage/lex-usage.module';
import { DuplosOuvrageModule } from './ouvrage/ouvrage.module';
import { DuplosPieceJointeModule } from './piece-jointe/piece-jointe.module';
import { DuplosUtilisateurModule } from './utilisateur/utilisateur.module';
import { DuplosRegionModule } from './region/region.module';
import { DuplosDepartementModule } from './departement/departement.module';
import { DuplosCommuneModule } from './commune/commune.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { DuplosAquifereModule } from './aquifere/aquifere.module';
import { DuplosLexSubstanceModule } from './lex-substance/lex-substance.module';
import { DuplosLexTypeDocModule } from './lex-type-doc/lex-type-doc.module';
import { DuplosPerimetreModule } from './perimetre/perimetre.module';

@NgModule({
    imports: [
        DuplosActeurModule,
        DuplosAdresseModule,
        DuplosContactModule,
        DuplosDeclarationModule,
        DuplosDeclarationReglementationModule,
        DuplosLexDirectionOuvrageModule,
        DuplosLexFonctionModule,
        DuplosLexModeObtentionXYModule,
        DuplosLexModeObtentionZModule,
        DuplosLexNatureOuvrageModule,
        DuplosLexOperationModule,
        DuplosLexPrecisionXYModule,
        DuplosLexPrecisionZModule,
        DuplosLexProfilReglementationModule,
        DuplosLexProfilUtilisateurModule,
        DuplosLexRegimeIcpeModule,
        DuplosLexReglementationModule,
        DuplosLexRelationModule,
        DuplosLexStatutDeclarationModule,
        DuplosLexStatutJuridiqueModule,
        DuplosLexSubstanceModule,
        DuplosLexSystemeReferenceSpatialModule,
        DuplosLexTypeDeclarationModule,
        DuplosLexTypeDocModule,
        DuplosLexTypeIcpeModule,
        DuplosLexTypeLocalisationModule,
        DuplosLexTypeSaisieCoorModule,
        DuplosLexUniteModule,
        DuplosLexUsageModule,
        DuplosOuvrageModule,
        DuplosPieceJointeModule,
        DuplosUtilisateurModule,
        DuplosAquifereModule,
        DuplosRegionModule,
        DuplosDepartementModule,
        DuplosCommuneModule,
        DuplosPerimetreModule,
        NgSelectModule, FormsModule,
    ],
    declarations: [],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosEntityModule {}
