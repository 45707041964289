import { Injectable, Component } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class SoumissionPopupService {
    private ngbModalRef: NgbModalRef;

    constructor(
    private modalService: NgbModal)
    {     
        this.ngbModalRef = null;
    }

    soumissionModalRef(component: Component, declarationId: number, isMultiple:boolean): NgbModalRef {
        const modalRef = this.modalService.open(component, { size: 'lg', backdrop: 'static', centered: true});
        modalRef.componentInstance.declarationId = declarationId;
        modalRef.componentInstance.multiple = isMultiple;
        return modalRef;
    }

    close() {
        this.ngbModalRef.close();
    }
}