import * as _ from 'lodash';
import * as $ from 'jquery';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { Adresse } from '../../entities/adresse/adresse.model';
import { Declaration } from '../../entities/declaration/declaration.model';
import { DeclarationService } from '../../entities/declaration/declaration.service';
import { Contact } from '../../entities/contact/contact.model';
import { Acteur } from '../../entities/acteur/acteur.model';
import { Ouvrage } from '../../entities/ouvrage/ouvrage.model';
import { Utilisateur } from '../../entities/utilisateur/utilisateur.model';
import { DeclarationReglementationService } from '../../entities/declaration-reglementation/declaration-reglementation.service';
import { SoumissionPopupService } from '../../shared/popup/soumission-popup.service';
import { AbandonPopupService } from '../../shared/popup/abandon-popup.service';
import { AdresseService } from 'src/app/entities/adresse/adresse.service';
import { ContactService } from 'src/app/entities/contact/contact.service';
import { ActeurService } from 'src/app/entities/acteur/acteur.service';
import { OuvrageService } from 'src/app/entities/ouvrage/ouvrage.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { Principal } from 'src/app/shared/auth/principal.service';
import { LexStatutDeclaration } from 'src/app/entities/lex-statut-declaration/lex-statut-declaration.model';
import { LexRelation } from 'src/app/entities/lex-relation/lex-relation.model';
import { LexTypeDeclaration } from 'src/app/entities/lex-type-declaration/lex-type-declaration.model';
import { LexOperation } from 'src/app/entities/lex-operation/lex-operation.model';
import { LexReglementation } from 'src/app/entities/lex-reglementation/lex-reglementation.model';
import { LexStatutDeclarationService } from 'src/app/entities/lex-statut-declaration/lex-statut-declaration.service';
import { LexTypeDeclarationService } from 'src/app/entities/lex-type-declaration/lex-type-declaration.service';
import { LexOperationService } from 'src/app/entities/lex-operation/lex-operation.service';
import { DeletePopupComponent } from 'src/app/shared/popup/delete-popup.component';
import { SoumissionPopupComponent } from 'src/app/shared/popup/soumission-popup.component';
import { ListOuvrage } from 'src/app/entities/ouvrage/listouvrage.model';
import { LexTypeIcpe } from 'src/app/entities/lex-type-icpe/lex-type-icpe.model';
import { LexTypeIcpeService } from 'src/app/entities/lex-type-icpe/lex-type-icpe.service';
import { LexUsage } from 'src/app/entities/lex-usage/lex-usage.model';
import { LexUsageService } from 'src/app/entities/lex-usage/lex-usage.service';
import { LexRelationService } from 'src/app/entities/lex-relation/lex-relation.service';
import { PieceJointePopupService } from 'src/app/shared/popup/piecejointe-popup.service';
import { PieceJointePopupComponent } from 'src/app/shared/popup/piecejointe-popup.component';
import { Perimetre } from 'src/app/entities/perimetre/perimetre.model';
import { PerimetreService } from 'src/app/entities/perimetre/perimetre.service';
import { CommuneService } from 'src/app/entities/commune/commune.service';
import { Commune } from 'src/app/entities/commune/commune.model';


@Component({
  selector: 'dup-create-declaration',
  templateUrl: './creationdeclarationform.component.html',
  styleUrls: [
    '../forms.css'
  ]
})
export class CreationDeclarationFormComponent implements OnInit, OnDestroy {

  // declaration en cours
  declaration: Declaration;
  typeDeclaration: LexTypeDeclaration;
  typeReglementation =  "MIN";

  // les differents lexiques
  lexstatutdeclarations: LexStatutDeclaration[];
  lexrelations: LexRelation[];
  lextypedeclarations: LexTypeDeclaration[];
  lexoperations: LexOperation[];
  lextypeicpes: LexTypeIcpe[];
  lexusages: LexUsage[];
  perimetre:Perimetre[];

  // concerne une ICPE ?
  isICPE: boolean = false;
  // concerne de l'eau
  isEau: boolean = false;
  // concerne des substances
  isSubstance: boolean = false;
  // concerne les forages domestiques
  isDomestique: boolean = false;

  isExploit: boolean = false;

  plusieursOuvrages: boolean;

  // roles du declarant
  isDeclarantProprietaire: boolean = false;
  isDeclarantExploitant: boolean = false;
  isDeclarantMOE: boolean = false;
  isDeclarantMOA: boolean = false;
  isDeclarantTiers: boolean = false;

  //declarant
  declarant: Acteur;
  account : Utilisateur;

  //liste des ouvrages de la déclaration
  declarationOuvrages: ListOuvrage;
  savedOuvrages: Ouvrage[];

  lexReglementations: LexReglementation[];

  // est-ce que la sauvegarde est en cours
  isSaving: boolean = false;
  forClose: boolean = false;
  isLoading: boolean = false;
  acteursFacultatifs: boolean = false;

  isValid: boolean = true;
  titreSoumission = "";

  dialogUrl: any;

  routeSub: any;
  navigationSubscription: Subscription;

  soumissionError: boolean = false;
  noMoa: boolean = false;

  constructor(
    private principal: Principal,
    private declarationService: DeclarationService,
    private ouvrageService: OuvrageService,
    private adresseService: AdresseService,
    private contactService: ContactService,
    private acteurService: ActeurService,
    private lexStatutDeclarationService: LexStatutDeclarationService,
    private declarationReglementationService: DeclarationReglementationService,
    private lexTypeDeclarationService: LexTypeDeclarationService,
    private lexUsageService: LexUsageService,
    private lexRelationService: LexRelationService,
    private lexOperationService: LexOperationService,
    private lexTypeIcpeService: LexTypeIcpeService,
    private perimetreService: PerimetreService,
    private alertService: AlertService,
    private soumissionPopupService: SoumissionPopupService,
    private pieceJointePopupService: PieceJointePopupService,
    private abandonPopupService: AbandonPopupService,
    private route: ActivatedRoute,
    private router: Router,
    private communeService: CommuneService
  ) {
    this.declaration = new Declaration();
    this.declaration.acteurMoa = new Acteur();
    this.declarant = new Acteur();
    this.declarationOuvrages = new ListOuvrage();
    this.savedOuvrages = [];
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadDeclaration();
      }
    });
  }

  ngOnInit() {
    if (sessionStorage.getItem('typeReglementation') !== null){
      this.typeReglementation = sessionStorage.getItem('typeReglementation');
      if (this.typeReglementation == 'FORDOM'){
        this.isExploit = true;
      }
    }


    this.initialise();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    this.navigationSubscription.unsubscribe();
  }

  initialise() {
    this.declaration = new Declaration();
    this.declaration.acteurMoa = new Acteur();
    this.declarant = new Acteur();
    this.declarationOuvrages.ouvrages = [];
    /*if (this.typeReglementation == 'FORDOM'){
      //var reglement = new LexReglementation(); 
      this.lexReglementationService.find(4050).subscribe((res) => {
        this.declaration.reglementations.push(res);
      }, (res) => this.onError(res));
      
    }*/

    this.savedOuvrages = [];
    this.principal.getDialogUrl().then((url) => { this.dialogUrl = url; });
    // alimente les differents lexiques
    this.lexOperationService.query()
      .subscribe((res) => { this.lexoperations = res; }, (res) => this.onError(res));
    this.lexStatutDeclarationService.query()
      .subscribe((res) => { this.lexstatutdeclarations = res; });
    this.perimetreService.query()
      .subscribe((res) => { this.perimetre = res;}, (res) => this.onError(res));
    this.lexTypeDeclarationService.query()
      .subscribe((res) => {
        this.lextypedeclarations = res;
        this.routeSub = this.route.params.subscribe((params) => {
          this.typeDeclaration = _.find(this.lextypedeclarations, ['id', Number(params['type'])]);
        });
       }, (res) => this.onError(res));
    this.lexRelationService.query()
    .subscribe((res) => { this.lexrelations = res; }, (res) => this.onError(res));
    this.lexTypeIcpeService.query()
    .subscribe((res) => { this.lextypeicpes = res; }, (res)=> this.onError(res));
    this.lexUsageService.query()
    .subscribe((res) => {
      this.lexusages = res;
    }, (res) => this.onError(res));
    this.principal.identity().then((account) => {

    this.account = account;
    this.declarant = this.toActeur(account);
    this.declarant.contactId = account.contactId;
    this.declarant.adresseId = account.adresseId;
    this.adresseDeclarant(this.initDeclarantActeur.bind(this));
      
    });
  }

  initDeclarantActeur(){
    this.routeSub = this.route.params.subscribe((params) => {
      if (params['moa'] == "true") {
        this.isDeclarantMOA = true;
        this.declaration.acteurMoa = this.toActeur(this.account);
      }
      if (params['moe'] == "true") {
        this.isDeclarantMOE = true;
      }
      if (params['proprio'] == "true") {
        this.isDeclarantProprietaire = true;
      }
      if (params['foreur'] == "true") {
        this.isDeclarantExploitant = true;
      }
      if(params['tiers'] == "true"){
        this.isDeclarantTiers = true;
        this.declaration.acteurTiers = this.toActeur(this.account);
      }
      //acteurs facultatifs si dcl régul et declarant autre que tiers déclarant
      this.acteursFacultatifs = this.typeDeclaration.id == 1800 && !this.isDeclarantTiers;
    });
  }

  loadDeclaration() {
    this.routeSub = this.route.params.subscribe((params) => {
      if (params['id']) {
        this.declarationService.find(params['id']).subscribe((decl) => { 
          this.declaration = decl;
          if (this.declaration.acteurMoaId) {
            this.acteurService.find(this.declaration.acteurMoaId).subscribe((moa) => {
              this.declaration.acteurMoa = moa;
              if (moa.contactId)
                this.contactService.find(moa.contactId).subscribe((cont) =>this.declaration.acteurMoa.contact = cont);
              if (moa.adresseId)
                this.adresseService.find(moa.adresseId).subscribe((adre) => this.declaration.acteurMoa.adresse = adre);
            });
          }else{
            this.declaration.acteurMoa = new Acteur();
          }
        });
      }
    });
    
  }

  eauStatus(event) {
    this.isEau = event;
    if(this.declaration.fonctionId == 300) {
      this.isExploit = true;
    }else if (this.typeReglementation != 'FORDOM') {
      this.isExploit = false;
    }
  }

  multiplesOuvrages(event) {
    this.plusieursOuvrages = event;
  }

  domestiqueStatus(event) {
    this.isDomestique = event;
  }

  // gestion des erreurs de communication
  private onError(error: any) {
    this.alertService.error(error.message, null, null);
  }

  toOuvrages(event) {
    this.save(false);
    this.next(event);
  }

  skipMOA(event){
    this.noMoa = true;
    this.toLocalisation(event);
  }

  toLocalisation(event) {
    this.next(event);
  }

  toIdentite(event) {
    this.previous(event);
  }

  toActeur(declarant: Utilisateur): Acteur {
    let acteur: Acteur = new Acteur();
    acteur.email = declarant.email;
    acteur.siretEtab = declarant.siretEtablissement;
    acteur.statutJuridiqueId = declarant.statutJuridiqueId;
    acteur.adresse = new Adresse();
    if(this.declarant.adresse) {
      const adresse = this.declarant.adresse
      acteur.adresse.bp = adresse.bp;
      acteur.adresse.cedex = adresse.cedex;
      acteur.adresse.codePostal = adresse.codePostal;
      acteur.adresse.commune = adresse.commune;
      acteur.adresse.communeInseeCom = adresse.communeInseeCom;
      acteur.adresse.communeNomCom = adresse.communeNomCom;
      acteur.adresse.complement = adresse.complement;
      acteur.adresse.lieuDit = adresse.lieuDit;
      acteur.adresse.nomVoie = adresse.nomVoie;
      acteur.adresse.numeroVoie = adresse.numeroVoie;
      acteur.adresse.pays = adresse.pays;
      acteur.adresse.typeVoie = adresse.typeVoie;
  }

    acteur.contact = new Contact();
    if(this.declarant.contact) {
      const contact = this.declarant.contact;
      acteur.contact.nom = contact.nom;
      acteur.contact.prenom = contact.prenom;
      acteur.contact.civilite = contact.civilite;
      acteur.contact.tel1 = contact.tel1;
      acteur.contact.tel2 = contact.tel2;
      acteur.contact.emailContact = contact.emailContact;
      acteur.contact.acceptNotif = contact.acceptNotif;
    }
    
    acteur.raisonSocialeEtab = declarant.raisonSocialeEtab;
    acteur.siretEtab = declarant.siretEtablissement;
    return acteur;
  }

  private adresseDeclarant(callback) {
    if(this.declarant.adresseId) {
        this.adresseService.find(this.declarant.adresseId).subscribe((subRes: Adresse) => {
        this.declarant.adresse = subRes;
        
        if (this.declarant.adresse.communeInseeCom){
          this.communeService.findByInsee(this.declarant.adresse.communeInseeCom).subscribe( (comm : Commune) =>{
            this.declarant.adresse.commune = comm;
            this.contactDeclarant(callback);
          }
          , (subRes) => { this.contactDeclarant(callback); }
          );
        }else{
          this.contactDeclarant(callback);
        }
      }, (subRes) => { this.contactDeclarant(callback); });
    }else{
      this.contactDeclarant(callback);
    }
  }

  private contactDeclarant(callback) {
    if(this.declarant.contactId){
     this.contactService.find(this.declarant.contactId).subscribe((subRes: Contact) => {
        this.declarant.contact = subRes;
        callback();
      }, (subRes) => { callback(); });
    }else{
      callback();
    }
  }

  // page suivante
  next(event) {
    this.changeFieldSet($(event.target).parent().parent(), $(event.target).parent().parent().next());
  }

  // page precedente
  previous(event) {
    this.changeFieldSet($(event.target).parent().parent(), $(event.target).parent().parent().prev());
  }

  /**
   * Change the fieldset to display
   * @param current_fs current to hide
   * @param next_fs next to show
   */
  changeFieldSet(current_fs, next_fs) {
    // activate next step on progressbar using the index of next_fs
    $('#progressbar li').removeClass('active');
    $('#progressbar li').removeClass('done');
    let index = $('fieldset.step').index(next_fs);
    $('#progressbar li').eq(index).addClass('active');
    if (index != 0) {
      for (var i = 0; i < index; i++) {
        $('#progressbar li').eq(i).addClass('done');
      }
    }

    // show the next fieldset
    next_fs.show();

    // hide the current fieldset
    current_fs.hide();
  }

  validateDeclaration() {
    //console.log(this.isSubstance);
    let baseDeclaration = this.declaration.dateDebutTravaux != null
      && this.declaration.dateFinTravaux != null && this.declaration.dateFinTravaux > this.declaration.dateDebutTravaux
      && this.declaration.nomProjet != null && this.declaration.fonctionId != null && this.declaration.plusieursOuvrages != null;
    if( sessionStorage.getItem("typeReglementation") == 'FORDOM' ){
      baseDeclaration = baseDeclaration && (this.declaration.autreUsagePrecision != "" && this.declaration.autreUsagePrecision != null );
    }
    if (this.declaration.plusieursOuvrages) baseDeclaration = baseDeclaration && this.declaration.relationId != null;
    if (this.isSubstance) baseDeclaration = baseDeclaration && this.declaration.substanceId != null;
    if (this.isExploit) baseDeclaration = baseDeclaration && this.declaration.usageId != null;
    if (this.isICPE) baseDeclaration = baseDeclaration && this.declaration.regimeIcpeId != null;
    return !baseDeclaration;
  }

  validateMoa() {
    let baseActeur = false;
    if(this.isDeclarantMOA) {
      baseActeur = true;
    } else if(this.declaration.acteurMoa?.adresse) {
      let acteurMoa = this.declaration.acteurMoa;
      let adresseMoa = this.declaration.acteurMoa.adresse;

      baseActeur = acteurMoa.statutJuridiqueId != null && acteurMoa.email != null;
      if (acteurMoa.statutJuridiqueId == 3800) {
        baseActeur = baseActeur && acteurMoa.siretEtab != null && acteurMoa.raisonSocialeEtab != null;
      }
      baseActeur = baseActeur && adresseMoa.pays != null;
      if (adresseMoa.pays == "France") {
        baseActeur = baseActeur && adresseMoa.communeInseeCom != null;
      } else {
        baseActeur = baseActeur && adresseMoa.lieuDit != null;
      }

      if (acteurMoa.email != undefined){
        const regex = new RegExp('^[\\w- \\.]+@([\\w-]+\\.)+[\\w-]{2,4}$', 'g');
        baseActeur = baseActeur && acteurMoa.email.match(regex) != null;
      }
    }

    return !baseActeur;
  }

  validatePerimetre() {
    let isValid = true;
    let sommeVolumeOuvrage = 0;
    if(this.declarationOuvrages.ouvrages) {
      this.declarationOuvrages.ouvrages.forEach(o => {
        if(o.adresse?.commune) {
          let perimetre = _.find(this.perimetre, ['regionInseeReg', o.adresse.commune.inseeReg]);
          isValid = isValid && perimetre != null;
        }
        /*if (o.prelevement && o.prelevement != null){
          sommeVolumeOuvrage = sommeVolumeOuvrage + o.prelevement;
        }*/
      });
      /*if (sommeVolumeOuvrage > 1000){
        console.log("Total du volume : " + sommeVolumeOuvrage);
        isValid = isValid && (sommeVolumeOuvrage <= 1000);
      }*/
      
    }
    return isValid;
  }

  validateLocalisation(ouvrage: Ouvrage) {
    let baseOuvrage = ouvrage.modeXYId != null && ouvrage.srsId != null && ouvrage.x != null && ouvrage.y != null && ouvrage.typeLocalisationId != null && ouvrage.adresse != null && ouvrage.adresse.communeInseeCom != null;
    return baseOuvrage;
  }

  validateCarac(ouvrage: Ouvrage) {
    let baseOuvrage:boolean = ouvrage.longeur != null && ouvrage.natureOuvrageId != null && ouvrage.directionId != null;
    if(ouvrage.directionId == 2400) baseOuvrage = baseOuvrage && ouvrage.azimut != null && ouvrage.azimut>=0 && ouvrage.azimut<360 && ouvrage.pendage != null && ouvrage.pendage>=0 && ouvrage.pendage <= 90;
    /*if (this.isDomestique) baseOuvrage = baseOuvrage && ouvrage.diametre != null && ouvrage.presenceMargelle != null && ouvrage.conformeNorme != null
      && ouvrage.prelevement != null && ouvrage.debit != null && ouvrage.rejetUsees != null && ouvrage.reseauInterne != null && ouvrage.rejetPluviales != null;*/

    return baseOuvrage;
  }

  validateActeur(acteur: Acteur, isActeur:boolean) {
    let baseActeur = false;
    if(isActeur) {
      baseActeur = true;
    } else {
      baseActeur = acteur != null && acteur != undefined;
      if(baseActeur) {
        baseActeur = baseActeur && acteur.statutJuridiqueId != null && acteur.email != null;
        if (acteur.adresse) {
          baseActeur = baseActeur && acteur.adresse.pays != null;
          if (acteur.adresse.pays == "France") {
            baseActeur = baseActeur && acteur.adresse.communeInseeCom != null;
          } else {
            baseActeur = baseActeur && acteur.adresse.lieuDit != null;
          }
        } else {
          baseActeur = false;
        }
        if (acteur.statutJuridiqueId == 3800) {
          baseActeur = baseActeur && acteur.siretEtab != null && acteur.raisonSocialeEtab != null;
        }
      }
    }
    return baseActeur;
  }

  validateOuvrages() {
    let baseOuvrage:boolean = this.declaration.plusieursOuvrages?this.declarationOuvrages.ouvrages.length > 1:this.declarationOuvrages.ouvrages.length==1;

    let sommeVolumeOuvrage = 0;

    const nomUsuelList = new Set();
    
    this.declarationOuvrages.ouvrages.forEach(ouvrage => {

      if(nomUsuelList.has(ouvrage.nomUsuel)){
        baseOuvrage = false;
      }else{
        nomUsuelList.add(ouvrage.nomUsuel);
      }


      baseOuvrage = baseOuvrage && ouvrage.nomUsuel != null && ouvrage.nomUsuel != "" && this.validateLocalisation(ouvrage) && this.validateCarac(ouvrage);
      if(!this.isDeclarantProprietaire && !this.acteursFacultatifs ) baseOuvrage = baseOuvrage && this.validateActeur(ouvrage.acteurProprio, this.isDeclarantProprietaire);
      if(!this.isDeclarantMOE && !this.acteursFacultatifs) baseOuvrage = baseOuvrage && this.validateActeur(ouvrage.acteurMoe, this.isDeclarantMOE);

      if (ouvrage.prelevement && ouvrage.prelevement != null){
        sommeVolumeOuvrage = sommeVolumeOuvrage + ouvrage.prelevement;
      }

    });

    if (sessionStorage.getItem('typeReglementation') !== null && sessionStorage.getItem('typeReglementation') == 'FORDOM' && sommeVolumeOuvrage > 1000){
      baseOuvrage = false;
    }

    /*if(this.isEau) {
      if(this.isDomestique) baseOuvrage = baseOuvrage && this.declaration.prelevement>0 && this.declaration.prelevement < 1000;
      else baseOuvrage = baseOuvrage && this.declaration.prelevement>=1000;
    }*/

    return !baseOuvrage;
  }

  save(forClose:boolean) {
    // commence par sauver le déclarant s'il n'existe pas (il est forcément l'un des acteurs), puis l'acteur MOA
    this.forClose = forClose;
    this.isSaving = true;
    if(this.declarant.id) {
      if(this.declaration.acteurTiers){
        this.declaration.acteurTiersId = this.declarant.id;
      }
      this.saveMOA(this);
    } else {
      this.acteurService.create(this.declarant).subscribe(
        (savedActeur: Acteur) => {
          this.declarant.id = savedActeur.id;
          this.declarant.auteurCreation = savedActeur.auteurCreation;
          this.declarant.auteurModification = savedActeur.auteurModification;
          if(this.declaration.acteurTiers){
            this.declaration.acteurTiersId = savedActeur.id;
          }
          this.saveMOA(this);
        });
    }
  }

  saveMOA(declarationForm: CreationDeclarationFormComponent) {
      if(declarationForm.declaration.acteurMoa && !declarationForm.noMoa) {
        declarationForm.saveActeur(declarationForm.declaration.acteurMoa, declarationForm, declarationForm.saveDeclaration, null, 'acteurMoa');
      } else {
        //Sinon on passe à la déclaration
        declarationForm.saveDeclaration(declarationForm, null);
      }
  }

  private saveActeur(acteur: Acteur, declarationForm: CreationDeclarationFormComponent, callback: Function, ouvrage?: any, type?: string) {
    if (acteur.adresse && (acteur.adresse.commune || acteur.adresse.lieuDit)) {
      if (acteur.adresse.id != null) {
        this.adresseService.update(acteur.adresse).subscribe((updatedAdresse: Adresse) => {
          this.onSaveAdresseSuccess(acteur, updatedAdresse, declarationForm, callback, ouvrage, type);
        },
          (res: any) => this.onSaveError(res));
      } else {
        this.adresseService.create(acteur.adresse).subscribe(
          (savedAdresse: Adresse) => {
            this.onSaveAdresseSuccess(acteur, savedAdresse, declarationForm, callback, ouvrage, type);
          }
          , (res: any) => this.onSaveError(res));
      }
    } else {
      this.onSaveAdresseSuccess(acteur, null, declarationForm, callback, ouvrage, type);
    }
  }

  private onSaveAdresseSuccess(acteur: Acteur, savedAdresse: Adresse, declarationForm: CreationDeclarationFormComponent, callback: Function, ouvrage?: any, type?: string) {
    // si on est ici on a deja verifie que le formulaire acteur existe
    if (savedAdresse) {
      acteur.adresse.id = savedAdresse.id;
      acteur.adresseId = savedAdresse.id;
      acteur.adresse.auteurCreation = savedAdresse.auteurCreation;
      acteur.adresse.auteurModification = savedAdresse.auteurModification;
    }
    if (acteur.contact) {
      if(acteur.contact.emailContact == null || acteur.contact.emailContact == undefined){
        acteur.contact.emailContact = acteur.email;
      }
      if (acteur.contact.id != null) {
        declarationForm.contactService.update(acteur.contact).subscribe((updatedContact: Contact) => {
          declarationForm.onSaveContactSuccess(acteur, updatedContact, declarationForm, callback, ouvrage, type);
        }
          , (res: any) => this.onSaveError(res));
      } else {
        if(acteur.contact.nom) {
          declarationForm.contactService.create(acteur.contact).subscribe(
            (savedContact: Contact) => declarationForm.onSaveContactSuccess(acteur, savedContact, declarationForm, callback, ouvrage, type)
            , (res: any) => declarationForm.onSaveError(res));
        } else {
          declarationForm.onSaveContactSuccess(acteur, null, declarationForm, callback, ouvrage, type);
        }
      }
    } else {
      declarationForm.onSaveContactSuccess(acteur, null, declarationForm, callback, ouvrage, type);
    }
  }

  private onSaveContactSuccess(acteur: Acteur, savedContact: Contact, declarationForm: CreationDeclarationFormComponent, callback: Function, ouvrage?: any, type?: string) {
    // si on est ici on a deja verifie que le formulaire acteur existe
    if (savedContact) {
      acteur.contact.id = savedContact.id;
      acteur.contactId = savedContact.id;
      acteur.contact.auteurCreation = savedContact.auteurCreation;
      acteur.contact.auteurModification = savedContact.auteurModification;
    }
    if (acteur.id != null) {
      declarationForm.acteurService.update(acteur).subscribe((updatedActeur) => {
        if (ouvrage) {
          //ouvrage[type] = updatedActeur;
          let typeId = type + "Id";
          ouvrage[typeId] = updatedActeur.id;
          ouvrage[type].id = updatedActeur.id;
          ouvrage[type].auteurCreation = updatedActeur.auteurCreation;
          ouvrage[type].auteurModification = updatedActeur.auteurModification;
        } else {
          declarationForm.declaration.acteurMoa.id = updatedActeur.id;
          declarationForm.declaration.acteurMoaId = updatedActeur.id;
          declarationForm.declaration.acteurMoa.auteurCreation = updatedActeur.auteurCreation;
          declarationForm.declaration.acteurMoa.auteurModification = updatedActeur.auteurModification;
        }
        if (typeof callback === "function"){
          callback(declarationForm, ouvrage);
        }
        
      }
        , (res: any) => declarationForm.onSaveError(res));
    } else {
      declarationForm.acteurService.create(acteur).subscribe(
        (savedActeur: Acteur) => {
          if (ouvrage) {
            ouvrage[type].id = savedActeur.id;
            let typeId = type + "Id";
            ouvrage[typeId] = savedActeur.id;
            ouvrage[type].auteurCreation = savedActeur.auteurCreation;
            ouvrage[type].auteurModification = savedActeur.auteurModification;
          } else {
            declarationForm.declaration.acteurMoa.id = savedActeur.id;
            declarationForm.declaration.acteurMoaId = savedActeur.id;
            declarationForm.declaration.acteurMoa.auteurCreation = savedActeur.auteurCreation;
            declarationForm.declaration.acteurMoa.auteurModification = savedActeur.auteurModification;
          }
          if (typeof callback === "function"){
            callback(declarationForm, ouvrage);
          }
        }
        , (res: any) => declarationForm.onSaveError(res));
    }
  }

  private saveDeclaration(declarationForm: CreationDeclarationFormComponent, ouvrage?: any) {
    if (declarationForm.declaration.id != null) {
      declarationForm.declarationService.update(declarationForm.declaration).subscribe(
        (updatedDeclaration: Declaration) => declarationForm.onSaveDeclarationSuccess(updatedDeclaration, declarationForm)
        , (res: any) => declarationForm.onSaveError(res));
    } else {
      declarationForm.declaration.typeDeclarationId = declarationForm.typeDeclaration.id;
      let t = _.find(declarationForm.lexstatutdeclarations, ['mnemo', 'ENCOURS']);
      declarationForm.declaration.statutDeclarationId = t.id;
      
      declarationForm.declarationService.create(declarationForm.declaration).subscribe(
        (savedDeclaration: Declaration) => declarationForm.onSaveDeclarationSuccess(savedDeclaration, declarationForm)
        , (res: any) => declarationForm.onSaveError(res));
    }
  }

  private onSaveDeclarationSuccess(savedDeclaration: Declaration, declarationForm: CreationDeclarationFormComponent) {
    declarationForm.declaration.id = savedDeclaration.id;
    declarationForm.declaration.auteurCreation = savedDeclaration.auteurCreation;
    if (declarationForm.declarationOuvrages.ouvrages.length != 0) {
      
      declarationForm.declarationOuvrages.ouvrages.forEach((ouvrage: Ouvrage) => {
        ouvrage.declarationId = savedDeclaration.id;
        if (ouvrage.adresse) {
          if (ouvrage.id != null) {
            declarationForm.adresseService.update(ouvrage.adresse).subscribe(
              (updatedAdresse: Adresse) => {
                declarationForm.onSaveAdresseOuvragesSuccess(updatedAdresse, ouvrage, declarationForm);
              }
              , (res: any) => declarationForm.onSaveError(res));
          } else {
            declarationForm.adresseService.create(ouvrage.adresse).subscribe(
              (savedAdresse: Adresse) => {
                declarationForm.onSaveAdresseOuvragesSuccess(savedAdresse, ouvrage, declarationForm);
              }
              , (res: any) => 
                declarationForm.onSaveError(res)
              );
          }
        } else {
          declarationForm.saveActeursOuvrage(declarationForm, ouvrage);
        }
      });
    } else {
      declarationForm.isSaving = false;
      if(declarationForm.forClose) declarationForm.gotoAccueil(null);
    }
  }

  private saveActeursOuvrage(declarationForm: CreationDeclarationFormComponent, ouvrage:Ouvrage) {

    if(declarationForm.isDeclarantMOE) {
      ouvrage.acteurMoe = this.toActeur(this.account);
    }
    if(declarationForm.isDeclarantExploitant) {
      ouvrage.acteurForeur = this.toActeur(this.account);
    }
    if(declarationForm.isDeclarantProprietaire) {
      ouvrage.acteurProprio = this.toActeur(this.account);
    }
    
    if(declarationForm.isDeclarantMOE && this.declarant.id)
    {
      declarationForm.saveActeur(ouvrage.acteurMoe, declarationForm, declarationForm.onSaveActeurMoeSuccess, ouvrage, "acteurMoe");
      
    } else {
      if (ouvrage.acteurMoe?.email) {
        declarationForm.saveActeur(ouvrage.acteurMoe, declarationForm, declarationForm.onSaveActeurMoeSuccess, ouvrage, "acteurMoe");
      } else if (ouvrage.acteurProprio?.email) {
        declarationForm.saveActeur(ouvrage.acteurProprio, declarationForm, declarationForm.onSaveActeurProprioSuccess, ouvrage, "acteurProprio");
      } else if (ouvrage.acteurForeur?.email) {
        declarationForm.saveActeur(ouvrage.acteurForeur, declarationForm, declarationForm.saveOuvrage, ouvrage, "acteurForeur");
      } else {
        declarationForm.saveOuvrage(declarationForm, ouvrage);
      }
    }

  }

  private onSaveAdresseOuvragesSuccess(savedAdresse: Adresse, ouvrage: Ouvrage, declarationForm: CreationDeclarationFormComponent) {
    ouvrage.adresse.id = savedAdresse.id;
    ouvrage.adresseId = savedAdresse.id;
    ouvrage.adresse.auteurCreation = savedAdresse.auteurCreation;
    ouvrage.adresse.auteurModification = savedAdresse.auteurModification;
   declarationForm.saveActeursOuvrage(declarationForm, ouvrage);
  }

  private onSaveActeurMoeSuccess(declarationForm: CreationDeclarationFormComponent, ouvrage: any) {
    if(declarationForm.isDeclarantProprietaire)
    {
      declarationForm.saveActeur(ouvrage.acteurProprio, declarationForm, declarationForm.onSaveActeurProprioSuccess, ouvrage, "acteurProprio");
      
    } else {
      if (ouvrage.acteurProprio?.email) {
        declarationForm.saveActeur(ouvrage.acteurProprio, declarationForm, declarationForm.onSaveActeurProprioSuccess, ouvrage, "acteurProprio");
      } else if (ouvrage.acteurForeur?.email) {
        declarationForm.saveActeur(ouvrage.acteurForeur, declarationForm, declarationForm.saveOuvrage, ouvrage, "acteurForeur");
      } else {
        declarationForm.saveOuvrage(declarationForm, ouvrage);
      }
    }
  }

  private onSaveActeurProprioSuccess(declarationForm: CreationDeclarationFormComponent, ouvrage: any, type: string) {
    if(declarationForm.isDeclarantExploitant) {
      declarationForm.saveActeur(ouvrage.acteurForeur, declarationForm, declarationForm.saveOuvrage, ouvrage, "acteurForeur");
    } else {
      if (ouvrage.acteurForeur?.email) {
        declarationForm.saveActeur(ouvrage.acteurForeur, declarationForm, declarationForm.saveOuvrage, ouvrage, "acteurForeur");
      } else {
        declarationForm.saveOuvrage(declarationForm, ouvrage);
      }
    }
  }

  private saveOuvrage(declarationForm: CreationDeclarationFormComponent, ouvrage: Ouvrage) {
    if (!ouvrage.id) {
      let op = _.find(declarationForm.lexoperations, ['mnemo', 'CREATION']);
      ouvrage.operationId = op.id;
      if (!ouvrage.typeSaisieCoorId) ouvrage.typeSaisieCoorId = null;
      declarationForm.ouvrageService.create(ouvrage).subscribe(
        (savedOuvrage: Ouvrage) => declarationForm.onSaveOuvragesSuccess(ouvrage, savedOuvrage, declarationForm)
        , (res: any) => declarationForm.onSaveError(res)
      );
    } else {
      declarationForm.ouvrageService.update(ouvrage).subscribe(
        (updatedOuvrage: Ouvrage) => declarationForm.onSaveOuvragesSuccess(ouvrage, updatedOuvrage, declarationForm)
        , (res: any) => declarationForm.onSaveError(res)
      );
    }
  }

  private onSaveOuvragesSuccess(ouvrage: Ouvrage, savedOuvrage: Ouvrage, declarationForm: CreationDeclarationFormComponent) {
    ouvrage.id = savedOuvrage.id;
    ouvrage.auteurCreation = savedOuvrage.auteurCreation;
    ouvrage.auteurModification = savedOuvrage.auteurModification;
    ouvrage.adresse.communeNomCom = ouvrage.adresse.commune.nomCom;
    declarationForm.savedOuvrages.push(ouvrage);
    if (declarationForm.savedOuvrages.length === declarationForm.declarationOuvrages.ouvrages.length) {
      declarationForm.declarationOuvrages.ouvrages = [];
      declarationForm.declarationOuvrages.ouvrages = [...declarationForm.savedOuvrages];
      declarationForm.savedOuvrages = [];
      if(declarationForm.forClose) {
        declarationForm.gotoAccueil(null);
      } else
      {
        declarationForm.finalSearch(declarationForm);
      }
    }
  }

  private finalSearch(declarationForm: CreationDeclarationFormComponent) {
    //if (declarationForm.lexReglementations == null || declarationForm.lexReglementations == []){ 
      declarationForm.declarationReglementationService.findByDeclaration(declarationForm.declaration.id).subscribe((res: LexReglementation[]) => {
        declarationForm.lexReglementations = res;
        declarationForm.isSaving = false;
      });
    //} else {

    //}
    declarationForm.declarationService.validate(declarationForm.declaration.id).subscribe((res: boolean) => {
      declarationForm.isValid = res;
      if (!res) {
        declarationForm.titreSoumission = "La déclaration n'est pas complète !";
      } else {
        declarationForm.titreSoumission = "Transmettre votre déclaration";
      }
    }, (err) => {
      declarationForm.isValid = false;
      declarationForm.titreSoumission = "La déclaration n'est pas complète !";
    });
  }

  isMinier() {
    let mine = _.find(this.lexReglementations, function (r) { return r.code == '1'; });
    return mine != null;
  }

  isLoiEau() {
    let eau = _.find(this.lexReglementations, function (r) { return r.code == '2'; });
    return eau != null;
  }

  isLoiIcpe() {
    let icpe = _.find(this.lexReglementations, function (r) { return r.code == '3' || r.code == '4'; });
    return icpe != null;
  }

  isForageDomestique() {
    let fd = _.find(this.lexReglementations, function (r) { return r.code == '5'; });
    return fd != null;
  }

  private onSaveError(res?: any) {
    if (res) {
      console.log(res);
    }
    this.isSaving = false;
  }

  trackId(index: number, item: Ouvrage) {
    return item.id;
  }

  find(list: any, id: any) {
    let item = _.find(list, ['id', id]);
    return item ? item.libelle : "";
  }

  openDialog(id) {
    window.open(this.dialogUrl + id, "_blank");
  }

  uploadFile(id, nom) {
    let modalRef: NgbModalRef = this.pieceJointePopupService.pieceJointeModalRef(PieceJointePopupComponent as Component, id, nom, this.declaration.id, true);
    modalRef.result.then((result) => {
    }, (reason) => { });
  }

  soumettre(event) {
    let modalRef: NgbModalRef = this.soumissionPopupService.soumissionModalRef(SoumissionPopupComponent as Component, this.declaration.id, this.declarationOuvrages.ouvrages.length>1);
    modalRef.result.then((result) => {
      this.soumissionError = false;
      this.gotoAccueil(event);
    }, (reason) => { if(reason === "Erreur") {
      this.soumissionError = true;
    }});
  }

  supprimer(event) {
    let modalRef: NgbModalRef = this.abandonPopupService.abandonModalRef(DeletePopupComponent as Component, this.declaration.id);
    modalRef.result.then((result) => {
      this.gotoAccueil(event);
    }, (reason) => { });
  }

  gotoAccueil(event) {
    this.router.navigate(['']);
  }

}
