import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from './alert.service';
import { DuplosEventManager } from '../event/event.service';

@Component({
    selector: 'dup-alert-error',
    template: `
        <div class="alerts" role="alert">
            <div *ngFor="let alert of alerts"  [ngClass]="{\'alert.position\': true, \'toast\': alert.toast}">
                <ngb-alert *ngIf="alert && alert.type && alert.msg" [type]="alert.type" (close)="alert.close(alerts)">
                    <pre [innerHTML]="alert.msg"></pre>
                </ngb-alert>
            </div>
        </div>`
})
export class DupAlertErrorComponent implements OnDestroy {

    alerts: any[];
    cleanHttpErrorListener: Subscription;
    cleanDateErrorListener: Subscription;
    // tslint:disable-next-line: no-unused-variable
    constructor(private alertService: AlertService, private eventManager: DuplosEventManager) {
        this.alerts = [];

        this.cleanDateErrorListener = eventManager.subscribe('date.error', (response) => {
            this.addErrorAlert(response.content);
        });

        this.cleanHttpErrorListener = eventManager.subscribe('duplosApp.httpError', (response) => {
            let i;
            const httpResponse = response.content;
            /*switch (httpResponse.status) {
                // connection refused, server not reachable
                case 0:
                    this.addErrorAlert('Server not reachable', 'error.server.not.reachable');
                    break;

                case 400:
                    const arr = Array.from(httpResponse.headers._headers);
                    const headers = [];
                    for (i = 0; i < arr.length; i++) {
                        if (arr[i][0].endsWith('app-error') || arr[i][0].endsWith('app-params')) {
                            headers.push(arr[i][0]);
                        }
                    }
                    headers.sort();
                    let errorHeader = null;
                    let entityKey = null;
                    if (headers.length > 1) {
                        errorHeader = httpResponse.headers.get(headers[0]);
                        entityKey = httpResponse.headers.get(headers[1]);
                    }
                    if (errorHeader) {
                        const entityName = entityKey;
                        this.addErrorAlert(errorHeader, errorHeader, { entityName });
                    } else if (httpResponse && httpResponse.fieldErrors) {
                        const fieldErrors = httpResponse.fieldErrors;
                        for (i = 0; i < fieldErrors.length; i++) {
                            const fieldError = fieldErrors[i];
                            // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                            const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                            const fieldName = convertedField.charAt(0).toUpperCase() +
                                convertedField.slice(1);
                            this.addErrorAlert(
                                'Error on field "' + fieldName + '"', 'error.' + fieldError.message, { fieldName });
                        }
                    } else if (httpResponse && httpResponse.message) {
                        this.addErrorAlert(httpResponse.message, httpResponse.message, httpResponse.params);
                    } else {
                        this.addErrorAlert(httpResponse.name);
                    }
                    break;

                case 404:
                    this.addErrorAlert('Not found', 'error.url.not.found');
                    break;

                default:
                    if ( httpResponse && httpResponse.message) {
                        this.addErrorAlert(httpResponse.message);
                    } else {
                        this.addErrorAlert(httpResponse.name);
                    }
            }*/
        });
    }

    ngOnDestroy() {
        if (this.cleanHttpErrorListener !== undefined && this.cleanHttpErrorListener !== null) {
            this.eventManager.destroy(this.cleanHttpErrorListener);
            this.alerts = [];
        } 
        if (this.cleanDateErrorListener !== undefined && this.cleanDateErrorListener !== null) {
            this.eventManager.destroy(this.cleanDateErrorListener);
            this.alerts = [];
        }
    }

    addErrorAlert(message, key?, data?) {
        this.alerts.push(
            this.alertService.addAlert(
                {
                    type: 'danger',
                    msg: message,
                    timeout: 0,
                    toast: this.alertService.isToast(),
                    scoped: true
                },
                this.alerts
            )
        );
    }
}
