import { NgModule, LOCALE_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import locale from '@angular/common/locales/en';
import { DuplosSharedLibsModule } from './shared-libs.module';
import { DupAlertComponent } from './alert/alert.component';
import { DupAlertErrorComponent } from './alert/alert-error.component';

@NgModule({
    imports: [
        DuplosSharedLibsModule
    ],
    declarations: [
        DupAlertComponent,
        DupAlertErrorComponent
    ],
    providers: [
        Title,
        {
            provide: LOCALE_ID,
            useValue: 'en'
        },
    ],
    exports: [
        DuplosSharedLibsModule,
        DupAlertComponent,
        DupAlertErrorComponent
    ]
})
export class DuplosSharedCommonModule {
    constructor() {
        registerLocaleData(locale);
    }
}
