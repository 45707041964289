import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { DeclarationReglementation } from './declaration-reglementation.model';
import { LexReglementation } from '../lex-reglementation/lex-reglementation.model';
import { DateUtils } from 'src/app/shared/model/date-utils';

@Injectable()
export class DeclarationReglementationService {

    private resourceUrl =  SERVER_API_URL + 'api/declaration-reglementations';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    create(declarationReglementation: DeclarationReglementation): Observable<DeclarationReglementation> {
        const copy = this.convert(declarationReglementation);
        return this.http.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    update(declarationReglementation: DeclarationReglementation): Observable<DeclarationReglementation> {
        const copy = this.convert(declarationReglementation);
        return this.http.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    find(id: number): Observable<DeclarationReglementation> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findByDeclaration(id: number): Observable<LexReglementation[]> {
        return this.http.get(`${this.resourceUrl}/declaration/${id}`).pipe(map((res) => {
            return this.convertReglementationResponse(res);
        }));
    }

    query(req?: any): Observable<DeclarationReglementation[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    private convertResponse(res): DeclarationReglementation[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    private convertReglementationResponse(res): LexReglementation[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(res[i]);
        }
        return result;
    }

    /**
     * Convert a returned JSON object to DeclarationReglementation.
     */
    private convertItemFromServer(json: any): DeclarationReglementation {
        const entity: DeclarationReglementation = Object.assign(new DeclarationReglementation(), json);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        return entity;
    }

    /**
     * Convert a DeclarationReglementation to a JSON which can be sent to the server.
     */
    private convert(declarationReglementation: DeclarationReglementation): DeclarationReglementation {
        const copy: DeclarationReglementation = Object.assign({}, declarationReglementation);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(declarationReglementation.dateCreation);
        return copy;
    }
}
