<div  class="col-md-10 offset-md-1">
  <div class="logo form-group">
    <i class="logo-img"></i>
    <h2>Mentions légales</h2>
  </div>
  <div>
    <h2>Édition</h2>
    <p>Le portail de Téléservice de la géothermie de minime importance est proposé et édité par le Ministère de la Transition écologique.</p>
    <p><span style="font-style: italic"> Ministère de la Transition écologique : </span></p>
    <img src="/assets/images/logo_ministere.svg" width="400" alt="Logo ministère">

    <p  style="font-style: italic"> Place Carpeaux <br/>
    Tour Séquoia<br/>
    92055 La Défense Cedex<br/>
    Tél : +33 (0)1 40 81 21 22
    </p>
    <a href="https://www.ecologie.gouv.fr/">&gt; En savoir plus sur le Ministère</a>

    <br/> <br/>
    <h2>Opérateur</h2>
    <p>Le site Internet DUPLOS est réalisé et hébergé par le BRGM</p>
    <p style="font-style: italic">BRGM - Centre scientifique et technique :</p>
    <p>
      3 avenue Claude-Guillemin, BP 36009<br/>
      45060 Orléans Cedex 02<br/>
      France<br/>
      Tél : + 33 (0)2 38 64 34 34
    </p>

    <a href="https://www.brgm.fr/fr">&gt; En savoir plus sur le BRGM</a> <br/>
    
</div>
