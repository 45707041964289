export class PieceJointe {
    constructor(
        public id?: number,
        public nomFichier?: string,
        public dateCreation?: any,
        public dateModification?: any,
        public declarationId?: number,
        public ouvrageId?: number,
        public auteurCreationId?: number,
        public auteurModificationID?: number,
        public docTypeId?:number,
        public docTypeLibelle?:string,
        public fichier?:FormData
    ) {
    }
}
