import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {MultiSelectModule} from 'primeng/multiselect';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from 'primeng/table';
import {PanelModule} from 'primeng/panel';
import { FormsModule } from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';

import { UtilisateurService } from './utilisateur.service';
import { utilisateurRoute, utilisateurPopupRoute } from './utilisateur.route';
import { UtilisateurComponent } from './utilisateur.component';
import { UtilisateurDialogComponent, UtilisateurPopupComponent } from './utilisateur-dialog.component';
import { UtilisateurPopupService } from './utilisateur-popup.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';

const ENTITY_STATES = [
    ...utilisateurRoute,
    ...utilisateurPopupRoute,
];

@NgModule({
    imports: [
        DuplosSharedModule, MultiSelectModule,
        NgSelectModule, FormsModule, TableModule, PanelModule, CalendarModule,
        RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
        UtilisateurComponent,
        UtilisateurDialogComponent,
        UtilisateurPopupComponent,
    ],
    providers: [
        UtilisateurService,
        UtilisateurPopupService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosUtilisateurModule {}
