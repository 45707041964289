import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import * as proj4 from 'proj4';
import { register } from 'ol/proj/proj4';

import { Title } from '@angular/platform-browser';
import { Utilisateur } from 'src/app/entities/utilisateur/utilisateur.model';
import { Principal } from 'src/app/shared/auth/principal.service';
import { LexSystemeReferenceSpatial } from 'src/app/entities/lex-systeme-reference-spatial/lex-systeme-reference-spatial.model';
import { LexSystemeReferenceSpatialService } from 'src/app/entities/lex-systeme-reference-spatial/lex-systeme-reference-spatial.service';

@Component({
    selector: 'dup-main',
    templateUrl: './main.component.html'
})
export class DupMainComponent implements OnInit, OnDestroy {

    lexsrs:LexSystemeReferenceSpatial[];

    account:Utilisateur;

    isAuthenticated:Boolean = false;
    
    constructor(
        private titleService: Title,
        private lexSystemeReferenceSpatialService:LexSystemeReferenceSpatialService,
        private principal: Principal,
        private router: Router
    ) {}

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title: string = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : 'duplosApp';
        if (routeSnapshot.firstChild) {
            title = this.getPageTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }

    ngOnInit() {
        this.principal.identity().then((account) => {
            this.account = account;
            this.isAuthenticated = this.account!=undefined && this.account.adresseId != null && this.account.adresseId != undefined;
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.titleService.setTitle(this.getPageTitle(this.router.routerState.snapshot.root));
            }
        });
        this.lexSystemeReferenceSpatialService.query()
        .subscribe((res) => { 
            this.lexsrs = res; 
            let proj4X = (proj4 as any).default;
            let defs = [];
            this.lexsrs.forEach(srs => {
                defs.push(['EPSG:'+srs.epsg,srs.description]);
            });
            proj4X.defs(defs);
            register(proj4X);
        });

        this.principal.getCompleted().subscribe((boolean)=>{
            this.isAuthenticated = true;
        });
    }

    ngOnDestroy(): void {
        this.principal.getCompleted().unsubscribe();
    }
}
