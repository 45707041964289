<nav class="navbar navbar-white navbar-expand-md jh-navbar fixed-top">
    <div class="jh-logo-container float-left">
        <a class="jh-navbar-toggler d-lg-none float-right" href="javascript:void(0);" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
            <i class="fa fa-bars"></i>
        </a>
        <a class="navbar-brand logo float-left" routerLink="/" (click)="collapseNavbar()">
            <i class="logo-img"></i>
        </a>
    </div>
    <div class="navbar-collapse collapse" id="navbarResponsive" [ngbCollapse]="isNavbarCollapsed">
        <h2 class="navbar-brand">DUPLOS</h2>
        <h3>Déclaration Unifiée Pour Les Ouvrages Souterrains 
            <!--span style="color: red;font-weight: bold;" *ngIf="typeDeclaration == 'MIN'" >Forages Code Minier</span>
            <span style="color: red;font-weight: bold;" *ngIf="typeDeclaration == 'FORDOM'" >Forages domestiques</span-->
        </h3>
        <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown pointer">
                <a href="https://assistance.brgm.fr/aide/DUPLOS" target="_blank" class="nav-link">
                    <i class="fas fa-question-circle" aria-hidden="true"></i>
                    <span>Besoin d'aide ?</span></a>
            </li>
            <li [hidden]="currentAccount?.profilUtilisateurId == 4450" ngbDropdown class="nav-item dropdown pointer">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="new-declaration">
                    <i class="fa fa-file" aria-hidden="true"></i>
                    <span>Déclarer forage (code minier)</span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li *ngFor="let lexTypeDeclarationOption of lextypedeclarations">
                        <a class="dropdown-item" (click)="openNewDeclaration(lexTypeDeclarationOption.id, 'MIN')" *ngIf="lexTypeDeclarationOption.valide">
                            <i class="fa fa-fw fa-asterisk" aria-hidden="true"></i>
                            <span>{{lexTypeDeclarationOption.libelle}} (Code Minier)</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li ngbDropdown class="nav-item dropdown pointer">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="new-declaration">
                    <i class="fa fa-file" aria-hidden="true"></i>
                    <span>Déclarer forage domestique</span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li *ngFor="let lexTypeDeclarationOption of lextypedeclarations">
                        <a class="dropdown-item" (click)="openNewDeclaration(lexTypeDeclarationOption.id, 'FORDOM')" *ngIf="lexTypeDeclarationOption.valide">
                            <i class="fa fa-fw fa-asterisk" aria-hidden="true"></i>
                            <span>{{lexTypeDeclarationOption.libelle}} (Forage Domestique)</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li ngbDropdown class="nav-item dropdown pointer" placement="bottom-right" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <a class="nav-link dropdown-toggle" ngbDropdownToggle href="javascript:void(0);" id="account-menu">
                  <span>
                    <i class="fa fa-user-circle" aria-hidden="true"></i>
                    <span *ngIf="currentAccount">
                        {{currentAccount && !isState && currentContact ? currentContact.prenom:''}} {{currentContact && currentContact.nom ? currentContact.nom : ''}} {{currentAccount && isState ?'(Service de l\'Etat)':''}} {{currentAccount && isAdmin ?'(Administrateur)':''}}
                    </span>
                  </span>
                </a>
                <ul class="dropdown-menu" ngbDropdownMenu>
                    <li>
                        <a class="dropdown-item" routerLink="settings" routerLinkActive="active" (click)="collapseNavbar()">
                            <i class="fa fa-fw fa-wrench" aria-hidden="true"></i>
                            <span>Mon compte</span>
                        </a>
                    </li>
                    <li [hidden]="!isAdmin">
                        <a class="dropdown-item" routerLink="utilisateur" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" (click)="collapseNavbar()">
                            <i class="fa fa fa-users" aria-hidden="true"></i>
                            <span>Utilisateurs</span>
                        </a>
                    </li>
                    <li>
                        <a class="dropdown-item" (click)="logout()" id="logout">
                            <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
                            <span>Se déconnecter</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</nav>
