import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import * as _ from 'lodash';

import { Ouvrage } from 'src/app/entities/ouvrage/ouvrage.model';
import { AquiferePopupService } from '../../shared/popup/aquifere-popup.service';
import { Aquifere } from 'src/app/entities/aquifere/aquifere.model';
import { AquifereService } from 'src/app/entities/aquifere/aquifere.service';
import { AlertService } from 'src/app/shared/alert/alert.service';

@Component({
  selector: 'dup-aquifere',
  templateUrl: './aquifereform.component.html',
  styleUrls: [
    '../forms.css'
  ]
})
export class AquifereFormComponent implements OnChanges {
  
  @Input() ouvrage: Ouvrage;

  @Input() srs: string;
  
  aquiferes:Aquifere[];

  aquifereSelected:Aquifere;

  isntKnown:Boolean = true;

  // est-ce que la sauvegarde est en cours
  isLoading: Boolean = false;

  constructor(
      private alertService: AlertService,
      private aquifereService: AquifereService
  ) {
    this.aquifereSelected = new Aquifere();
  }

  ngOnChanges(changes: SimpleChanges):void {
    for (let propName in changes) {  
      if(propName == 'ouvrage')
      {
        if(this.ouvrage.x && this.ouvrage.y && this.srs) {
          this.isLoading = true;
          this.aquifereService.findAquifere(this.ouvrage.x, this.ouvrage.y, this.srs)
          .subscribe((res) => { 
            this.aquiferes = res; 
            this.isLoading = false;
            if(this.ouvrage.codeNappe) {
              let aquifere = _.find(this.aquiferes, ['code', this.ouvrage.codeNappe]);
              this.aquifereSelected = aquifere;
            }   
          }, (res) => this.onError(res));
        }
      }
    }
  }

    // gestion des erreurs de communication
  private onError(error: any) {
    this.alertService.error(error.message, null, null);
  }

  changeAquifere(event) {
    if(event) {
      this.isntKnown = false;
      this.ouvrage.codeNappe = event.code;
      this.ouvrage.natureNappe = event.nature;
      this.ouvrage.nomNappe = event.nom;
    }
  }

  changeUnknown(event) {
    this.isntKnown = true;
    this.aquifereSelected = new Aquifere();
    this.ouvrage.codeNappe = null;
      this.ouvrage.natureNappe = null;
      this.ouvrage.nomNappe = null;
  }

  validateAquifere() {
    let baseAquifere = this.isntKnown || this.aquifereSelected != null;
    return !baseAquifere;
  }
}

@Component({
  selector: 'dup-aquifere-popup',
  template: ''
})
export class AquiferePopupComponent implements OnInit, OnDestroy {

  routeSub: any;

  constructor(
    private route: ActivatedRoute,
    private ouvragePopupService: AquiferePopupService
  ) {}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
        if ( params['id'] ) {
            this.ouvragePopupService
                .open(AquifereFormComponent as Component, params['id']);
        } else {
            this.ouvragePopupService
                .open(AquifereFormComponent as Component);
        }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
}