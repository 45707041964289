export class LexRelation {
    constructor(
        public id?: number,
        public code?: string,
        public mnemo?: string,
        public libelle?: string,
        public definition?: string,
        public valide?: boolean,
        public dateCreation?: any,
        public dateModification?: any,
    ) {
        this.valide = false;
    }
}
