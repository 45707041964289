import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { Ouvrage } from './ouvrage.model';
import { Commune } from '../commune/commune.model';
import { DateUtils } from 'src/app/shared/model/date-utils';

@Injectable()
export class OuvrageService {

    private resourceUrl =  SERVER_API_URL + 'api/ouvrages';
    private resourceDecUrl =  SERVER_API_URL + 'api/declarations';
    private currentUserResourceUrl =  SERVER_API_URL + 'api/currentuser/ouvrages';
    private instructorResourceUrl =  SERVER_API_URL + 'api/instructor/ouvrages';

    private _ouvragesSource = new Subject<Ouvrage[]>();
    private _ouvrageSource = new Subject<Ouvrage>();

    public ouvrages$ = this._ouvragesSource.asObservable();
    public ouvrage$ = this._ouvrageSource.asObservable();


    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    create(ouvrage: Ouvrage): Observable<Ouvrage> {
        const copy = this.convert(ouvrage);
        return this.http.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    update(ouvrage: Ouvrage): Observable<Ouvrage> {
        const copy = this.convert(ouvrage);
        return this.http.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    find(id: number): Observable<Ouvrage> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findByDeclaration(id: string): Observable<Ouvrage[]> {  
        return this.http.get(`${this.resourceDecUrl}/${id}/ouvrages`).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    currentUser(req?: any): Observable<Ouvrage[]> {  
        return this.http.get(`${this.currentUserResourceUrl}`, {params: req}).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    instructor(req?: any): Observable<Ouvrage[]> {  
        return this.http.get(`${this.instructorResourceUrl}`, {params: req}).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    findCommunesByDeclaration(id: number): Observable<Commune[]> {
        return this.http.get(`${this.resourceDecUrl}/${id}/communes`).pipe(map((res) => {
            return this.convertCommunesResponse(res);
        }));
    }

    findZonesByDeclaration(id: number): Observable<string[]> {
        return this.http.get(`${this.resourceDecUrl}/${id}/zones`).pipe(map((res) => {
            return this.convertStringResponse(res);
        }));
    }

    query(req?: any): Observable<Ouvrage[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    private convertResponse(res): Ouvrage[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        this._ouvragesSource.next(result);
        return result;
    }

    private convertCommunesResponse(res): Commune[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(res[i]);
        }
        return result;
    }

    private convertStringResponse(res): string[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(res[i]);
        }
        return result;
    }

    private convertRequest(req): Ouvrage[] {
        const request = [];
        for (let i = 0; i < req.length; i++) {
            request.push(this.convert(req[i]));
        }
        return request;
    }

    /**
     * Convert a returned JSON object to Ouvrage.
     */
    private convertItemFromServer(json: any): Ouvrage {
        const entity: Ouvrage = Object.assign(new Ouvrage(), json);
        if(json.dateCreation)
            entity.dateCreation = this.dateUtils
                .convertLocalDateToServer(json.dateCreation);
        if(json.dateModification)
            entity.dateModification = this.dateUtils
                .convertLocalDateToServer(json.dateModification);
        this._ouvrageSource.next(entity);
        return entity;
    }

    /**
     * Convert a Ouvrage to a JSON which can be sent to the server.
     */
    private convert(ouvrage: Ouvrage): Ouvrage {
        const copy: Ouvrage = Object.assign({}, ouvrage);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(ouvrage.dateCreation);
        copy.dateModification = this.dateUtils
            .convertLocalDateToServer(ouvrage.dateModification);
        return copy;
    }
}
