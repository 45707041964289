<div class="row form-row">
    <label class="col-md-5 form-control-label" for="field_nom">Nom</label>
</div><div class="row form-group form-row">
    <input type="text" class="col-md-8 form-control" name="nom" id="field_nom"
        [(ngModel)]="contact.nom" maxlength="50"/>
</div>
<div class="row form-row">
        <label class="col-md-5 form-control-label" for="field_prenom">Prénom</label>
    </div><div class="row form-group form-row">
        <input type="text" class="col-md-8 form-control" name="prenom" id="field_prenom"
            [(ngModel)]="contact.prenom" maxlength="50"/>
</div>

<div class="row form-row">
        <label class="col-md-5 form-control-label" for="field_tel1">Téléphone fixe</label>
    </div><div class="row form-group form-row">
        <input type="text" class="col-md-8 form-control" name="tel1" id="field_tel1"
            [(ngModel)]="contact.tel1" minlength="10" maxlength="10"/>
</div>

<div class="row form-row">
        <label class="col-md-5 form-control-label" for="field_tel2">Téléphone portable</label>
    </div><div class="row form-group form-row">
        <input type="text" class="col-md-8 form-control" name="tel2" id="field_tel2"
            [(ngModel)]="contact.tel2" minlength="10" maxlength="10"/>
</div>
