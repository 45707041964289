import { Routes } from '@angular/router';
import { DeclarationComponent } from './declaration.component';
import { UserRouteAccessService } from 'src/app/shared/auth/user-route-access-service';

export const declarationRoute:Routes = [
    {
        path: 'declaration/:id',
        component: DeclarationComponent,
        data: {
            authorities: ['ROLE_USER'],
            pageTitle: 'Déclaration'
        },
        canActivate: [UserRouteAccessService]
    }
]