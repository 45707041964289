import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DepartementService } from './departement.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';

@NgModule({
    imports: [
        DuplosSharedModule,
    ],
    declarations: [],
    providers: [
        DepartementService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosDepartementModule {}