import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { LexProfilUtilisateur } from './lex-profil-utilisateur.model';
import { DateUtils } from 'src/app/shared/model/date-utils';


@Injectable()
export class LexProfilUtilisateurService {

    private resourceUrl =  SERVER_API_URL + 'api/lex-profil-utilisateurs';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    find(id: number): Observable<LexProfilUtilisateur> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<LexProfilUtilisateur[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    findAll(): Observable<LexProfilUtilisateur[]> {
        return this.http.get(this.resourceUrl)
            .pipe(map((res) => this.convertResponse(res)));
    }

    private convertResponse(res): LexProfilUtilisateur[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to LexProfilUtilisateur.
     */
    private convertItemFromServer(json: any): LexProfilUtilisateur {
        const entity: LexProfilUtilisateur = Object.assign(new LexProfilUtilisateur(), json);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateModification = this.dateUtils
            .convertLocalDateFromServer(json.dateModification);
        return entity;
    }

    /**
     * Convert a LexProfilUtilisateur to a JSON which can be sent to the server.
     */
    private convert(lexProfilUtilisateur: LexProfilUtilisateur): LexProfilUtilisateur {
        const copy: LexProfilUtilisateur = Object.assign({}, lexProfilUtilisateur);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(lexProfilUtilisateur.dateCreation);
        copy.dateModification = this.dateUtils
            .convertLocalDateToServer(lexProfilUtilisateur.dateModification);
        return copy;
    }
}
