import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressBarModule } from 'primeng/progressbar';
import { CalendarModule } from 'primeng/calendar';
import {PanelModule} from 'primeng/panel';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SidebarModule} from 'primeng/sidebar';
import {CardModule} from 'primeng/card';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

import { formsState, ouvragePopupRoute, acteurPopupRoute, aquiferePopupRoute, ouvrageUpdateFormRoute, declarantIdentificationRoute } from './forms.route';
import { ActeurFormComponent } from './acteurform/acteurform.component';
import { AdresseFormComponent } from './adresseform/adresseform.component';
import { ContactFormComponent } from './contactform/contactform.component';
import { HierarchicalSelectComponent } from './hierarchical-select/hierarchical-select.component';
import { CaracOuvrageFormComponent, CaracOuvragePopupComponent } from './caracOuvrageForm/caracouvrageform.component';
import { LocalisationFormComponent, LocalisationPopupComponent } from './localisationform/localisationform.component';
import { AquifereFormComponent, AquiferePopupComponent } from './aquifereform/aquifereform.component';
import { OlMapComponent } from './ouvrageform/map/ol-map.component';
import { ForeurFormComponent } from './foreurform/foreurform.component';
import { DeclarantComponent } from './declarantForm/declarant.component';
import { SettingsComponent } from './accountForm/settings.component';
import { DuplosSharedModule } from '../shared/shared.module';
import { CreationDeclarationFormComponent } from './creationDeclarationForm/creationdeclarationform.component';
import { DeclarationFormComponent } from './declarationform/declarationform.component';
import { ActeurPopupComponent } from './acteurform/acteur-popup.component';
import { OuvrageFormComponent } from './ouvrageform/ouvrageform.component';

const ENTITY_STATES = [
    ...formsState,
    ...declarantIdentificationRoute,
    ...ouvragePopupRoute,
    ...acteurPopupRoute,
    ...aquiferePopupRoute,
    ...ouvrageUpdateFormRoute
];

@NgModule({
    imports: [
        DuplosSharedModule,
        TableModule, SidebarModule,
        FileUploadModule, ScrollPanelModule,
        ProgressBarModule, PanelModule,
        NgSelectModule, FormsModule,
        CalendarModule, CardModule,
        RouterModule.forChild(ENTITY_STATES),
        BrowserModule,
        HttpClientModule
    ],
    declarations: [
        CreationDeclarationFormComponent,
        DeclarationFormComponent,
        ActeurFormComponent,
        ContactFormComponent,
        AdresseFormComponent,
        OuvrageFormComponent,
        CaracOuvrageFormComponent,
        CaracOuvragePopupComponent,
        AquifereFormComponent,
        AquiferePopupComponent,
        LocalisationFormComponent,
        LocalisationPopupComponent,
        HierarchicalSelectComponent,
        ActeurPopupComponent,
        OlMapComponent,
        ForeurFormComponent,
        DeclarantComponent,
        SettingsComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosDeclarationFormModule {}
