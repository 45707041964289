import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AdresseService } from './adresse.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';


@NgModule({
    imports: [
        DuplosSharedModule
    ],
    declarations: [],
    providers: [
        AdresseService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosAdresseModule {}
