import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Region } from './region.model';
import { SERVER_API_URL } from 'src/app/app.constants';

@Injectable()
export class RegionService {

    private resourceUrl =  SERVER_API_URL + 'api/regions';

    constructor(private http: HttpClient) { }

    find(id: string): Observable<Region> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<Region[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    private convertResponse(res): Region[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to Region.
     */
    private convertItemFromServer(json: any): Region {
        const entity: Region = Object.assign(new Region(), json);
        return entity;
    }
}