import { MonocomptePopupComponent } from './../../shared/popup/monocompte-popup.component';
import { Component, OnInit, PipeTransform, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Utilisateur } from '../../entities/utilisateur/utilisateur.model';
import { Router } from '@angular/router';
import { Principal } from 'src/app/shared/auth/principal.service';

import { NgbCarouselConfig, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import {DialogModule} from 'primeng/dialog';
import { Actualite } from 'src/app/shared/model/actualite';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'dup-home',
    templateUrl: './home.component.html',
    styleUrls: [
        'home.css'
    ],
    encapsulation: ViewEncapsulation.None,
})

export class HomeComponent implements OnInit {
    
    account: Utilisateur;

    isCollapsed:Boolean = true;
    typeReglementation = 'MIN';

    lstActualite: Actualite[];
    currentActu: Actualite;

    displayBasic: boolean;

    constructor(
        private principal: Principal,
        private router: Router,
        private modalService: NgbModal,
        config: NgbCarouselConfig
    ) {
        this.lstActualite = [];
        this.currentActu = new Actualite(0,"","","");

        /*this.lstActualite.push(
            new Actualite(1,
                "Que pensez-vous du portail DUPLOS ?",
                "Le portail DUPLOS a connu en 3 années d'existence une évolution importante et un déploiement national. Afin de rester toujours au plus proche des attentes des utilisateurs et anticiper de futurs développements, nous souhaitons <strong>recueillir votre avis</strong> sur votre usage du portail. Rendez-vous <a href=\"https://forms.office.com/e/W1cU65EYsm\">ici</a> pour donner votre avis ! Vous avez <strong>jusqu'au 30 juin</strong> pour vous exprimer.",
                "/assets/images/caroussel/logo_sondage.jpg",
                "Mai 2024"
            )
        )*/

        this.lstActualite.push(
            new Actualite(1,
                "Domestiquez vos forages !",
                "A partir du 1er février 2024, DUPLOS vous permettra de réaliser vos démarches de déclaration des forages domestiques et de forages « code minier » sous une seule et même interface ! Plus besoin de dédoubler les déclarations, le Cerfa n°13837*02 sera automatiquement renseigné à l'issue du processus en ligne et envoyé directement en mairie et aux services de l'état compétents (DDT, D(R)EAL, ARS).",
                "/assets/images/caroussel/actu_domestiquez_forage.png",
                "15 janvier 2024"
            )
        )

        this.lstActualite.push(
            new Actualite(2,
                "Attribution des codes BSS",
                "Très prochainement, le code BSS pour chaque ouvrage souterrain réalisé sera généré au moment du dépôt de votre Rapport de Fin de Forage (RFF) et non plus au moment de la transmission de la déclaration. L'utilisation de l'outil DIALOG pour réaliser votre RFF et finaliser votre déclaration inclura automatiquement le ou les codes BSS dans votre rapport. Dans le cas où vous finaliseriez votre déclaration en déposant votre RFF sous forme de pièce jointe dans DUPLOS, le ou les codes BSS seront visibles dans le tableau de bord DUPLOS.",
                "/assets/images/caroussel/actu_code_bss.png",
                "20 Novembre 2023"
            )
        )

        this.lstActualite.push(
            new Actualite(3,
                "DUPLOS fait peau neuve !",
                "Il ne vous aura pas échappé que la page d'accueil de DUPLOS s'est transformée, pour un design plus moderne et ergonomique. Cette métamorphose n'est que la première étape de transformation du site, qui s'institutionnalisera par la modification de son adresse (url) et accueillera prochainement les déclarations de forages domestiques. A suivre !",
                "/assets/images/caroussel/Nouveau.jpg",
                "Septembre 2023"
            )
        )

        /*this.lstActualite.push(
            new Actualite(3,
                "DUPLOS à Mayotte !",
                "Mayotte déploie DUPLOS sur son territoire à partir du 1er octobre !<br><br>Exceptionnellement, ce sera un <b>séminaire en local</b> qui sera organisé pour présenter les outils DUPLOS et DiaLog le <b>26 septembre dans les locaux de la DEALM.</>",
                "/assets/images/Mayotte-satel.png",
                "Septembre 2023"
            )
        )*/

        /*this.lstActualite.push(
            new Actualite(4,
                "Dialog s'assouplit !",
                "Dans la description géologique, la colonne \" constituant \" est désormais facultative et c'est la colonne \" Description \" (anciennement \" Commentaire \") qui devient obligatoire : la saisie des descriptions géologiques redevient plus naturelle. Couplée à ce changement, une nouvelle fonctionnalité est apparue : si le premier mot de votre commentaire saisi est un terme du lexique lithologique BRGM, alors la colonne \" Constituant \" se remplira automatiquement ! Par exemple, si vous saisissez \" Calcaire beige friable \" dans le commentaire, alors \" calcaire \" apparaîtra automatiquement à côté. On rappel que pour obtenir une coupe géologique visuelle, les constituants restent nécessaires. Nous vous rappelons d'ailleurs que DiaLog vous permet, outre l'élaboration de coupes techniques et géologiques, de générer en ligne votre rapport de fin de forage, incluant d'éventuelles pièces jointes complémentaires. Nous vous encourageons à l'utiliser, cela facilitera aussi le travail des correspondants BSS en charge d'instruire les dossiers BSS.",
                "/assets/images/Carottes-brgm.png",
                "Juin 2023"
            )
        );*/

    }

    ngOnInit() {
        //Récupération de la variable de session typeDeclaration. Affichage des éléments du tableau de bord en fonction de cette variable
        if (sessionStorage.getItem('typeReglementation') !== null)
            this.typeReglementation = sessionStorage.getItem('typeReglementation');
        else
        this.typeReglementation = 'MIN';
    }

    showBasicDialog(actu: Actualite) {
        this.currentActu = actu;
        this.displayBasic = true;
    }


    isAuthenticated() {
        if(this.principal.isAuthenticated() && this.account == undefined) {
            this.principal.identity().then((account) => {
                this.account = account;
            });
        }
        return this.principal.isAuthenticated();
    }

    hasAccount() {
        return this.account != undefined && this.account.adresseId != null && this.account.adresseId != undefined && this.account.firstLogin == false ;
    }

    login(typeReglementation) {
        // Stockage de la variable de session typeDeclaration. Elle definira le type de tableau de bord / déclaration à afficher
        sessionStorage.setItem('typeReglementation', typeReglementation);
        window.location.replace('/back/');
    }

    createAccount() {
        this.router.navigate(['/settings',{}]);
    }

    create() {
      const modalRef = this.modalService.open(MonocomptePopupComponent, { size: 'lg', backdrop: 'static', centered: true });
      modalRef.result.then((result) => {
       }, (reason) => {});
    }

      // Fonction pour ouvrir la popup
    ouvrirPopup() {
        var popup = document.getElementById("maPopup");
        popup.style.display = "block";
    }

    // Fonction pour fermer la popup
    fermerPopup() {
        var popup = document.getElementById("maPopup");
        popup.style.display = "none";
    }
}
