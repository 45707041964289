import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Utilisateur } from './utilisateur.model';
import { UtilisateurService } from './utilisateur.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { DuplosEventManager } from 'src/app/shared/event/event.service';
import { Principal } from 'src/app/shared/auth/principal.service';
import { ITEMS_PER_PAGE } from 'src/app/shared/constants/pagination.constants';
import { LexProfilUtilisateur } from '../lex-profil-utilisateur/lex-profil-utilisateur.model';
import { LexProfilUtilisateurService } from '../lex-profil-utilisateur/lex-profil-utilisateur.service';
import * as _ from 'lodash';
import { RegionService } from '../region/region.service';
import { DepartementService } from '../departement/departement.service';
import { ZoneSelect } from './zoneSelect'
import { mapToStyles } from '@popperjs/core/lib/modifiers/computeStyles';
import { DateUtils } from 'src/app/shared/model/date-utils';
import { saveAs } from "file-saver";

@Component({
    selector: 'dup-utilisateur',
    templateUrl: './utilisateur.component.html',
    styleUrls: [
        'utilisateur.css'
      ],
})
export class UtilisateurComponent implements OnInit, OnDestroy {
    utilisateurs: Utilisateur[];
    currentAccount: any;
    eventSubscriber: Subscription;
    lexprofilutilisateurs: LexProfilUtilisateur[];
    lexzones: ZoneSelect[];

    filterIdentifiant : string;
    filterProfils: number[];
    filterMinDate: Date;
    filterMaxDate: Date;
    filterZones: ZoneSelect[];

    isLoading: boolean;
    

    itemsPerPage: any;
    totalUtilisateurs:number;
    page:any;
    sortColumn:string;
    sortOrder:string;

    constructor(
        private utilisateurService: UtilisateurService,
        private jhiAlertService: AlertService,
        private eventManager: DuplosEventManager,
        private principal: Principal,
        private lexprofilutilisateurService: LexProfilUtilisateurService,
        private regionService: RegionService,
        private departementService: DepartementService,
        private dateUtils: DateUtils
    ) {
        this.isLoading = false;
        this.page = 0;
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.sortColumn = "id";
        this.sortOrder = "DESC";
        this.filterProfils = [];
        this.filterZones = [];
        this.filterIdentifiant = "";

        this.lexprofilutilisateurService.findAll().subscribe( res => { this.lexprofilutilisateurs = res})

        this.lexzones = [];
        this.regionService.query().subscribe( res=> { 
            res.forEach(e => {this.lexzones.push(new ZoneSelect(e.nomReg, e.inseeReg, null))} );
         } );
        this.departementService.query().subscribe( res=> { 
            res.forEach(e=> { this.lexzones.push(new ZoneSelect(e.nomDep, null, e.inseeDep)) });
        } );
        
        
    }

    filtrerIdentifiant(){
        this.page = 0;
        this.loadAll();
    }

    filtrerProfils(value:any) {
        if (value.target.checked && !this.filterProfils.includes(value.target.value)) {
          this.filterProfils.push(value.target.value)
        }else {
          this.filterProfils.splice(this.filterProfils.indexOf(value.target.value), 1)
        }
        this.page = 0;
        this.loadAll();
      }

      filtrerZones(selectedZones:ZoneSelect[]) {
        this.filterZones = selectedZones;
        this.page = 0;
        this.loadAll();
      }

      filtrerDate(){
        this.page = 0;
        this.loadAll();
      }

    loadUtilisateursLazy(event) {
        this.page = event.first/this.itemsPerPage;
        if(event.sortField)
            this.sortColumn = event.sortField;
        if(event.sortOrder == 1)
        {
            this.sortOrder = "ASC";
        }
        else {
            this.sortOrder = "DESC";
        }
        this.loadAll();
    }

    loadAll() {
        this.utilisateurService.query({
            page: this.page,
            size: this.itemsPerPage,
            sort: this.sortColumn.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`) + "," + this.sortOrder,
            profilUtilisateurIds: _.join(this.filterProfils,','),
            identifiant: this.filterIdentifiant,
            depInseeIds: _.join(this.filterZones.map(z=>z.depInseeId).filter(Boolean) ,','),
            regionInseeIds: _.join(this.filterZones.map(z=>z.regionInseeId).filter(Boolean) ,','),
            startDate: this.dateUtils.convertLocalDateToServer(this.filterMinDate),
            endDate: this.dateUtils.convertLocalDateToServer(this.filterMaxDate)
          }).subscribe(
            (res) => {
                this.utilisateurs = res.utilisateurs;
                this.totalUtilisateurs = res.maxUtilisateur;
                this.utilisateurs.forEach((utilisateur) => {
                    utilisateur.zones = [];
                    this.utilisateurService.getZones(utilisateur.id).subscribe(zones => {
                        zones.forEach((zone)=> {
                            if(zone.regionNomReg)
                                utilisateur.zones.push(zone.regionNomReg);
                            if(zone.departementNomDep)
                                utilisateur.zones.push(zone.departementNomDep);
                        });
                    });
                })
            },
            (res) => this.onError(res)
        );
    }
    ngOnInit() {
        this.loadAll();
        this.principal.identity().then((account) => {
            this.currentAccount = account;
        });
        this.registerChangeInUtilisateurs();
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: Utilisateur) {
        return item.id;
    }
    registerChangeInUtilisateurs() {
        this.eventSubscriber = this.eventManager.subscribe('utilisateurListModification', (response) => this.loadAll());
    }

    export(){
        this.isLoading = true;
        this.utilisateurService.export({
            profilUtilisateurIds: _.join(this.filterProfils,','),
            identifiant: this.filterIdentifiant,
            depInseeIds: _.join(this.filterZones.map(z=>z.depInseeId).filter(Boolean) ,','),
            regionInseeIds: _.join(this.filterZones.map(z=>z.regionInseeId).filter(Boolean) ,','),
            startDate: this.dateUtils.convertLocalDateToServer(this.filterMinDate),
            endDate: this.dateUtils.convertLocalDateToServer(this.filterMaxDate)
          }).subscribe(
            (res) => {
                var newBlob = new Blob([res], { type: "application/csv" });
                saveAs(newBlob, "export.csv");
                this.isLoading = false;
                this.utilisateurService.deleteExport().subscribe(res => {});
            },
            (res) => {this.onError(res); this.isLoading = false;}
        );

    }

    private onError(error) {
        this.jhiAlertService.error(error.message, null, null);
    }
}
