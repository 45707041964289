import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { LexTypeDeclaration } from './lex-type-declaration.model';
import { DateUtils } from 'src/app/shared/model/date-utils';


@Injectable()
export class LexTypeDeclarationService {

    private resourceUrl =  SERVER_API_URL + 'api/lex-type-declarations';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    find(id: number): Observable<LexTypeDeclaration> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<LexTypeDeclaration[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    private convertResponse(res): LexTypeDeclaration[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to LexTypeDeclaration.
     */
    private convertItemFromServer(json: any): LexTypeDeclaration {
        const entity: LexTypeDeclaration = Object.assign(new LexTypeDeclaration(), json);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateModification = this.dateUtils
            .convertLocalDateFromServer(json.dateModification);
        return entity;
    }

    /**
     * Convert a LexTypeDeclaration to a JSON which can be sent to the server.
     */
    private convert(lexTypeDeclaration: LexTypeDeclaration): LexTypeDeclaration {
        const copy: LexTypeDeclaration = Object.assign({}, lexTypeDeclaration);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(lexTypeDeclaration.dateCreation);
        copy.dateModification = this.dateUtils
            .convertLocalDateToServer(lexTypeDeclaration.dateModification);
        return copy;
    }
}
