import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { LexUnite } from './lex-unite.model';
import { DateUtils } from 'src/app/shared/model/date-utils';


@Injectable()
export class LexUniteService {

    private resourceUrl =  SERVER_API_URL + 'api/lex-unites';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    find(id: number): Observable<LexUnite> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<LexUnite[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    private convertResponse(res): LexUnite[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to LexUnite.
     */
    private convertItemFromServer(json: any): LexUnite {
        const entity: LexUnite = Object.assign(new LexUnite(), json);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateModificaton = this.dateUtils
            .convertLocalDateFromServer(json.dateModificaton);
        return entity;
    }

    /**
     * Convert a LexUnite to a JSON which can be sent to the server.
     */
    private convert(lexUnite: LexUnite): LexUnite {
        const copy: LexUnite = Object.assign({}, lexUnite);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(lexUnite.dateCreation);
        copy.dateModificaton = this.dateUtils
            .convertLocalDateToServer(lexUnite.dateModificaton);
        return copy;
    }
}
