export class LexProfilReglementation {
    constructor(
        public id?: number,
        public valide?: boolean,
        public dateCreation?: any,
        public dateModification?: any,
        public profilUtilisateurId?: number,
        public reglementationId?: number,
    ) {
        this.valide = false;
    }
}
