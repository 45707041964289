import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LexUsageService } from './lex-usage.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';


@NgModule({
    imports: [
        DuplosSharedModule,
    ],
    declarations: [],
    providers: [
        LexUsageService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosLexUsageModule {}
