import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DATA, MENTIONS } from './home';
import { errorRoute, navbarRoute } from './layouts';

const LAYOUT_ROUTES = [
    navbarRoute,
    DATA,
    MENTIONS,
    ...errorRoute
];

@NgModule({
    imports: [
        RouterModule.forRoot(LAYOUT_ROUTES, { useHash: true, onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})
export class DuplosAppRoutingModule {}
