import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, timeout } from 'rxjs/operators';
import { SERVER_API_URL } from 'src/app/app.constants';


@Injectable({ providedIn: 'root'})
export class ApiService {

    codeIGN;
    private resourceUrl =  SERVER_API_URL + 'api/ign';

    constructor(private http: HttpClient){
        this.getCodeIGN();
    }

    getCodeIGN() {
        this.http.get(this.resourceUrl,{responseType: 'text'}).subscribe((res)=>{
            this.codeIGN=res;
        });
    }

    /**
     * Utilise l'API adresse.data.gouv pour récupérer l'adresse du point à partir des coordonnées X/Y en long/lat
     * @param lon
     * @param lat
     */
    getAdresseByCoordinate(lon: number, lat: number) {
        return this.http.get('https://api-adresse.data.gouv.fr/reverse/?lon=' + lon + '&lat=' + lat);
    }

    /**
     * Utilise l'API adresse.data.gouv pour récupérer l'adresse du point à partir des coordonnées X/Y en long/lat
     * @param lon
     * @param lat
     */
    getAdresseByName(address:string) {
        return this.http.get('https://api-adresse.data.gouv.fr/search/?q='+address);
    }

    getCadastreByCoordinates(lon: number, lat: number) {
        return this.http.get('https://apicarto.ign.fr/api/cadastre/parcelle?apikey='+this.codeIGN+'&source_ign=PCI&geom=%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%20%5B'+ lon + '%2C%20' + lat + '%5D%7D').pipe(timeout(2000));
    }

    getZByCoordinates(lon:number, lat:number) {
        return this.http.get('https://data.geopf.fr/altimetrie/1.0/calcul/alti/rest/elevation.json?lon='+lon+'&lat='+lat+'&resource=ign_rge_alti_wld&zonly=true').pipe(timeout(2000));
    }

    getActeurBySiret(siret:string) {
        //return this.http.get('https://entreprise.data.gouv.fr/api/sirene/v3/etablissements/'+siret);
        return this.http.get('https://api.insee.fr/entreprises/sirene/V3/siret/'+ siret, 
        {headers: {
            'Content-Type':  'application/json',
            'Authorization': 'Bearer 714936dd-6631-3cb4-8f91-39c7f08d1e9d'}});
    }

    getCadastreLayer(codeCommune:string) {
        return this.http.get("https://cadastre.data.gouv.fr/bundler/cadastre-etalab/communes/"+codeCommune+"/geojson/parcelles");
    }

    getArrondissements(codeDep:string) {
        return this.http.get('https://data.opendatasoft.com/api/records/1.0/search/?dataset=arrondissements-millesimes0%40public&q='+codeDep+'&facet=code_dpartement');
    }

    getMairieMail(cp: string) {
        return this.http.get('https://www.service-public.fr/geo/rechercheJson?_csrf=ce958ab5-fea1-4a53-a1ab-058665760e46&idFiche=F172&pivotLocalList=mairie&pivotLocalList=dreal_ut&pivotLocalServiceEnLigne=&insee=' + cp);
    }
}
