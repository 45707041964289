export class Contact {
    constructor(
        public id?: number,
        public civilite?: string,
        public nom?: string,
        public prenom?: string,
        public tel1?: string,
        public tel2?: string,
        public auteurCreation?:string,
        public auteurModification?:string,
        public dateCreation?: any,
        public dateModification?: any,
        public emailContact?: string,
        public acceptNotif? : boolean
    ) {
    }
}
