import { Component, OnInit, Input, ViewChildren, QueryList, OnChanges, SimpleChanges } from '@angular/core';

import { Acteur } from '../../entities/acteur/acteur.model';
import { Contact } from 'src/app/entities/contact/contact.model';
import { Adresse } from 'src/app/entities/adresse/adresse.model';
import { ActeurService } from 'src/app/entities/acteur/acteur.service';
import { AdresseService } from 'src/app/entities/adresse/adresse.service';
import { ContactService } from 'src/app/entities/contact/contact.service';
import { ApiService } from 'src/app/shared/service/api.service';
import { CommuneService } from 'src/app/entities/commune/commune.service';


import * as _ from 'lodash';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { LexStatutJuridique } from 'src/app/entities/lex-statut-juridique/lex-statut-juridique.model';
import { LexStatutJuridiqueService } from 'src/app/entities/lex-statut-juridique/lex-statut-juridique.service';


@Component({
  selector: 'dup-acteur-form',
  templateUrl: './acteurform.component.html',
  styleUrls: [
    '../forms.css'
  ]
})
export class ActeurFormComponent implements OnInit, OnChanges {

  // acteur en cours
  @Input() acteur: Acteur;

  @Input() facultatif:Boolean;

  acteursSiret: Acteur[];
  // lexiques
  lexstatutjuridiques: LexStatutJuridique[];
  lexstatutjuridique: LexStatutJuridique;

  // acteur professionel
  isPro: boolean = true;
  isValidEmail: boolean = false;

  constructor(
    private lexStatutJuridiqueService: LexStatutJuridiqueService,
    private acteurService:ActeurService,
    private contactService:ContactService,
    private adresseService:AdresseService,
    private ouvrageDeclaration:ApiService,
    private communeService:CommuneService,
    private alertService: AlertService,
  ) {
    this.acteur = new Acteur();
    this.acteursSiret = [];
  }

  ngOnInit() {
    this.lexStatutJuridiqueService.query()
      .subscribe((res) => {
        this.lexstatutjuridiques = res;
        this.updateStatutJuridique();
      }, (res) => this.onError(res));
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if(propName == 'acteur' && this.lexstatutjuridiques)
      {
        this.updateStatutJuridique();
      }
    }
  }

  updateStatutJuridique(): void {
    if(this.acteur?.statutJuridiqueId) {
      this.lexstatutjuridique = _.find(this.lexstatutjuridiques,['id',this.acteur.statutJuridiqueId]);
      this.isPro = this.lexstatutjuridique.mnemo === 'PRO';
      this.isValidEmail = this.validEmail();
    }
  }

  // gestion des erreurs de communication
  private onError(error: any) {
    this.alertService.error(error.message, null, null);
  }

  findBySiret() {
    this.acteur.siretEtab = this.acteur.siretEtab.trim();
    this.acteur.siretEtab = this.acteur.siretEtab.replace(/ /g,"");
      
    this.acteurService.getActeurBySiret(this.acteur.siretEtab).subscribe((res:any) => {

      let result = res.results[0];
      this.lexstatutjuridique = _.find(this.lexstatutjuridiques,['code','2']);
      this.isPro = this.lexstatutjuridique.mnemo === 'PRO';
      let adresse:Adresse = new Adresse();
      this.acteur.id = null;
      this.acteur.contactId = null;
      this.acteur.contact = new Contact();
      this.acteur.adresseId = null;
      this.acteur.statutJuridiqueId = this.lexstatutjuridique.id;
      this.acteur.raisonSocialeEtab = result.nom_raison_sociale;

      adresse.nomVoie = result.siege.numero_voie + " " + result.siege.type_voie + " " + result.siege.libelle_voie;
      adresse.codePostal = result.siege.code_postal;
      adresse.pays = 'France';
      adresse.communeInseeCom = result.siege.commune;
      adresse.cedex = result.siege.cedex;
      adresse.complement = result.siege.complement_adresse;
      
      this.communeService.find(adresse.communeInseeCom).subscribe((commune) => {
          adresse.commune = commune;
          this.acteur.adresse = adresse;
      });
    });
  }

  checkActeur() {
    if(this.acteursSiret.length > 0) {
      let acteur = _.find(this.acteursSiret,['email',this.acteur.email]);
      if(acteur) {
        Object.assign(this.acteur, acteur);
      }
    } 
  }

  /*findByMail() {
    this.acteurService.findByMail(this.acteur.email).subscribe(res => {
      if(res && res.id) {
        Object.assign(this.acteur, res);
        if(this.acteur) {
          if(this.acteur.statutJuridiqueId)
          {
            this.lexstatutjuridique = _.find(this.lexstatutjuridiques,['id',this.acteur.statutJuridiqueId]);
            this.isPro = this.lexstatutjuridique.mnemo === 'PRO';
          }
          if(this.acteur.adresseId) this.adresseService.find(this.acteur.adresseId).subscribe((adresse) => this.acteur.adresse = adresse);
          if(this.acteur.contactId) this.contactService.find(this.acteur.contactId).subscribe((contact) => this.acteur.contact = contact);
        }
      }
    });
  }*/

  statutJuridiqueChange(event) {
    this.isPro = event.mnemo === 'PRO';
    this.acteur.statutJuridiqueId = this.lexstatutjuridique.id;
  }


  validEmail(){
    if(this.acteur && this.acteur != undefined){
    const regex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$', 'g');
                              
    this.isValidEmail = this.acteur.email.match(regex) != null
    return this.isValidEmail;
    }
    return false;
  }
}