import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Adresse } from '../../entities/adresse/adresse.model';
import { Region } from 'src/app/entities/region/region.model';
import { Departement } from 'src/app/entities/departement/departement.model';
import { Commune } from 'src/app/entities/commune/commune.model';
import { RegionService } from 'src/app/entities/region/region.service';
import { DepartementService } from 'src/app/entities/departement/departement.service';
import { CommuneService } from 'src/app/entities/commune/commune.service';
import * as _ from 'lodash';

@Component({
  selector: 'dup-adresse-form',
  templateUrl: './adresseform.component.html',
  styleUrls: [
    '../forms.css'
  ]
})
export class AdresseFormComponent implements OnInit, OnChanges {

  @Input() isPro: boolean;
  @Input() adresse: Adresse;

  @Input() isOuvrage: boolean;

  region: Region;
  departement: Departement;
  commune: Commune;

  regions: Region[];
  departements: Departement[];
  communes: Commune[];

  communeLoading: boolean = false;
  isFrance: boolean = true;

  constructor(
    private regionService: RegionService,
    private departementService: DepartementService,
    private communeService: CommuneService,
  ) {
    this.adresse = new Adresse();
  }

  ngOnInit() {
    this.regionService.query().subscribe((reg) => {
      this.regions = reg;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {  
      if(propName == 'adresse')
      {
        if(!this.adresse.pays) {
          this.changeToFrance();
        }
        if(this.adresse.pays.toLowerCase() != "france") this.isFrance = false;
        if(this.adresse?.communeInseeCom) {
          this.communeService.find(this.adresse.communeInseeCom).subscribe((commune) => 
          {
            this.commune = commune;
            this.adresse.commune = commune;
            this.region = _.find(this.regions, {'inseeReg': this.commune.inseeReg});
            this.onSelectRegion(null);
          }); 
        } else {
          this.commune = null;
          this.departement = null;
          this.region = null;
        }
      }
    }
  }

  public onSelectRegion(event) {
    if(this.region) {
      this.departementService.findByRegion(this.region.inseeReg).subscribe((dep) => {
          this.departements = dep;
          if(event == null) {
              this.departement = _.find(this.departements, {'inseeDep': this.commune.inseeDep});
              this.onSelectDepartement(null);
          }
      });
    }
  }

  public onSelectDepartement(event) {
    if(this.departement) {
      this.communeLoading = true;
      this.communeService.findByDepartement(this.departement.inseeDep).subscribe((com) => {
          this.communes = com;
          this.communeLoading = false;
          if(event == null) {
            this.commune = _.find(this.communes, {'inseeCom': this.commune.inseeCom})
          }
      });
    }
  }

  public onSelectCommune(event) {
      if(this.commune) {
          this.adresse.communeInseeCom = this.commune.inseeCom;
          this.adresse.commune = this.commune;
      }
  }

  displayBPCedex() {
    return this.isPro && this.isFrance;
  }

  changeToFrance() {
    this.adresse.pays = "France";
    //this.isFrance = true;
  }

  findCity(event) {
    this.communeService.findByCp(this.adresse.codePostal).subscribe((communes) => 
    {
      this.communes = communes;
      if(this.communes.length == 1)
      {
        this.commune = this.communes[0];
        this.adresse.commune = this.commune;
        this.adresse.communeInseeCom = this.commune.inseeCom;
        this.adresse.communeNomCom = this.commune.nomCom;
      }

      this.region = _.find(this.regions, {'inseeReg': this.communes[0].inseeReg});
      this.departementService.findByRegion(this.region.inseeReg).subscribe((dep) => {
        this.departements = dep;
        this.departement = _.find(this.departements, {'inseeDep': this.communes[0].inseeDep});
      });
    });
  }
}
