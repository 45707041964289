import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { Declaration } from './declaration.model';
import { ListDeclaration } from './listdeclaration.model';
import { DateUtils } from 'src/app/shared/model/date-utils';

@Injectable()
export class DeclarationService {

    private resourceUrl =  SERVER_API_URL + 'api/declarations';
    private exportUrl =  SERVER_API_URL + 'api/export/declarations';
    private cerfaUrl =  SERVER_API_URL + 'api/cerfa';
    private currentUserResourceUrl =  SERVER_API_URL + 'api/currentuser/declarations';
    private instructorResourceUrl =  SERVER_API_URL + 'api/instructor/declarations';
    idReglementation = '3850';
    
    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    create(declaration: Declaration): Observable<Declaration> {
        const copy = this.convert(declaration);
        return this.http.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    update(declaration: Declaration): Observable<Declaration> {
        const copy = this.convert(declaration);
        return this.http.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    find(id: number): Observable<Declaration> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findAdministrations(id: number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/${id}/administrations`);
    }

    download(id:number):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.get(`${this.resourceUrl}/${id}/dl`, { headers: headers, responseType: 'blob' });
    }

    cerfa(id:number):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.get(`${this.cerfaUrl}/${id}/dl`, { headers: headers, responseType: 'blob' });
    }

    query(req?: any): Observable<Declaration[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    currentUser(req?: any): Observable<ListDeclaration> {
        
        return this.http.get(this.currentUserResourceUrl,  {params: req, observe: 'response'})
            .pipe(map((res) => {
                let listDeclarations = new ListDeclaration();
                listDeclarations.declarations = this.convertResponse(res.body);
                listDeclarations.maxDeclaration = Number(res.headers.get('X-Total-Count'));
                return listDeclarations;
            }));
    }

    currentUserActeur(req?: any): Observable<ListDeclaration> {
      return this.http.get(this.currentUserResourceUrl+'/acteur',  {params: req, observe: 'response'})
          .pipe(map((res) => {
              let listDeclarations = new ListDeclaration();
              listDeclarations.declarations = this.convertResponse(res.body);
              listDeclarations.maxDeclaration = Number(res.headers.get('X-Total-Count'));
              return listDeclarations;
          }));
  }

    exportUser(req?: any):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.get(this.exportUrl+'/currentuser', { headers: headers, params: req, responseType: 'blob' });
    }

    instructor(req?: any): Observable<ListDeclaration> {
        return this.http.get(this.instructorResourceUrl,  {params: req, observe: 'response'})
            .pipe(map((res) => {
                let listDeclarations = new ListDeclaration();
                listDeclarations.declarations = this.convertResponse(res.body);
                listDeclarations.maxDeclaration = Number(res.headers.get('X-Total-Count'));
                return listDeclarations;
            }));
    }

    exportInstructor(req?: any):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.get(this.exportUrl+'/instructor', { headers: headers, params: req, responseType: 'blob' });
    }

    deleteExport(): Observable<any> {
        return this.http.delete(`${this.exportUrl}/deletefile`);
    }

    cancel(id:number) {
        return this.http.delete(`${this.resourceUrl}/cancel/${id}`);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    deletePj(id: number): Observable<any> {
      return this.http.delete(`${this.resourceUrl}/${id}/piecesjointes`);
  }

    deleteOuvrages(id: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}/ouvrages`);
    }

    deleteReglementations(id: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}/reglementations`);
    }

    deleteFile(id:number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}/dl`);
    }

    deleteCerfa(id:number): Observable<any> {
        return this.http.delete(`${this.cerfaUrl}/${id}/dl`);
    }

    validate(id: number): Observable<boolean> {
        return this.http.get(`${this.resourceUrl}/${id}/validation`).pipe(map((res) => {
            return res as boolean;
        }));
    }

    submit(id: number): Observable<Boolean> {
        console.log(id);
        return this.http.get(`${this.resourceUrl}/${id}/submit`).pipe(map((res) => { return res as boolean;}));
    }
    
    private convertResponse(res): Declaration[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to Declaration.
     */
    private convertItemFromServer(json: any): Declaration {
        const entity: Declaration = Object.assign(new Declaration(), json);
        entity.dateDebutTravaux = this.dateUtils
            .convertLocalDateFromServer(json.dateDebutTravaux);
        entity.dateFinTravaux = this.dateUtils
            .convertLocalDateFromServer(json.dateFinTravaux);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateSoumission = this.dateUtils
            .convertLocalDateFromServer(json.dateSoumission);
        entity.dateFinalisation = this.dateUtils
            .convertLocalDateFromServer(json.dateFinalisation);
        entity.dateModification = this.dateUtils
            .convertLocalDateFromServer(json.dateModification);
        return entity;
    }

    /**
     * Convert a Declaration to a JSON which can be sent to the server.
     */
    private convert(declaration: Declaration): Declaration {
        const copy: Declaration = Object.assign({}, declaration);
        copy.dateDebutTravaux = this.dateUtils
            .convertLocalDateToServer(declaration.dateDebutTravaux);
        copy.dateFinTravaux = this.dateUtils
            .convertLocalDateToServer(declaration.dateFinTravaux);
        if(declaration.dateCreation)
            copy.dateCreation = this.dateUtils
                .convertLocalDateToServer(declaration.dateCreation);
        if(declaration.dateModification)
            copy.dateModification = this.dateUtils
                .convertLocalDateToServer(declaration.dateModification);
        copy.dateSoumission = this.dateUtils
            .convertLocalDateToServer(declaration.dateSoumission);
        copy.dateFinalisation = this.dateUtils
            .convertLocalDateToServer(declaration.dateFinalisation);
        return copy;
    }
}
