import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { LexTypeSaisieCoor } from './lex-type-saisie-coor.model';
import { DateUtils } from 'src/app/shared/model/date-utils';


@Injectable()
export class LexTypeSaisieCoorService {

    private resourceUrl =  SERVER_API_URL + 'api/lex-type-saisie-coors';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    find(id: number): Observable<LexTypeSaisieCoor> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<LexTypeSaisieCoor[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    private convertResponse(res): LexTypeSaisieCoor[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to LexTypeSaisieCoor.
     */
    private convertItemFromServer(json: any): LexTypeSaisieCoor {
        const entity: LexTypeSaisieCoor = Object.assign(new LexTypeSaisieCoor(), json);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateModification = this.dateUtils
            .convertLocalDateFromServer(json.dateModification);
        return entity;
    }

    /**
     * Convert a LexTypeSaisieCoor to a JSON which can be sent to the server.
     */
    private convert(lexTypeSaisieCoor: LexTypeSaisieCoor): LexTypeSaisieCoor {
        const copy: LexTypeSaisieCoor = Object.assign({}, lexTypeSaisieCoor);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(lexTypeSaisieCoor.dateCreation);
        copy.dateModification = this.dateUtils
            .convertLocalDateToServer(lexTypeSaisieCoor.dateModification);
        return copy;
    }
}
