<div class="modal-header">
    <h2 class="modal-title">Confirmation de suppression</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-delete-popup">
    <p>Êtes vous sûr de vouloir supprimer cette déclaration ? Cette opération est irréversible.</p>
</div>
<div class="modal-footer">
    <div class="col-md-6">
        <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="cancel()"/>
        <input type="button" name="validate" class="btn btn-danger btn-md float-right" value="Valider" (click)="validate()" />
    </div>
</div>