import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { DeclarationReglementationService } from './declaration-reglementation.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';


@NgModule({
    imports: [
        DuplosSharedModule
    ],
    declarations: [],
    providers: [
        DeclarationReglementationService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosDeclarationReglementationModule {}
