<div class="modal-header">
    <h2 class="modal-title">Caractéristiques techniques envisagées</h2>
</div>
<div  class="modal-body col-md-11">
    <!--<div class="row form-row">
        <label for="field_nom_us" class="col-md-5 form-control-label">Nom usuel <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row">
        <input type="text" class="col-md-8 form-control" name="nomUsuel" id="field_nom_us" [(ngModel)]="ouvrage.nomUsuel" />
    </div>-->
    <div class="row form-row">
        <label class="col-md-5 form-control-label">Type <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row">
        <span *ngFor="let natureouvrage of lexnaturesouvrage" class="col-md-3"> 
            <input type="radio" class="col-md-2" name="nature" [value]="natureouvrage.id" [ngModelOptions]="{standalone: true}" [(ngModel)]="ouvrage.natureOuvrageId" (change)="changeNature($event)" />
            <label class="col-md-10" [title]="natureouvrage.definition">{{natureouvrage.libelle}} <i class="fas fa-info-circle" [title]="natureouvrage.definition"></i></label>
        </span>
    </div>

    <div class="row form-row">
        <label class="col-md-5 form-control-label">Direction <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row">
        <span *ngFor="let direction of lexdirections" class="col-md-3"> 
            <input type="radio" class="col-md-2" name="direction" [value]="direction.id" [ngModelOptions]="{standalone: true}" [(ngModel)]="ouvrage.directionId" (change)="changeDirection($event)"/>
            <label class="col-md-10">{{direction.libelle}}</label>
        </span>
    </div>

    <div class="row form-row">
        <label class="col-md-5 form-control-label" for="field_longueur">Longueur (m) <span class="asterisk-red">*</span> <i class="fas fa-info-circle" title="Pour un ouvrage vertical, la longueur forée correspondra à la profondeur finale ; dans les autres cas, c'est bien la longueur du forage et non sa profondeur finale qui est demandée"></i></label>
    </div><div class="row form-group form-row">
        <input type="number" class="col-md-8 form-control" name="longueur" id="field_longueur" [(ngModel)]="ouvrage.longeur" min="0" max="9999" (input)="maxLongueur($event)"/>
        <span *ngIf="!isValidLongueur" style="color: red;">La longueur doit être comprise entre 0 et 10 000 m.</span>
    </div>
    <div class="row form-row" *ngIf="ouvrage.directionId && codeDirection == '2'">
            <label class="col-md-5 form-control-label" for="field_azimut">Azimut (°) <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row" *ngIf="ouvrage.directionId && codeDirection == '2'">
            <input type="number" class="col-md-8 form-control" name="azimut" id="field_azimut" [(ngModel)]="ouvrage.azimut" min="0" max="359" (input)="maxAzimut($event)" />
            <span *ngIf="!isValidAzimut" style="color: red;">L'azimut doit être compris entre 0 et 359</span>
    </div>
    <div class="row form-row" *ngIf="ouvrage.directionId && codeDirection == '2'">
        <label class="col-md-5 form-control-label" for="field_pendage">Pendage (°) <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row" *ngIf="ouvrage.directionId && codeDirection == '2'">
        <input type="number" class="col-md-8 form-control" name="pendage" id="field_pendage" [(ngModel)]="ouvrage.pendage" min="0" max="90" (input)="maxPendage($event)" />
        <span *ngIf="!isValidPendage" style="color: red;">Le pendage doit être compris entre 0 et 90°</span>
    </div>
    <div class="row form-group" *ngIf="ouvrage.directionId && codeDirection == '3'">
        <label class="col-md-12">Le tracé de l'ouvrage sera à fournir dans le rapport de fin de travaux.</label>
    </div>

    <div class="row form-row" *ngIf="isDomestique">
        <label class="col-md-5 form-control-label" for="field_diametre">Diamètre (mm) <span class="asterisk-red">*</span></label>
    </div>
    <div class="row form-group form-row" *ngIf="isDomestique">
        <input type="number" class="col-md-8 form-control" name="diametre" id="field_diametre" min="100" max="9999" [(ngModel)]="ouvrage.diametre"  />
        <span *ngIf="ouvrage.diametre < 100 || ouvrage.diametre > 9999"><label class="col-md-12" style="color: red;">Le diamètre doit être compris entre 100 mm et 9999 mm</label></span>
    </div>

    <!--div class="row form-row" *ngIf="isDomestique">
        <label class="col-md-5 form-control-label" for="field_debit">Débit de prélèvement (m3/h)</label>
    </div><div class="row form-group form-row" *ngIf="isDomestique">
        <input type="number" class="col-md-8 form-control" name="debit" id="field_debit" [(ngModel)]="ouvrage.debit"  />
    </div>

    <div class="row form-row" *ngIf="isDomestique">
        <label class="col-md-5 form-control-label" for="field_prelevement">Volume annuel prélevé (m3/an)</label>
    </div><div class="row form-group form-row" *ngIf="isDomestique">
        <input type="number" class="col-md-8 form-control" name="prelevement" id="field_prelevement" [(ngModel)]="ouvrage.prelevement"  />
    </div-->

    <div class="row form-row" *ngIf="isDomestique">
        <label class="col-md-10">Présence d'une margelle béton autour de la tête de forage <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row" *ngIf="isDomestique">
        <div class="col-md-3"> 
            <input type="radio" class="col-md-2" name="margelle" id="marg_oui" [(ngModel)]="ouvrage.presenceMargelle"
            [value]="true" /><label for="marg_oui" class="col-md-10" >Oui</label>
        </div><div class="col-md-3">
            <input type="radio" class="col-md-2" name="margelle" id="marg_non" [(ngModel)]="ouvrage.presenceMargelle"
            [value]="false" /><label for="marg_non" class="col-md-10">Non</label>
        </div>
    </div>

    <div class="row form-row" *ngIf="isDomestique">
        <label class="col-md-12">Ouvrage réalisé en se conformant à la norme "NF X 10-999 forages d'eau et de géothermie" ? <span class="asterisk-red">*</span><br>
        <span style="font-size: 12px; font-style: italic;">Le respect de cette norme permet de garantir que l'ouvrage est réalisé dans les règles de l'art et permet notamment de protéger la ressource souterraine 
            de toute infiltration directe d'eaux de ruissellement superficielle potentiellement polluée.
        </span>
        </label>
    </div><div class="row form-group form-row" *ngIf="isDomestique">
        <div class="col-md-3"> 
            <input type="radio" class="col-md-2" name="norme" id="norm_oui" [(ngModel)]="ouvrage.conformeNorme"
            [value]="true" /><label for="norm_oui" class="col-md-10" >Oui</label>
        </div><div class="col-md-3">
            <input type="radio" class="col-md-2" name="norme" id="norm_non" [(ngModel)]="ouvrage.conformeNorme"
            [value]="false" /><label for="norm_non" class="col-md-10">Non</label>
        </div>
    </div>
    <div class="row form-row">
        <h4 class="col-md-12 fs-title" *ngIf="isEau">Prélèvement d'eau envisagé</h4>
    </div>
    <div class="row form-group form-row" *ngIf="isEau">
        <label class="col-md-5 form-control-label" for="field_volume">Volume annuel (m<sup>3</sup>/an)  <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row" *ngIf="isEau">
        <input type="number" class="col-md-8 form-control" name="volume" id="field_volume" [(ngModel)]="ouvrage.prelevement" min="0" (input)="validateEau();checkMaxVolumeFORDOM()" />
        <span *ngIf="!isValidPrelevement" style="color: red;"><span class="fas fa-times"></span> Le volume doit être positif</span>
        <span *ngIf="!isValidMaxVolume && isValidMaxVolume != null && isDomestique" style="color: red;"><span class="fas fa-times"></span> Le volume doit être < 1000m3</span>
    </div>

    <div class="row form-row" *ngIf="isEau">
        <label class="col-md-5 form-control-label" for="field_debit">Débit de prélèvement (m<sup>3</sup>/h) <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row" *ngIf="isEau">
        <input type="number" class="col-md-8 form-control" name="debit" id="field_debit" [(ngModel)]="ouvrage.debit" min="0" (input)="validateEau()" />
        <span *ngIf="!isValidDebit" style="color: red;"><span class="fas fa-times"></span> Le débit doit être positif</span>
    </div>

    <div class="row form-row">
        <h4 class="col-md-12 fs-title" *ngIf="isDomestique">Connexion au réseaux d'eau :</h4>
    </div>
    <!--div class="row form-group form-row" *ngIf="isDomestique">
        <label class="col-md-12">Utilisation de l'eau pour la consommation humaine</label>
    </div><div class="row form-group" *ngIf="isDomestique">
        <div class="col-md-3"> 
            <input type="radio" class="col-md-2" name="consoHumaine_oui" id="consoHumaine" [(ngModel)]="ouvrage.consoHumaine" [ngModelOptions]="{standalone: true}" 
            [value]="true" /><label for="consoHumaine_oui" class="col-md-10" >Oui</label>
        </div><div class="col-md-3">
            <input type="radio" class="col-md-2" name="consoHumaine_non" id="consoHumaine" [(ngModel)]="ouvrage.consoHumaine" [ngModelOptions]="{standalone: true}" 
            [value]="false" /><label for="consoHumaine_non" class="col-md-10">Non</label>
        </div>
    </div>

    <div class="row form-group form-row" *ngIf="isDomestique">
        <label class="col-md-12">En cas d'utilisation de l'eau pour la consommation humaine :<br>
            pour un usage unifamilial, une analyse de type P1, à l'exception du chlore, définie dans l'arrêté du 11 janvier 2007 (relatif au programme de prélèvements et d'analyse du contrôle sanitaire pour les eaux fouries par un réseau distribution) doit être réalisée et jointe à la déclaration ; pour les ouvrages à réaliser l'analyse est transmise après travaux ;
            pour les autres cas, une autorisation préfectorale doit être demandée au titre de l'article L.1321-7 du code de la santé publique.
        </label>
    </div-->

    <!--div class="row form-group form-row" *ngIf="isDomestique">
        <label class="col-md-12">Autres usages de l'eau ?</label>
    </div-->
    <!--div class="row form-group" *ngIf="isDomestique">
        <div class="col-md-3"> 
            <input type="radio" class="col-md-2" name="autreUsageEauOui" id="autreusageeau" [(ngModel)]="ouvrage.autreUsage" [ngModelOptions]="{standalone: true}" 
            [value]="true" /><label for="reseau_oui" class="col-md-10" >Oui</label>
        </div>
        <div class="col-md-3">
            <input type="radio" class="col-md-2" name="autreUsageEauNon" id="autreusageeau" [(ngModel)]="ouvrage.autreUsageEau" [ngModelOptions]="{standalone: true}" 
            [value]="false" /><label for="reseau_non" class="col-md-10">Non</label>
        </div>
    </div>
    <div class="row form-group form-row" *ngIf="isDomestique">
        <label class="col-md-5 form-control-label" for="field_autreusageeaupreciser">Si oui, préciser :</label>
    </div><div class="row form-group form-row" *ngIf="isDomestique">
        <input type="text" class="col-md-8 form-control" name="diametre" id="field_autreusageeaupreciser" [(ngModel)]="ouvrage.autreusageeaupreciser"  />
    </div-->

    <div class="row form-group form-row" *ngIf="isDomestique">
        <label class="col-md-12">Existence d'un réseau de distribution d'eau intérieur au bâtiment alimenté par l'ouvrage ?</label>
    </div>
    <div class="row form-group" *ngIf="isDomestique">
        <div class="col-md-3"> 
            <input type="radio" class="col-md-2" name="reseau_distribution_oui" id="reseauInterne" [(ngModel)]="ouvrage.reseauInterne" [ngModelOptions]="{standalone: true}" 
            [value]="true" /><label for="reseau_oui" class="col-md-10" >Oui</label>
        </div><div class="col-md-3">
            <input type="radio" class="col-md-2" name="reseau_distribution_non" id="reseauInterne" [(ngModel)]="ouvrage.reseauInterne" [ngModelOptions]="{standalone: true}" 
            [value]="false" /><label for="reseau_non" class="col-md-10">Non</label>
        </div>
    </div>

    <div class="row form-row" *ngIf="isDomestique">
        <label class="col-md-12">Après usage, existence d'un rejet des eaux issues du pompage dans le réseau public de collecte des eaux usées ? <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row" *ngIf="isDomestique">
        <div class="col-md-3"> 
            <input type="radio" class="col-md-2" name="rejetU" id="rejetU_oui" [(ngModel)]="ouvrage.rejetUsees" [ngModelOptions]="{standalone: true}" 
            [value]="true" /><label for="rejetU_oui" class="col-md-10" >Oui</label>
        </div><div class="col-md-3">
            <input type="radio" class="col-md-2" name="rejetU_non" id="rejetU_non" [(ngModel)]="ouvrage.rejetUsees" [ngModelOptions]="{standalone: true}" 
            [value]="false" /><label for="rejetU" class="col-md-10">Non</label>
        </div>
    </div>

    <div class="row form-row" *ngIf="isDomestique">
        <label class="col-md-12">Après usage, existence d'un rejet des eaux issues du pompage dans le réseau public de collecte des eaux pluviales ? <span class="asterisk-red">*</span></label>
    </div><div class="row form-group form-row" *ngIf="isDomestique">
        <div class="col-md-3"> 
            <input type="radio" class="col-md-2" name="rejetP" id="rejetP_oui" [(ngModel)]="ouvrage.rejetPluviales" [ngModelOptions]="{standalone: true}" 
            [value]="true" /><label for="rejetP_oui" class="col-md-10" >Oui</label>
        </div><div class="col-md-3">
            <input type="radio" class="col-md-2" name="rejetP" id="rejetP_non" [(ngModel)]="ouvrage.rejetPluviales" [ngModelOptions]="{standalone: true}" 
            [value]="false" /><label for="rejetP_non" class="col-md-10">Non</label>
        </div>
    </div>
</div>