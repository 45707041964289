export class LexModeObtentionZ {
    constructor(
        public id?: number,
        public code?: string,
        public mnemo?: string,
        public libelle?: string,
        public definition?: string,
        public equidistance?: boolean,
        public appareil?: boolean,
        public precisionAbsolue?: boolean,
        public nomMnt?: boolean,
        public tailleMaille?: boolean,
        public valide?: boolean,
        public dateCreation?: any,
        public dateModification?: any,
        public precisionZId?: number,
        public precisionZLibelle?: string
    ) {
        this.equidistance = false;
        this.appareil = false;
        this.precisionAbsolue = false;
        this.nomMnt = false;
        this.tailleMaille = false;
        this.valide = false;
    }
}
