import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { LexStatutJuridique } from './lex-statut-juridique.model';
import { DateUtils } from 'src/app/shared/model/date-utils';


@Injectable()
export class LexStatutJuridiqueService {

    private resourceUrl =  SERVER_API_URL + 'api/lex-statut-juridiques';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    find(id: number): Observable<LexStatutJuridique> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<LexStatutJuridique[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    private convertResponse(res): LexStatutJuridique[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to LexStatutJuridique.
     */
    private convertItemFromServer(json: any): LexStatutJuridique {
        const entity: LexStatutJuridique = Object.assign(new LexStatutJuridique(), json);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateModification = this.dateUtils
            .convertLocalDateFromServer(json.dateModification);
        return entity;
    }

    /**
     * Convert a LexStatutJuridique to a JSON which can be sent to the server.
     */
    private convert(lexStatutJuridique: LexStatutJuridique): LexStatutJuridique {
        const copy: LexStatutJuridique = Object.assign({}, lexStatutJuridique);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(lexStatutJuridique.dateCreation);
        copy.dateModification = this.dateUtils
            .convertLocalDateToServer(lexStatutJuridique.dateModification);
        return copy;
    }
}
