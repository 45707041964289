import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LexFonctionService } from './lex-fonction.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';

@NgModule({
    imports: [
        DuplosSharedModule
    ],
    declarations: [],
    providers: [
        LexFonctionService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosLexFonctionModule {}
