export interface IActualite {
    id?: number;
    titre?: string;
    corps?: string;
    urlImage?: string;
    date?: string;
}

export class Actualite implements IActualite{
    constructor(
        public id?: number,
        public titre?: string,
        public corps?: string,
        public urlImage?: string,
        public date?: string
    ){}
}
