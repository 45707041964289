import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../../shared/login/login.service';

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {

    constructor(private loginService:LoginService) {
        
    }

    intercept(request: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((error:HttpErrorResponse) => {
            if (error.status === 401) {
                this.loginService.logout();
            }
            return throwError(error);              
            
        }));
    }
}
