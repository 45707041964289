import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { LexModeObtentionZ } from './lex-mode-obtention-z.model';
import { DateUtils } from 'src/app/shared/model/date-utils';

@Injectable()
export class LexModeObtentionZService {

    private resourceUrl =  SERVER_API_URL + 'api/lex-mode-obtention-zs';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    find(id: number): Observable<LexModeObtentionZ> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<LexModeObtentionZ[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    private convertResponse(res): LexModeObtentionZ[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to LexModeObtentionZ.
     */
    private convertItemFromServer(json: any): LexModeObtentionZ {
        const entity: LexModeObtentionZ = Object.assign(new LexModeObtentionZ(), json);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateModification = this.dateUtils
            .convertLocalDateFromServer(json.dateModification);
        return entity;
    }

    /**
     * Convert a LexModeObtentionZ to a JSON which can be sent to the server.
     */
    private convert(lexModeObtentionZ: LexModeObtentionZ): LexModeObtentionZ {
        const copy: LexModeObtentionZ = Object.assign({}, lexModeObtentionZ);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(lexModeObtentionZ.dateCreation);
        copy.dateModification = this.dateUtils
            .convertLocalDateToServer(lexModeObtentionZ.dateModification);
        return copy;
    }
}
