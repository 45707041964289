export class LexSubstance {
    constructor(
        public id?: number,
        public code?:string,
        public mnemo?: string,
        public libelle?: string,
        public definition?: string,
        public dateCreation?: any,
        public dateModification?: any,
        public parentId?: number,
        public statut?: number,
    ) {
    }
}
