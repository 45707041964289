import { Adresse } from '../adresse/adresse.model';
import { Contact } from '../contact/contact.model';

export class Acteur {
    constructor(
        public id?: number,
        public email?: string,
        public siretEtab?: string,
        public raisonSocialeEtab?: string,
        public dateCreation?: any,
        public dateModification?: any,
        public contactId?: number,
        public adresseId?: number,
        public statutJuridiqueId?: number,
        public auteurCreation?: string,
        public auteurModification?: string,
        public contact?:Contact,
        public adresse?:Adresse
    ) {
        this.contact = new Contact();
        this.adresse = new Adresse();
    }
}
