<div id="map" class="map">
    <div id="search" class="row searchAddress">
        <input type="text" class="col-md-8 form-control" name="searchAddress" id="searchAddress" [(ngModel)]="address" (keyup.enter)="findByAddress()" placeholder="Rechercher une adresse..." style="margin: 3px 5px;" />
        <input type="button" name="search" class="col-md-3 btn btn-info btn-md" value="Rechercher" (click)="findByAddress()" style="margin: 0px 15px;"/>
    </div>
    <div id="layerswitcher" class="switcher">
        <div *ngFor="let layer of layerSwitcherLayers">
            <input type="checkbox" (change)="toggleLayer(layer)" [(ngModel)]="layer.visible" [id]="layer.id">{{layer.title}}
        </div>
    </div>
    <div id="dromLayers" class="drom">
        <div *ngFor="let drom of dromLayers">
            <button (click)="goToDrom(drom)" [(ngModel)]="drom.visible" [id]="drom.id">
                <img src={{drom.icon}} height ="80" width="80" alt={{drom.title}}/>
            </button>
        </div>
    </div>
</div>
