import * as _ from 'lodash';
import { Injectable, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActeurService } from 'src/app/entities/acteur/acteur.service';
import { Acteur } from 'src/app/entities/acteur/acteur.model';

@Injectable()
export class ActeurPopupService {
    private ngbModalRef: NgbModalRef;

    constructor(
        private modalService: NgbModal,
        private acteurService: ActeurService

    ) {
        this.ngbModalRef = null;
    }

    open(component: Component, id?: number | any): Promise<NgbModalRef> {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null;
            if (isOpen) {
                resolve(this.ngbModalRef);
            }

            if (id) {
                this.acteurService.find(id).subscribe((acteur) => {
                    if (acteur.dateCreation) {
                        acteur.dateCreation = {
                            year: acteur.dateCreation.getFullYear(),
                            month: acteur.dateCreation.getMonth() + 1,
                            day: acteur.dateCreation.getDate()
                        };
                    }
                    if (acteur.dateModification) {
                        acteur.dateModification = {
                            year: acteur.dateModification.getFullYear(),
                            month: acteur.dateModification.getMonth() + 1,
                            day: acteur.dateModification.getDate()
                        };
                    }
                    this.ngbModalRef = this.acteurModalRef(component, acteur, new Acteur(), '');
                    resolve(this.ngbModalRef);
                });
            } else {
                // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    this.ngbModalRef = this.acteurModalRef(component, new Acteur(), new Acteur(), '');
                    resolve(this.ngbModalRef);
                }, 0);
            }
        });
    }

    acteurModalRef(component: Component, acteur: Acteur, acteurRef:Acteur, acteurType:String): NgbModalRef {
        const modalRef = this.modalService.open(component, { size: 'lg', backdrop: 'static', centered: true});
        modalRef.componentInstance.acteur = _.cloneDeep(acteur);
        modalRef.componentInstance.acteurRef = _.cloneDeep(acteurRef);
        modalRef.componentInstance.acteurType = acteurType;
        return modalRef;
    }

    close() {
        this.ngbModalRef.close();
    }
}
