<div class="row form-row">
    <label class="col-md-5">Statut <span class="asterisk-red">*</span></label>
</div><div class="row form-row form-group">
    <span *ngFor="let lexStatutJuridique of lexstatutjuridiques" class="col-md-4">
        <input type="radio" class="col-md-2" name="field_statut" id="field_statut_{{lexStatutJuridique.code}}" [value]="lexStatutJuridique" [ngModelOptions]="{standalone: true}" [(ngModel)]="lexstatutjuridique"
            (change)="statutJuridiqueChange(lexStatutJuridique)" />
        <label class="col-md-10" for="field_statut_{{lexStatutJuridique.code}}">{{lexStatutJuridique.libelle}}</label>
    </span>
</div>
<div class="row form-row" *ngIf="isPro">
    <label class="col-md-5 form-control-label" for="field_siret">Siret <span class="asterisk-red">*</span></label>
</div><div class="row form-row form-group" *ngIf="isPro && acteur">
    <input type="text" class="col-md-6 form-control" name="siret" id="field_siret"
        [(ngModel)]="acteur.siretEtab" maxlength="14" />
    <input type="button" name="previous" class="col-md-2 btn btn-success btn-md" value="Rechercher" (click)="findBySiret()"/>
</div>
<div class="row form-row" *ngIf="isPro">
    <label class="col-md-5 form-control-label" for="field_rs">Raison sociale <span class="asterisk-red">*</span></label>
</div><div class="row form-group form-row" *ngIf="isPro && acteur">
    <input type="text" class="col-md-8 form-control" name="rs" id="field_rs"
        [(ngModel)]="acteur.raisonSocialeEtab" maxlength="200" />
</div>
<div class="form-group">
    <h4 [hidden]="!isPro" class="col-md-12">Coordonnées de la personne en charge du dossier</h4>
</div>
<div class="row form-row">
  <label class="col-md-5 form-control-label" for="field_email">Courriel <span class="asterisk-red">*</span></label>
</div><div class="row form-group form-row" *ngIf="acteur">
  <input type="text" class="col-md-8 form-control" name="email" id="field_email"
      [(ngModel)]="acteur.email" maxlength="50" (blur)="checkActeur()" (input)="validEmail()" />
      <span *ngIf="!isValidEmail" style="color: red;"><span class="fas fa-times"></span> Format attendu : abc@def.com</span>
</div>
<dup-contact-form *ngIf="acteur" [contact]="acteur.contact"></dup-contact-form>
<div class="form-group">
    <h4 [hidden]="isPro" class="col-md-12">Coordonnées</h4>
    <h4 [hidden]="!isPro" class="col-md-12">Coordonnées de l'entreprise</h4>
</div>
<dup-adresse-form *ngIf="acteur" [adresse]="acteur.adresse" [isPro]="isPro" [isOuvrage]="false"></dup-adresse-form>
