import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from 'src/app/app.constants';
import { Commune } from './commune.model';
import { DateUtils } from 'src/app/shared/model/date-utils';

@Injectable()
export class CommuneService {

    private resourceUrl =  SERVER_API_URL + 'api/communes';
    private resourceDepUrl =  SERVER_API_URL + 'api/departements';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    find(id: string): Observable<Commune> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findByCoordinates(x:any, y:any) {
        return this.http.get(`${this.resourceUrl}/coordinates?x=${x}&y=${y}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findByDepartement(id: string): Observable<Commune[]> {
        return this.http.get(`${this.resourceDepUrl}/${id}/communes`).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    findByCp(cp: string): Observable<Commune[]> {
        return this.http.get(`${this.resourceUrl}/cp/${cp}`).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    findByInsee(insee: string): Observable<Commune> {
        return this.http.get(`${this.resourceUrl}/insee/${insee}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<Commune[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    findByBBox(extent): Observable<Commune[]> {
        return this.http.get(`${this.resourceUrl}/box?x1=${extent[0]}&y1=${extent[1]}&x2=${extent[2]}&y2=${extent[3]}`).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    private convertResponse(res): Commune[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to Region.
     */
    private convertItemFromServer(json: any): Commune {
        const entity: Commune = Object.assign(new Commune(), json);
        return entity;
    }
}