import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { DuplosSharedModule } from 'src/app/shared/shared.module';
import { ActeurService}  from './acteur.service';


@NgModule({
    imports: [
        DuplosSharedModule
    ],
    declarations: [],
    providers: [
        ActeurService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosActeurModule {}
