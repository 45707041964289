import { Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DuplosEventManager } from 'src/app/shared/event/event.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private eventManager: DuplosEventManager) {
        //TODO faire un service de broadcast
    }

    intercept(request: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>>  {
        return next.handle(request).pipe(catchError((error:HttpErrorResponse) => {
            if (!(error.status === 401 && (error.statusText === '' ||
                (error.url && error.url.indexOf('/api/account') === 0 )))) {
                if (this.eventManager !== undefined) {
                    this.eventManager.broadcast( {name: 'duplosApp.httpError', content: error});
                }
            }
            return throwError(error);
        }));
    }
}
