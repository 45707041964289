import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { Contact } from './contact.model';
import { DateUtils } from 'src/app/shared/model/date-utils';


@Injectable()
export class ContactService {

    private resourceUrl =  SERVER_API_URL + 'api/contacts';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    create(contact: Contact): Observable<Contact> {
        const copy = this.convert(contact);
        return this.http.post(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    update(contact: Contact): Observable<Contact> {
        return this.http.put(this.resourceUrl, contact).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    find(id: number): Observable<Contact> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<Contact[]> {
        return this.http.get(this.resourceUrl, {params: req})
            .pipe(map((res) => this.convertResponse(res)));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    private convertResponse(res): Contact[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to Contact.
     */
    private convertItemFromServer(json: any): Contact {
        const copy: Contact = Object.assign(new Contact(), json);
        if(json.dateCreation)
            copy.dateCreation = this.dateUtils
                .convertLocalDateToServer(json.dateCreation);
        if(json.dateModification)
            copy.dateModification = this.dateUtils
                .convertLocalDateToServer(json.dateModification);
        return copy;
    }

    /**
     * Convert a Contact to a JSON which can be sent to the server.
     */
    private convert(contact: Contact): Contact {
        const copy: Contact = Object.assign({}, contact);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(contact.dateCreation);
        copy.dateModification = this.dateUtils
            .convertLocalDateToServer(contact.dateModification);
        return copy;
    }
}
