<div class="modal-header">
    <h2 class="modal-title">{{acteurType}}</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-acteur-popup">
    <label class="col-md-5 form-control-label" for="ident">Identique au maitre d'ouvrage</label>
    <input type="checkbox" class="col-md-1" name="ident" id="ident" [(ngModel)]="isIdent" (change)="ident()" />
    <dup-acteur-form [acteur]="acteur"></dup-acteur-form>
</div>
 <div class="modal-footer">
    <div class="col-md-6">
        <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="cancel()"/>
        <input  type="button" name="next" class="btn btn-success btn-md float-right" value="Valider" (click)="validate()" [disabled]="validateActeur()" />
    </div>
</div>