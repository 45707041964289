import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AquifereService } from './aquifere.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';

@NgModule({
    imports: [
        DuplosSharedModule
    ],
    declarations: [],
    providers: [
        AquifereService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosAquifereModule {}