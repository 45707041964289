import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LexRelationService } from './lex-relation.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';


@NgModule({
    imports: [
        DuplosSharedModule
    ],
    declarations: [],
    providers: [
        LexRelationService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosLexRelationModule {}
