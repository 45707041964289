import { Injectable, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';

import { OuvrageService } from 'src/app/entities/ouvrage/ouvrage.service';
import { Ouvrage } from 'src/app/entities/ouvrage/ouvrage.model';

@Injectable()
export class AquiferePopupService {
    private ngbModalRef: NgbModalRef;

    constructor(
        private modalService: NgbModal,
        private router: Router,
        private ouvrageService: OuvrageService

    ) {
        this.ngbModalRef = null;
    }

    open(component: Component, id?: number | any, srs?:string): Promise<NgbModalRef> {
        return new Promise<NgbModalRef>((resolve, reject) => {
            const isOpen = this.ngbModalRef !== null;
            if (isOpen) {
                resolve(this.ngbModalRef);
            }

            if (id) {
                this.ouvrageService.find(id).subscribe((ouvrage) => {
                    if (ouvrage.dateCreation) {
                        ouvrage.dateCreation = {
                            year: ouvrage.dateCreation.getFullYear(),
                            month: ouvrage.dateCreation.getMonth() + 1,
                            day: ouvrage.dateCreation.getDate()
                        };
                    }
                    if (ouvrage.dateModification) {
                        ouvrage.dateModification = {
                            year: ouvrage.dateModification.getFullYear(),
                            month: ouvrage.dateModification.getMonth() + 1,
                            day: ouvrage.dateModification.getDate()
                        };
                    }
                    this.ngbModalRef = this.aquifereModalRef(component, ouvrage, srs);
                    resolve(this.ngbModalRef);
                });
            } else {
                // setTimeout used as a workaround for getting ExpressionChangedAfterItHasBeenCheckedError
                setTimeout(() => {
                    this.ngbModalRef = this.aquifereModalRef(component, new Ouvrage(), srs);
                    resolve(this.ngbModalRef);
                }, 0);
            }
        });
    }

    aquifereModalRef(component: Component, ouvrage: Ouvrage, srs:string, isDomestique?:boolean): NgbModalRef {
        const modalRef = this.modalService.open(component, { size: 'lg', backdrop: 'static', centered: true});
        modalRef.componentInstance.ouvrage = _.cloneDeep(ouvrage);
        modalRef.componentInstance.srs = _.cloneDeep(srs);
        if(isDomestique) modalRef.componentInstance.isDomestique = isDomestique;
        return modalRef;
    }
}
