import { PerimetreService } from './../../entities/perimetre/perimetre.service';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChildren, QueryList, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ListOuvrage } from 'src/app/entities/ouvrage/listouvrage.model';
import { Ouvrage } from 'src/app/entities/ouvrage/ouvrage.model';
import { Acteur } from 'src/app/entities/acteur/acteur.model';
import { Adresse } from 'src/app/entities/adresse/adresse.model';
import * as _ from 'lodash';
import { Declaration } from 'src/app/entities/declaration/declaration.model';
import { OuvrageService } from 'src/app/entities/ouvrage/ouvrage.service';
import { LexTypeSaisieCoor } from 'src/app/entities/lex-type-saisie-coor/lex-type-saisie-coor.model';
import { LexTypeSaisieCoorService } from 'src/app/entities/lex-type-saisie-coor/lex-type-saisie-coor.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { LexSystemeReferenceSpatial } from 'src/app/entities/lex-systeme-reference-spatial/lex-systeme-reference-spatial.model';
import { LexSystemeReferenceSpatialService } from 'src/app/entities/lex-systeme-reference-spatial/lex-systeme-reference-spatial.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AdresseService } from 'src/app/entities/adresse/adresse.service';
import { ContactService } from 'src/app/entities/contact/contact.service';
import { ActeurService } from 'src/app/entities/acteur/acteur.service';
import { Perimetre } from 'src/app/entities/perimetre/perimetre.model';
import { CommuneService } from 'src/app/entities/commune/commune.service';

@Component({
    selector: 'dup-ouvrage',
    templateUrl: './ouvrageform.component.html',
    styleUrls: [
      '../forms.css'
    ]
  })
  export class OuvrageFormComponent implements OnInit, OnChanges, OnDestroy {

    lextypesaisiecoor: LexTypeSaisieCoor[];
    lexsrs:LexSystemeReferenceSpatial[];
    perimetre:Perimetre[];

    // concerne de l'eau
    @Input() isEau: Boolean = false;
    // concerne les forages domestiques
    //@Input() isDomestique: Boolean = false;

    @Input() plusieursOuvrages: Boolean = false;

    // roles du declarant
    @Input() isDeclarantProprietaire: Boolean = false;
    @Output() isDeclarantProprietaireChange = new EventEmitter();
    @Input() isDeclarantExploitant: Boolean = false;
    @Output() isDeclarantExploitantChange = new EventEmitter();
    @Input() isDeclarantMOE: Boolean = false;
    @Output() isDeclarantMOEChange = new EventEmitter();

    //declarant
    @Input() declarant: Acteur;

    @Input() declaration: Declaration;

    @ViewChildren("ouvWarn") ouvWarns:QueryList<ElementRef>;
    @ViewChildren("ouvName") ouvNames:QueryList<ElementRef>;

    //liste des ouvrages de la déclaration
    @Input() declarationOuvrages: ListOuvrage;

    isSumOK:Boolean = true;
    //prise en compte de chaque changement pour la carto
    tick:number = 0;

    routeSub: any;
    navigationSubscription: Subscription;

    //sidebars
    displayNameOuvrage:Boolean = false;
    displayLocalisationOuvrage:Boolean = false;
    displayCaracOuvrage:Boolean = false;
    displayActeurOuvrage:Boolean = false;
    displayAquifereOuvrage:Boolean = false;

    //current
    index:number;
    ouvrageForm: Ouvrage;
    acteur:Acteur;
    acteurName:String = "";
    acteurType:string = "";
    isIdent:Boolean = false;
    isIdentMap:Map<String, Boolean>;
    isSame:Boolean = true;
    coord_ok: boolean = false;

    @Input() acteursFacultatifs:Boolean;

    duplicateNomUsuel:Set<string> = new Set();


    prelevementIsOK = true; // si > 1000 alors pas OK

    constructor(
        private ouvrageService: OuvrageService,
        private lexTypeSaisieCoorService: LexTypeSaisieCoorService,
        private lexSystemeReferenceSpatialService: LexSystemeReferenceSpatialService,
        private perimetreService: PerimetreService,
        private adresseService: AdresseService,
        private contactService: ContactService,
        private acteurService: ActeurService,
        private communeService: CommuneService,
        private alertService: AlertService,
        private route: ActivatedRoute,
        private router: Router
    ) {
      this.ouvrageForm = new Ouvrage();
      this.perimetre = [];
      this.ouvrageForm.adresse = new Adresse();
      this.acteur = new Acteur();
      this.index = 0;
      this.navigationSubscription = this.router.events.subscribe((e: any) => {
          // If it is a NavigationEnd event re-initalise the component
          if (e instanceof NavigationEnd) {
            this.loadOuvrages();
          }
        });
        this.isIdentMap = new Map();
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('typeReglementation') !== null && sessionStorage.getItem('typeReglementation') == 'FORDOM'){
          this.isEau = true;
        }
        this.lexTypeSaisieCoorService.query()
            .subscribe((res) => { this.lextypesaisiecoor = res; }, (res) => this.onError(res));
        this.lexSystemeReferenceSpatialService.query()
            .subscribe((res) => { this.lexsrs = res; }, (res) => this.onError(res));
        this.perimetreService.query()
            .subscribe((res) => { this.perimetre = res;}, (res) => this.onError(res));
    }

    ngOnChanges(changes: SimpleChanges): void {
      for (let propName in changes) {
        if(propName == 'plusieursOuvrages') {
          if(!this.declaration.plusieursOuvrages) {
            this.declarationOuvrages.ouvrages = this.declarationOuvrages.ouvrages.slice(0,1);
          }
          this.tick++;
        }
      }
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
        this.navigationSubscription.unsubscribe();
    }

    // gestion des erreurs de communication
    private onError(error: any) {
        this.alertService.error(error.message, null, null);
    }

    loadOuvrages() {
        this.routeSub = this.route.params.subscribe((params) => {
          if (params['id']) {
              this.ouvrageService.findByDeclaration(params['id']).subscribe((res) => {
                res.forEach(ouvrage => {
                  if (ouvrage.acteurMoeId) {
                    this.acteurService.find(ouvrage.acteurMoeId).subscribe((res) => {
                      if (res.email == this.declarant.email)
                      {
                        this.isDeclarantMOE = true;
                        this.isDeclarantMOEChange.emit(this.isDeclarantMOE);
                      } else {
                        ouvrage.acteurMoe = res;
                        if (res.contactId)
                          this.contactService.find(res.contactId).subscribe((res) =>ouvrage.acteurMoe.contact = res);
                        if (res.adresseId)
                          this.adresseService.find(res.adresseId).subscribe((res) => ouvrage.acteurMoe.adresse = res);
                      }
                    });
                  }
                  if (ouvrage.acteurProprioId) {
                    this.acteurService.find(ouvrage.acteurProprioId).subscribe((res) => {
                      if (res.email == this.declarant.email)
                      {
                        this.isDeclarantProprietaire = true;
                        this.isDeclarantProprietaireChange.emit(this.isDeclarantProprietaire);
                      } else {
                        ouvrage.acteurProprio = res;
                        if (res.contactId)
                          this.contactService.find(res.contactId).subscribe((res) =>ouvrage.acteurProprio.contact = res);
                        if (res.adresseId)
                          this.adresseService.find(res.adresseId).subscribe((res) => ouvrage.acteurProprio.adresse = res);
                      }
                    });
                  }
                  if (ouvrage.acteurForeurId) {
                    this.acteurService.find(ouvrage.acteurForeurId).subscribe((res) => {
                      if (res.email == this.declarant.email)
                      {
                        this.isDeclarantExploitant = true;
                        this.isDeclarantExploitantChange.emit(this.isDeclarantExploitant);
                      } else {
                        ouvrage.acteurForeur = res;
                        if (res.contactId)
                          this.contactService.find(res.contactId).subscribe((res) =>ouvrage.acteurForeur.contact = res);
                        if (res.adresseId)
                          this.adresseService.find(res.adresseId).subscribe((res) => ouvrage.acteurForeur.adresse = res);
                      }
                    });
                  }
                  if (ouvrage.adresseId) {
                    this.adresseService.find(ouvrage.adresseId).subscribe((res) => {
                      ouvrage.adresse = res;
                      this.communeService.find(res.communeInseeCom).subscribe((res) => ouvrage.adresse.commune = res);
                    });
                  }
                  if (ouvrage.x && ouvrage.y){
                    this.coord_ok = true;
                  }

                  this.declarationOuvrages.ouvrages.push(ouvrage);
                  this.tick++;
                });
              });
            }
        });
    }

    addOuvrage(event) {

        if(this.declarationOuvrages.ouvrages.length >= 20){
          return;
        }

        let ouvrage = new Ouvrage();
        let sc = _.find(this.lextypesaisiecoor, ['code', '1']);
        ouvrage.nomUsuel = "";
        ouvrage.typeSaisieCoorId = sc.id;
        ouvrage.adresse = new Adresse();
        ouvrage.adresse.pays = "France";
        if(this.declarationOuvrages.ouvrages.length > 0 && this.isSame) {
          this.clone(ouvrage);
        } else {
          if (this.isDeclarantMOE) {
            if(this.declarant.id) {
              ouvrage.acteurMoeId = this.declarant.id;
            } else {
              ouvrage.acteurMoe = this.declarant;
            }
          } else {
            ouvrage.acteurMoe = new Acteur();
          }
          if (this.isDeclarantProprietaire) {
            if(this.declarant.id) {
              ouvrage.acteurProprioId = this.declarant.id;
            } else {
              ouvrage.acteurProprio = this.declarant;
            }
          } else {
            ouvrage.acteurProprio = new Acteur();
          }
          if (this.isDeclarantExploitant) {
            if(this.declarant.id) {
              ouvrage.acteurForeurId = this.declarant.id;
            } else {
              ouvrage.acteurForeur = this.declarant;
            }
          } else {
            ouvrage.acteurForeur = new Acteur();
          }
        }

        this.declarationOuvrages.ouvrages.push(ouvrage);
        this.tick++;
      }

      same(event) {
        if(this.isSame && this.declarationOuvrages.ouvrages.length>0) {
          for (let i = 1; i < this.declarationOuvrages.ouvrages.length; i++) {
            this.clone(this.declarationOuvrages.ouvrages[i]);
          }
          this.checkPrelevement();
        }
      }

      clone(ouvrageToClone:Ouvrage) {
        let ouvrage: Ouvrage = this.declarationOuvrages.ouvrages[0];
        ouvrageToClone.acteurMoe = _.cloneDeep(ouvrage.acteurMoe);
        ouvrageToClone.acteurMoeId = _.clone(ouvrage.acteurMoeId);
        ouvrageToClone.acteurProprio = _.cloneDeep(ouvrage.acteurProprio);
        ouvrageToClone.acteurProprioId = _.clone(ouvrage.acteurProprioId);
        ouvrageToClone.acteurForeur = _.cloneDeep(ouvrage.acteurForeur);
        ouvrageToClone.acteurForeurId = _.clone(ouvrage.acteurForeurId);
        ouvrageToClone.longeur = _.clone(ouvrage.longeur);
        ouvrageToClone.diametre = _.clone(ouvrage.diametre);
        ouvrageToClone.natureOuvrageId = _.clone(ouvrage.natureOuvrageId);
        ouvrageToClone.natureOuvrageLibelle = _.clone(ouvrage.natureOuvrageLibelle);
        ouvrageToClone.directionId = _.clone(ouvrage.directionId);
        ouvrageToClone.directionLibelle = _.clone(ouvrage.directionLibelle);
        ouvrageToClone.azimut = _.clone(ouvrage.azimut);
        ouvrageToClone.pendage = _.clone(ouvrage.pendage);
        ouvrageToClone.presenceMargelle = _.clone(ouvrage.presenceMargelle);
        ouvrageToClone.conformeNorme = _.clone(ouvrage.conformeNorme);
        ouvrageToClone.prelevement = _.clone(ouvrage.prelevement);
        ouvrageToClone.debit = _.clone(ouvrage.debit);
        ouvrageToClone.reseauInterne = _.clone(ouvrage.reseauInterne);
        ouvrageToClone.rejetPluviales = _.clone(ouvrage.rejetPluviales);
        ouvrageToClone.rejetUsees = _.clone(ouvrage.rejetUsees);
        ouvrageToClone.codeNappe = _.clone(ouvrage.codeNappe);
        ouvrageToClone.natureNappe = _.clone(ouvrage.natureNappe);
        ouvrageToClone.nomNappe = _.clone(ouvrage.nomNappe);
        ouvrageToClone.commentaireNappe = _.clone(ouvrage.commentaireNappe);
      }

      validatePerimetre() {
        let isValid = true;
        let sommeVolumeOuvrage = 0;
        if(this.declarationOuvrages.ouvrages) {
          this.declarationOuvrages.ouvrages.forEach(o => {
            if(o.adresse && o.adresse.commune) {
              //console.log(o.adresse.commune);
              let perimetre = _.find(this.perimetre, ['regionInseeReg', o.adresse.commune.inseeReg]);
              isValid = isValid && perimetre != null;
            }
            if (o.prelevement && o.prelevement != null){
              sommeVolumeOuvrage = sommeVolumeOuvrage + o.prelevement;
            }
          });
        }
        if (sommeVolumeOuvrage > 1000 && sessionStorage.getItem('typeReglementation') == 'FORDOM'){
          this.prelevementIsOK = false;
        } else {
          this.prelevementIsOK = true;
        }
        return isValid;
      }

      coord_valide(coordXY:boolean){
        this.coord_ok = coordXY;
      }

      validateLocalisation(ouvrage:Ouvrage) {
        let baseOuvrage = ouvrage != null && ouvrage.modeXYId != null && ouvrage.srsId != null
        && ouvrage.x != null && ouvrage.y != null && ouvrage.typeLocalisationId != null
        && ouvrage.adresse != null && ouvrage.adresse.communeInseeCom != null;
        //console.log(this.coord_ok);
        return baseOuvrage && this.coord_ok;
      }

      validateCarac(ouvrage:Ouvrage) {
        let baseOuvrage:Boolean = ouvrage != null && ouvrage != undefined && ouvrage.longeur != null && ouvrage.longeur <10000 && ouvrage.natureOuvrageId != null && ouvrage.directionId != null;
        if(baseOuvrage) {
          if(ouvrage.directionId == 2400){
            baseOuvrage = baseOuvrage && ouvrage.azimut != null && ouvrage.azimut>=0 && ouvrage.azimut<360 && ouvrage.pendage != null && ouvrage.pendage>=0 && ouvrage.pendage <= 90;
          }
          /*if (this.isDomestique) baseOuvrage = baseOuvrage && ouvrage.diametre != null && ouvrage.presenceMargelle != null && ouvrage.conformeNorme != null
            && ouvrage.prelevement != null && ouvrage.debit != null && ouvrage.rejetUsees != null && ouvrage.reseauInterne != null && ouvrage.rejetPluviales != null;*/
            if (sessionStorage.getItem("typeReglementation") !== null && sessionStorage.getItem("typeReglementation") == 'FORDOM'){
              baseOuvrage = baseOuvrage && ouvrage.diametre >= 100 && ouvrage.diametre < 9999;
            }
        }
        if (sessionStorage.getItem("typeReglementation") !== null && sessionStorage.getItem("typeReglementation") == 'FORDOM'){
          baseOuvrage = baseOuvrage && ouvrage.presenceMargelle != null && ouvrage.conformeNorme != null && ouvrage.reseauInterne != null && ouvrage.rejetUsees != null && ouvrage.rejetPluviales != null;
        
        }
        
        if ( this.declaration.fonctionId >= 5500 && this.declaration.fonctionId <= 5599 ) {
          baseOuvrage = baseOuvrage && ouvrage.debit != null && ouvrage.debit >= 0 && ouvrage.prelevement != null && ouvrage.prelevement >= 0 && (ouvrage.prelevement < 1000 || (sessionStorage.getItem("typeReglementation") !== null && sessionStorage.getItem("typeReglementation") != 'FORDOM') );
        }
        
        return baseOuvrage;
      }

      validateActeur(acteur:Acteur) {
        let baseActeur  = acteur != null && acteur != undefined;
        if(baseActeur) {
          baseActeur = baseActeur && acteur.statutJuridiqueId != null && acteur.email != null;
          if (acteur.adresse) {
            baseActeur = baseActeur && acteur.adresse.pays != null;
            if (acteur.adresse.pays == "France") {
              baseActeur = baseActeur && acteur.adresse.communeInseeCom != null;
            } else {
              baseActeur = baseActeur && acteur.adresse.lieuDit != null;
            }
          } else {
            baseActeur = false;
          }
          if (acteur.statutJuridiqueId == 3800) {
            baseActeur = baseActeur && acteur.siretEtab != null && acteur.raisonSocialeEtab != null;
          }
          
          if (acteur.email != undefined){
            const regex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$', 'g');
            baseActeur = baseActeur && acteur.email.match(regex) != null;
          }
        }
        return baseActeur;
      }

      cancelOuvrage(ouvrage: Ouvrage) {
        let index = this.declarationOuvrages.ouvrages.indexOf(ouvrage);
        if (ouvrage.id) {
          this.ouvrageService.delete(ouvrage.id).subscribe(res => {});
        }
        this.declarationOuvrages.ouvrages.splice(index,1);
        this.tick++;
        this.computeDuplicate()
      }

      changeName(ouvrage:Ouvrage) {
       let index = this.declarationOuvrages.ouvrages.indexOf(ouvrage);
       let element = this.ouvNames.find((item, idx) => {
          return idx === index;
        }).nativeElement;
        element.style.display='inline-block';
        element.focus();
        this.ouvWarns.find((item, idx) => {
          return idx === index;
        }).nativeElement.style.display = 'none';
      }

      computeDuplicate(){

        const set = new Set();
        this.duplicateNomUsuel.clear();

        this.declarationOuvrages.ouvrages.forEach(ouv => {
          if (set.has(ouv.nomUsuel)) {
            this.duplicateNomUsuel.add(ouv.nomUsuel);
          } else {
            set.add(ouv.nomUsuel);
          }
        });
      }

      closeInput(ouvrage:Ouvrage){
        let index = this.declarationOuvrages.ouvrages.indexOf(ouvrage);
        this.ouvNames.find((item, idx) => {
           return idx === index;
         }).nativeElement.style.display = 'none';
        this.ouvWarns.find((item, idx) => {
          return idx === index;
        }).nativeElement.style.display = 'inline-block';
      }

      ident() {
        this.isIdentMap.set(this.acteurName, this.isIdent);
        if(this.isIdent) {
          if(this.declaration.acteurMoa && this.declaration.acteurMoa.email)
            this.acteur = _.cloneDeep(this.declaration.acteurMoa);
          else
            this.acteur = _.cloneDeep(this.declarant);
        } else {
          this.acteur = new Acteur();
        }
      }

      openActeurPopup(ouvrage: Ouvrage, type: string, name: String) {
        this.index = this.declarationOuvrages.ouvrages.indexOf(ouvrage);
        this.ouvrageForm = _.cloneDeep(ouvrage);
        this.acteur = this.ouvrageForm[type];
        this.acteurType = type;
        this.acteurName = name;
        if(!this.isIdentMap.has(name)){
          this.isIdentMap.set(name, false);
        }
        this.isIdent = this.isIdentMap.get(name);
        this.displayActeurOuvrage = true;
        this.displayCaracOuvrage = false;
        this.displayLocalisationOuvrage = false;
        this.displayAquifereOuvrage = false;
      }

      saveActeur() {
        this.ouvrageForm[this.acteurType] = _.cloneDeep(this.acteur);
        this.declarationOuvrages.ouvrages[this.index] = _.cloneDeep(this.ouvrageForm);
        this.displayActeurOuvrage = false;
      }

      openOuvragePopup(ouvrage: Ouvrage) {
        this.index = this.declarationOuvrages.ouvrages.indexOf(ouvrage);
        this.ouvrageForm = _.cloneDeep(ouvrage);
        this.displayCaracOuvrage = true;
        this.displayActeurOuvrage = false;
        this.displayLocalisationOuvrage = false;
        this.displayAquifereOuvrage = false;
      }

      saveOuvrage() {
        this.declarationOuvrages.ouvrages[this.index] = _.cloneDeep(this.ouvrageForm);
        this.checkPrelevement();
        this.displayCaracOuvrage = false;
      }

      checkPrelevement() {
        if(this.isEau) {
          let prelevement = 0;
          this.declarationOuvrages.ouvrages.forEach(ouvrage => {
            if(ouvrage.prelevement) prelevement += ouvrage.prelevement;
          });
          /*if(this.isDomestique) {
            if(prelevement>0 && prelevement < 1000) this.isSumOK = true;
            else this.isSumOK = false;
          } else {
            if(prelevement>=1000) this.isSumOK = true;
            else this.isSumOK = false;
          }*/
          //if(this.isSumOK)
          this.declaration.prelevement = prelevement;
        }
      }

      openAquiferePopup(ouvrage: Ouvrage) {
        this.index = this.declarationOuvrages.ouvrages.indexOf(ouvrage);
        this.ouvrageForm = _.cloneDeep(ouvrage);
        this.displayAquifereOuvrage = true;
        this.displayActeurOuvrage = false;
        this.displayLocalisationOuvrage = false;
        this.displayCaracOuvrage = false;
      }

      saveAquifere() {
        this.declarationOuvrages.ouvrages[this.index] = _.cloneDeep(this.ouvrageForm);
        this.displayAquifereOuvrage = false;
      }

      getSrs():string {
        return this.ouvrageForm && this.ouvrageForm.srsId ?"EPSG:" + _.find(this.lexsrs, ['id', this.ouvrageForm.srsId]).epsg:"";
      }

      isCarte():Boolean {
        let isCarte = false;
        let lextypesaisiecoorCarte = _.find(this.lextypesaisiecoor, ['id', this.ouvrageForm.typeSaisieCoorId]);
        if (lextypesaisiecoorCarte && lextypesaisiecoorCarte.mnemo == 'CARTE') isCarte = true;
        return isCarte;
      }

      openLocalisationPopup(ouvrage: Ouvrage) {
        this.index = this.declarationOuvrages.ouvrages.indexOf(ouvrage);
        this.ouvrageForm = _.cloneDeep(ouvrage);
        this.displayLocalisationOuvrage = true;
        this.displayActeurOuvrage = false;
        this.displayCaracOuvrage = false;
        this.displayAquifereOuvrage = false;
      }

      saveLocalisation() {
        this.declarationOuvrages.ouvrages[this.index] = _.cloneDeep(this.ouvrageForm);
        this.displayLocalisationOuvrage = false;
        this.tick++;
      }
  }
