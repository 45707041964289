<div class="modal-header">
    <h2 class="modal-title">Finalisation de déclaration</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>
<div class="saving" [hidden]="!isLoading">
    <p-progressBar [value]="value"></p-progressBar>
    Enregistrement dans la Banque du Sous-Sol (BSS) en cours...
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-final-popup" *ngIf="!ready">
    <p [hidden]="!showError" style="text-align: center; color: red ; font-weight: bold;" > Nom de fichier trop long (80 caractères, espaces compris maximum). Renommez votre fichier avant de le déposer. </p>

    <p style="text-align: center">Pour finaliser votre déclaration, vous devez déposer un rapport de fin de forage.</p>
    <p style="text-align: center">2 possibilités vous sont proposées</p>
    <div class="row">
        <div class="col-5">
            <button type="button" name="dialog" class="btn btn-primary btn-md custom-button-dark-blue" 
               value="dialog" (click)="openDialog()">Créer votre rapport avec l'outil <br>
                <strong> dialog </strong> <br> mis à votre disposition par le BRGM</button>
        </div>
        <div style="text-align: center; display: flex; justify-content: center; align-items: center;" class="col-2">
            <span>ou</span>
        </div>
        <div class="col-5">
            <p-fileUpload #fileUpload id="fileuploadfinalisationbtn" mode="basic" styleClass="btn btn-md custom-button-dark-blue height100" [auto]="true" 
            chooseLabel="Déposer le document PDF de votre choix"  chooseIcon="" uploadIcon="" style="height: 100%; display: block;" class="custom-button-dark-blue"
               name="myFiles[]" customUpload="true" (uploadHandler)="myUploader($event)" (onSelect)="onSelectUpload($event,fileUpload)" accept=".pdf"></p-fileUpload>
        </div>
    </div>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-final-ready" *ngIf="ready">
    <p>Vous vous apprêtez à finaliser votre déclaration n°{{declarationId}} avec le dépôt d’un rapport de fin de forage.</p>
    <p>Cette opération sera bancarisée en BSS et notifiée à la DREAL et est irréversible. Voulez-vous continuer ?</p>
</div>
<div class="modal-footer">
    <div class="col-md-6">
        <input type="button" name="previous" class="btn btn-secondary btn-md custom-button-round" value="Annuler" (click)="cancel()"/>
        <input type="button" name="next" class="btn btn-primary btn-md float-right custom-button-dark-blue" value="Valider" (click)="validate()" [disabled]="!ready || isLoading" />
    </div>
</div>