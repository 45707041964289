import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Principal } from "../auth/principal.service";

@Component({
  selector: 'dup-monocompte-popup',
  templateUrl: './monocompte-popup.component.html',
  styleUrls: ['./popup.css']
})
export class MonocomptePopupComponent implements OnInit {

  passportUrl:any;

  constructor(
    private ngbModal: NgbActiveModal,
    private principal: Principal
  ) { 
  }

  ngOnInit() {
    this.principal.getPasseportUrl().then((url) => {this.passportUrl = url;});
  }

  validate() {
    window.location.href=this.passportUrl;
  }

  cancel() {
    this.ngbModal.dismiss();
  }

}
