import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LexPrecisionXYService } from './lex-precision-xy.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';


@NgModule({
    imports: [
        DuplosSharedModule
    ],
    declarations: [],
    providers: [
        LexPrecisionXYService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosLexPrecisionXYModule {}
