import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LexNatureOuvrageService } from './lex-nature-ouvrage.service';
import { DuplosSharedModule } from 'src/app/shared/shared.module';

@NgModule({
    imports: [
        DuplosSharedModule
    ],
    declarations: [],
    providers: [
        LexNatureOuvrageService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosLexNatureOuvrageModule {}
