import { MonocomptePopupComponent } from './popup/monocompte-popup.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';

import { ProgressBarModule } from 'primeng/progressbar';
import {FileUploadModule} from 'primeng/fileupload';

import { DuplosSharedLibsModule } from './shared-libs.module';
import { DuplosSharedCommonModule } from './shared-common.module';
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import { LoginService } from './login/login.service';
import { AccountService } from './auth/account.service';
import { StateStorageService } from './auth/state-storage.service';
import { Principal } from './auth/principal.service';
//import { CSRFService } from './auth/csrf.service';
import { AuthServerProvider } from './auth/auth-jwt.service';
import { DuplosEventManager } from './event/event.service';
import { AlertService } from './alert/alert.service';
import { AquiferePopupService } from './popup/aquifere-popup.service';
import { SoumissionPopupService } from './popup/soumission-popup.service';
import { AbandonPopupService } from './popup/abandon-popup.service';
import { LocalisationPopupService } from './popup/localisation-popup.service';
import { ActeurPopupService } from './popup/acteur-popup.service';
import { OuvragePopupService } from './popup/ouvrage-popup.service';
import { SoumissionPopupComponent } from './popup/soumission-popup.component';
import { DeletePopupComponent } from './popup/delete-popup.component';
import { AbandonPopupComponent } from './popup/abandon-popup.component';
import { PieceJointePopupService } from './popup/piecejointe-popup.service';
import { PieceJointePopupComponent } from './popup/piecejointe-popup.component';
import { FinalisationPopupComponent } from './popup/finalisation-popup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TableModule } from 'primeng/table';

@NgModule({
    imports: [
        DuplosSharedLibsModule,
        DuplosSharedCommonModule,
        ProgressBarModule,
        NgSelectModule,
        TableModule,
        FileUploadModule
    ],
    declarations: [
        HasAnyAuthorityDirective,
        SoumissionPopupComponent,
        DeletePopupComponent,
        AbandonPopupComponent,
        PieceJointePopupComponent,
        FinalisationPopupComponent,
        MonocomptePopupComponent
    ],
    providers: [
        LoginService,
        AccountService,
        StateStorageService,
        Principal,
        //CSRFService,
        AuthServerProvider,
        DatePipe,
        DuplosEventManager,
        AlertService,
        OuvragePopupService,
        AquiferePopupService,
        ActeurPopupService,
        LocalisationPopupService,
        SoumissionPopupService,
        AbandonPopupService,
        PieceJointePopupService,
    ],
    exports: [
        DuplosSharedCommonModule,
        HasAnyAuthorityDirective,
        DatePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosSharedModule {}
